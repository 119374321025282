import React from "react";
import Constants from "../../../languages/en.json";
import { Button } from "@mui/material";
import "./sampleId.scss";
const SampleId = ({ handleChange, showSampleIdDetails }) => {
  const enterSample = Constants?.Enter_Sample;
  return (
      showSampleIdDetails && (
        <Button
          type="button"
          onClick={handleChange}
          className="sample-btn"
          variant="outlined"
        >
          {enterSample?.Add_Sample}
        </Button>
      )
  );
};
export default SampleId;
