
import { createSlice } from "@reduxjs/toolkit"
const initialState = {
    past: [],
    present: null, // (?) How do we initialize the present?
    future: []
}
const undoRendoSlice = createSlice({
    name: "undoRendo",
    initialState,
    reducers: {
    }

})
export const undoRedoActions = undoRendoSlice.actions;
export default undoRendoSlice.reducer