import React,{Fragment} from 'react'
import  Constant from '../../../../languages/language'
const RuledTab = () => {
  const ruledin = Constant?.workupDetails?.editData?.ruledin;
  const ruledout = Constant?.workupDetails?.editData?.ruledout;
    return (
      <Fragment>
        <div className="label ruled-in">
          <span className="dot"></span>{ ruledin }
        </div>
        <div className="label ruled-out">
          <span className="dot"></span>{ ruledout }
        </div>
      </Fragment>
    );
  };
  export default RuledTab;
