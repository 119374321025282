import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import "./patientTable.scss";
import SearchFilter from "../filter/SearchFilter";
import MenuEdit from "./menuEdit";
import { ReactComponent as SortAscending } from "../../assets/SortAscending.svg";
import { ReactComponent as SortDescending } from "../../assets/SortDecending.svg";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useParams} from "react-router-dom";
import { DateFormat } from "../../utils/dateFormat";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function PatientTable({
  data,
  sortingUp,
  sortingDown,
  sortColumn_a,
  sortColumn_d,
  addPatientDetails,
  searcText,
  onSearchTextChange,
}) {
  const [value, setValue] = useState("");
  const [addPatientData, setAddPatientData] = useState(null);
  const { workupId } = useParams();
  const handleChange = (event, val, id) => {
    setValue(event.target.value);
    setAddPatientData(val);
    const newData = JSON.stringify([val]);
    newData && localStorage.setItem("allPatientData", newData);
    // setValue(id);
  };

  const handleAddPatientDetails = () => {};

  return (
    <Box>
      <div>
        <h2 className="page-title">
          {addPatientDetails && addPatientDetails
            ? "Create Workup"
            : "Patients"}
        </h2>
        {addPatientDetails && (
          <div className="create-workup-header-testtable">
            <b>
              Patient Information <span className="optional"> (Optional)</span>
            </b>
            <p>
              Assign this workup to an existing patient by searching below or
              creating a new patient.{" "}
            </p>
          </div>
        )}
        <div className="patient-root">
          <div className="left-sec">
            <SearchFilter
              searchText={searcText}
              onSearchTextChange={onSearchTextChange}
            />
          </div>
          <div className="right-sec">
            <Button
              className="create-patient"
              component={Link}
              to="/create-patient"
              variant="outlined"
            >
              {addPatientDetails && addPatientDetails
                ? "Create new patient"
                : "Create patient"}
            </Button>
          </div>
        </div>

        <div className="patient-table-root">
          <Box>
            <TableContainer className="table-container">
              <Table className="table" stickyHeader aria-label="sticky table">
                <TableHead className="table-head">
                  <TableRow className="tr">
                    {addPatientDetails && (
                      <TableCell className="table-header-cell"></TableCell>
                    )}
                    <TableCell className="table-header-cell">
                      Patient ID
                    </TableCell>
                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">Patient Name</span>
                        <span className="table-sorting-icons">
                          <SortAscending
                            onClick={() => sortingUp("last_name")}
                            className={sortColumn_a["last_name"]}
                          />
                          <SortDescending
                            onClick={() => sortingDown("last_name")}
                            className={sortColumn_d["last_name"]}
                          />
                        </span>
                      </span>
                    </TableCell>

                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">Age</span>
                        <span className="table-sorting-icons">
                          <SortAscending
                            onClick={() => sortingUp("age")}
                            className={sortColumn_a["age"]}
                          />
                          <SortDescending
                            onClick={() => sortingDown("age")}
                            className={sortColumn_d["age"]}
                          />
                        </span>
                      </span>
                    </TableCell>
                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">
                          Date of Birth{" "}
                        </span>
                        <span className="table-sorting-icons">
                          <SortAscending
                            onClick={() => sortingUp("date")}
                            className={sortColumn_a["date"]}
                          />
                          <SortDescending
                            onClick={() => sortingDown("date")}
                            className={sortColumn_d["date"]}
                          />
                        </span>
                      </span>
                    </TableCell>
                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">Gender</span>
                        <span className="table-sorting-icons">
                          <SortAscending
                            onClick={() => sortingUp("gender")}
                            className={sortColumn_a["gender"]}
                          />
                          <SortDescending
                            onClick={() => sortingDown("gender")}
                            className={sortColumn_d["gender"]}
                          />
                        </span>
                      </span>
                    </TableCell>
                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">ABO/Rh</span>
                        <span className="table-sorting-icons">
                          <SortAscending
                            onClick={() => sortingUp("abo_rh")}
                            className={sortColumn_a["abo_rh"]}
                          />
                          <SortDescending
                            onClick={() => sortingDown("abo_rh")}
                            className={sortColumn_d["abo_rh"]}
                          />
                        </span>
                      </span>
                    </TableCell>

                    <TableCell className="table-header-cell"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {data.map((patientsData, index) => (
                    <TableRow hover key={index}>
                      {addPatientDetails && (
                        <TableCell>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={value}
                              onChange={() =>
                                handleChange(
                                  event,
                                  patientsData,
                                  patientsData?.id
                                )
                              }
                            >
                              <FormControlLabel
                                value={patientsData?.id}
                                // value={value}
                                // onChange={() =>handleChange(patientsData,patientsData.id)}
                                control={
                                  <Radio
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#CF2F44",
                                      },
                                    }}
                                  />
                                }
                                label=""
                              />
                            </RadioGroup>
                          </FormControl>
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">
                        {patientsData.id}
                      </TableCell>
                      <TableCell className="patient-name" align="right">
                        <a href="#">
                          {patientsData.last_name +
                            "," +
                            " " +
                            patientsData.first_name}
                        </a>
                      </TableCell>
                      <TableCell align="left">{patientsData.age}</TableCell>
                      <TableCell>
                        {dayjs(patientsData.date).format(DateFormat())}
                      </TableCell>

                      <TableCell align="left">{patientsData.gender}</TableCell>
                      <TableCell align="left">{patientsData.abo_rh}</TableCell>
                      <TableCell>
                        <MenuEdit />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {addPatientDetails && (
            <div className="button-container">
              <Stack direction="row">
                <Link
                   to={
                    workupId ? `/single-workup/${workupId}` : "/single-workup"
                  }
                  className="cancel"
                  state={
                    workupId
                      ? { addPatientData: [], editWorkupData: true }
                      : { addPatientData: [] }
                  }
                  variant="outlined"
                  onClick={() => {
                    localStorage.removeItem("allPatientData");
                  }}
                >
                  Cancel
                </Link>
              </Stack>{" "}
              <Stack direction="row">
                <Link
                  className="submit"
                  to={
                    workupId ? `/single-workup/${workupId}` : "/single-workup"
                  }
                  state={
                    workupId
                      ? { addPatientData: addPatientData, editWorkupData: true }
                      : { addPatientData: addPatientData }
                  }
                  onClick={handleAddPatientDetails}
                  variant="contained"
                >
                  Add patient
                </Link>
              </Stack>
            </div>
          )}
        </div>
      </div>
    </Box>
  );
}
