import React from 'react'
import { Drawer, Box, Button } from '@mui/material';
import "./ViewPanelDetails.scss"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import Constants from '../../languages/language'
import LoadingSpinner from '../Notes/LoadingSpinner';
import i18n from '../../i18n'
import { useEffect } from 'react';
import { useState } from 'react';

const ViewPanelDetails = ({ panelApiResponse, open, panelClose, load }) => {
    const Constant = Constants?.viewPanelDetails  
    const [lang,setLang] = useState([]);
    
    useEffect(()=>{
        if(panelApiResponse !== undefined || null){
            let presentLang;
           presentLang = i18n.language === "fr-CA" ? panelApiResponse['fr-CA'] : panelApiResponse['en-US'];
           setLang(presentLang)
        }
    },[panelApiResponse])

    const LanguageSelect = () => {
        const listItems = lang.map((number) =>
            <p key={number.toString()}>{number}</p>)
        return listItems
    }

    return (
        <Drawer anchor='right' open={open} onClose={panelClose}>
            <Box className="panel-box">
                <div className="panel-heading">{Constant?.panelHeading}
                    <span className="close-btn" onClick={panelClose}><CloseIcon /></span>
                </div>
                <div className="product-data">
                    <div className='reagent-data'>{Constant?.RBC}</div>
                    <div className="product-panel-data">{panelApiResponse?.product}</div>
                    <div className="antigram-data">{Constant?.antigram_antigen}</div>
                </div>
                {/* <div className='cell-data'>
                    <div className='error-icon'><ErrorOutlineIcon /></div>
                    <div className='second-error-icon'><ErrorOutlineIcon /></div>
                </div> */}
                <div className="static-data">
                    <div className="static-info">{panelApiResponse?.static[0]}</div>
                    <div>{panelApiResponse?.static[1]}</div>
                </div>
                {load ? <LoadingSpinner/> : ""}<div className="additional-details">
                    <h1 className='additional-panel-heading'>{Constant?.additional_details}</h1>
                    <div className="language-data">{LanguageSelect()}</div>
                </div>
            </Box>
        </Drawer>
    )
}

export default ViewPanelDetails