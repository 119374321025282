import React from "react";
import { Link } from "react-router-dom";
import TestTubeEmpty from "../../assets/images/Test-tube-empty.js";
import Person from "../../assets/images/Person.js";
import FileOutline from "../../assets/images/File-outline.js";
import Folder from "../../assets/images/Folder.js";
import { userInfo } from "../../Redux/Store/user-info";
import "./index.scss";
import Constants from "../../languages/language";
import { useLocation } from "react-router-dom";

const sidebar = Constants.SideBar_Navigation;
let Constant = Constants;

const sideBarItems = [
  {
    id: 1,
    icon: "testTube",
    linkTitle: Constant.SideBar_Navigation.Tests,
    linkName: "/",
    state: { addTestResults: false },
    pathMatch: ["/view-test-results"],
  },
  {
    id: 2,
    icon: "folder",
    linkTitle: Constant.SideBar_Navigation.Workups,
    linkName: "/workup-home",
    state: {},
  },
  // {
  //   id: 3,
  //   icon: "person",
  //   linkTitle: "Patients",
  //   linkName: "/patient-home",
  //   state: { addPatientDetails: false },
  // },
  // { id: 4, icon: "fileOutline", linkTitle: "Library",linkName:"/"  },
];

const Sidebar = () => {
  const location = useLocation();
  const { sidebarSelection } = location.state || {};
  const termsOfServicePresent = !!userInfo?.data?.terms_of_service;
  const allowNavigation = termsOfServicePresent;

  const getActiveId = () => {
    if (!allowNavigation) {
      return 0;
    }

    if (sidebarSelection == sidebar.Tests) {
      return 1;
    }
    if (sidebarSelection == sidebar.Workups) {
      return 2;
    }

    // if path contains workup, highlight workup
    if (location.pathname.includes("workup")) {
      return 2;
    }

    if (
      location.pathname === "/" ||
      sideBarItems[0].pathMatch.find((p) => location.pathname.startsWith(p))
    ) {
      return 1;
    }

    return 0; // dont select anything
  };

  const activeId = getActiveId();

  return (
    <div
      className="sidebar-root"
      style={{ pointerEvents: allowNavigation ? undefined : "none" }}
    >
      {sideBarItems.map((item, index) => {
        return (
          <div key={index} className="side-nav-item">
            <Link
              className={item.id === activeId ? "active" : " "}
              to={item.linkName}
              state={item?.state}
            >
              {item.icon === "testTube" && (
                <TestTubeEmpty
                  fill={item.id === activeId ? "#CF2F44" : " #34383C"}
                />
              )}
              {item.icon === "folder" && (
                <Folder fill={item.id === activeId ? "#CF2F44" : " #34383C"} />
              )}
              {item.icon === "fileOutline" && (
                <FileOutline
                  fill={item.id === activeId ? "#CF2F44" : " #34383C"}
                />
              )}
              <span>{item.linkTitle}</span>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
export default Sidebar;
