import React, { useState } from "react";
import Constants from "../../../../languages/language";
import dayjs from "dayjs";
import "./index.scss";
import FormattedLabel from "../../../formattedLabel/FormattedLabel";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { DateFormat } from "../../../../utils/dateFormat";
import ViewPanelDetails from "../../../panelDetails/ViewPanelDetails";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: '#34383C',
    fontWeight: 400,
    padding: 12,
    borderRadius: 6,
    fontSize: 10,
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))"
  },
}));
const AntigramTable = ({ apiAllData, handleOpen, openPanel, panelApiResponse, panelClose, load }) => {

  const antigramTableConst = Constants.antigramTable;
  const colOrder = antigramTableConst.apiColOrder;
  const allPanelsData = apiAllData && [apiAllData];

  const headerRowLabels = () => {
    const colOrderCst = antigramTableConst.cstColOrder;
    const headerCells = [];
    for (var j = 0; j < colOrder.length - 1; j++) {
      if (colOrder[j] === "cell_number" || colOrder[j] === "Spc" || colOrder[j] === "Rh-hr" || colOrder[j] === "donor_number" || colOrder[j] === "tst-rslt") {
        headerCells.push(
          <td key={j} className={`hd-cell cl-${j + 1} ${colOrder[j]}`}>
            {colOrderCst[j]}
          </td>
        );
      } else if (colOrder[j] === "is") {
        headerCells.push(
          <td key={j} colSpan={5} className={`hd-cell cl-${j + 1} ${colOrder[j]}`}>
            {antigramTableConst.tableHeaderGroup.TestsResults}
          </td>
        );
      } else if (colOrder[j] === "37c" || colOrder[j] === "iat" || colOrder[j] === "fic" || colOrder[j] === "user_entered") {
        //
      } else {
        headerCells.push(
          <td
            key={j}
            className={`hd-cell cl-${j + 1} ${colOrder[j]}`}
            colkey={colOrder[j]}
          >
            <FormattedLabel j={colOrder[j]}></FormattedLabel>
          </td>)
      }
    }
    return headerCells;
  };

  const patientcellsrow = () => {
    const patientcells = [];
    let rowLabel;
    for (let j = 0; j < colOrder.length; j++) {
      if (colOrder[j] === "Rh-hr") {
        rowLabel = antigramTableConst.tableFooterDetails.PatientCells;
        patientcells.push(
          <td key={j} className={`pt-cell cl-${j + 1} ${colOrder[j]}`}>
            {rowLabel}
          </td>
        );
      } else if (colOrder[j] === "is" || colOrder[j] === "37c" || colOrder[j] === "iat" || colOrder[j] === "fic" || colOrder[j] === "user_entered") {
        for (let k = 0; k <= 11; k++) {
          if (+apiAllData?.results[k]?.cell_number === "AC") {
            rowLabel = apiAllData?.results[k]?.columns[colOrder[j]]
            break;
          }
        }
        patientcells.push(
          <td key={j} className={`pt-cell cl-${j + 1} ${colOrder[j]}`}>
            {rowLabel}
          </td>
        );
      } else {
        rowLabel = "";
        patientcells.push(
          <td key={j} className={`pt-cell cl-${j + 1} ${colOrder[j]}`}>
            {rowLabel}
          </td>
        );
      }
    }
    return patientcells;
  };

  const anitigenColsPanel = (i, panelno) => {
    const eachCol = [];

    const getPanelData = allPanelsData && allPanelsData[panelno];
    for (let j = 0; j < colOrder.length; j++) {
  
      let resVal = [];
  /*ordering the result data from api in ascending order*/
      for (let k = 0; k <= 11; k++) {
        if (+getPanelData?.results[k]?.cell_number === i + 1) {
          resVal = getPanelData?.results[k]?.columns[colOrder[j]]
        }
      }
      if (colOrder[j] === "cell_number") {
        eachCol.push(
          <td key={j} className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]}`}>{i + 1}</td>
        );
      } else if (colOrder[j] === "is" || colOrder[j] === "37c" || colOrder[j] === "iat" || colOrder[j] === "fic" || colOrder[j] === "user_entered") {
        eachCol.push(
          <td key={j} className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]} tst-columns`}>
            {resVal}
          </td>
        )
      } else {
        eachCol.push(
          <td
            key={j}
            panelno={panelno}
            className={`rw-${i + 1} cl-${j + 1} ${colOrder[j]} panel-${panelno + 1}
            pnl-${panelno + 1}rw-${i + 1}`}>
            {getPanelData?.panel?.rows[i][colOrder[j]]}
          </td>
        );
      }
    }

    return eachCol;
  };

  const anitigenRowsPanel = (panelno) => {
    const getPanelData = allPanelsData && allPanelsData[panelno];
    const eachRow = [];
    for (var i = 0; i < getPanelData?.panel?.rows?.length; i++) {
      if (getPanelData?.panel.rows[i]?.cell_number === "AC") {
        eachRow.push();
      }
      else {
        eachRow.push(
          <tr className={`row-${i + 1}`} key={`row-${i + 1}`}>{anitigenColsPanel(i, panelno)}</tr>
        );
      }
    }
    eachRow.push(<tr key={panelno}>{patientcellsrow()}</tr>);
    return eachRow;
  };

  const LoopPanels = (panelKey, key) => {
    return (
      <React.Fragment>
        <tbody className="antigram-header-data panel-a panel-a-header">
          <tr>
            <td className="panel-a-detail-row" colSpan={32}>
              <div className="panel-a-row">
                <span className="panel-name">
                  {allPanelsData[key].panel.panel_type !== "Screen" ?
                   antigramTableConst.panelDetails.panelName : null} {allPanelsData[key].panel.panel_type}
                </span>
                <span className="lot-no">
                  <span>{antigramTableConst.panelDetails.lotNo}</span>{" "}
                  {allPanelsData && allPanelsData[key]?.panel?.lot_number}
                  {allPanelsData[key]?.panel?.expiration_date &&
                    " / " +
                    antigramTableConst.panelDetails.exp +
                    " " +
                    dayjs(allPanelsData[key]?.panel?.expiration_date)
                      .format(DateFormat())}
                </span>
                <span className="sample-id">
                  <b>{antigramTableConst.panelDetails.sampleId}</b> &nbsp;
                  <LightTooltip className="view-only-hover-popUp" title={allPanelsData[key]?.panel?.sample.unhashed} arrow placement="top-start" >
                    <span>{allPanelsData[key]?.panel?.sample?.unhashed && allPanelsData[key]?.panel?.sample?.unhashed?.length > 20 ? allPanelsData[key]?.panel?.sample?.unhashed?.substring(0, 20) + "..." :
                      allPanelsData[key]?.panel?.sample.unhashed}</span>
                  </LightTooltip >

                </span>
                <span id="see-details" onClick={() => handleOpen()} className="see-details-label">
                  {antigramTableConst.panelDetails.SeeDetails}
                </span>
              </div>
            </td>
            <td className="tr-col is cl-title">IS</td>
            <td className="tr-col 37c cl-title">37C</td>
            <td className="tr-col iat cl-title">IAT</td>
            <td className="tr-col fic cl-title">Fic</td>
            <td className={`tr-col user_entered cl-title ${""}`}></td>
          </tr>
        </tbody>
        <tbody className="antigram-main-data panel-a panel-a-table">
          {anitigenRowsPanel(key)}
        </tbody>
      </React.Fragment>
    );
  };

  return (
    <div className="test-table-wrapper">
      <ViewPanelDetails panelApiResponse={panelApiResponse} open={openPanel} panelClose={panelClose} load={load} />
      <table>
        <tbody className="antigram-header-data antigram-header-sticky">
          <tr className="table-group-heading">
            <td colSpan={3}></td>
            <td colSpan={8}>{antigramTableConst.tableHeaderGroup.RhHr}</td>
            <td colSpan={6}>{antigramTableConst.tableHeaderGroup.Kell}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Duffy}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Kidd}</td>
            <td>{antigramTableConst.tableHeaderGroup.SexLinked}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Lewis}</td>
            <td colSpan={4}>{antigramTableConst.tableHeaderGroup.MNS}</td>
            <td>{antigramTableConst.tableHeaderGroup.P}</td>
            <td colSpan={2}>{antigramTableConst.tableHeaderGroup.Lutheran}</td>
            <td colSpan={6}></td>
          </tr>

          <tr className="cell-row-header">{headerRowLabels()}</tr>
        </tbody>

        {allPanelsData &&
          allPanelsData.map((panelKey, key) => {
            return (
              <React.Fragment key={key}>
                {LoopPanels(panelKey, key)}
              </React.Fragment>
            );
          })}

        <tbody>
          <tr className="table-group-footer">
            <td colSpan={3}>
              {antigramTableConst.tableFooterDetails.ModeReactivity}
            </td>
            <td colSpan={8}>
              {antigramTableConst.tableFooterDetails.CAntiglobulin}
            </td>
            <td colSpan={11}>
              {antigramTableConst.tableFooterDetails.Antiglobulin}
            </td>
            <td colSpan={4}>
              {antigramTableConst.tableFooterDetails.Variable}
            </td>
            <td colSpan={3}>{antigramTableConst.tableFooterDetails.Cold}</td>
            <td colSpan={2}>{antigramTableConst.tableFooterDetails.Var}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default AntigramTable;
