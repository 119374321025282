import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";

export const getNotes = async (workupId) => {
  try {
    const response = await Api.get(
      `${REACT_APP_API_ENDPOINT}org/workups/${workupId}/notes`
    );
    if (response) {
      return response?.data?.notes.reverse();
    }
  } catch (err) {
    return err
  }
};
export const saveNotes = async (workupId, entText) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}org/workups/${workupId}/notes`,
      entText
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};
export const updateEditedNotesContent = async (workupId, id, content) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}org/workups/${workupId}/notes/${id}`,
      content
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
    return err;
  }
};
export const deleteNotes = async (workupId, id) => {
  try {
    const response = await Api.delete(
      `${REACT_APP_API_ENDPOINT}org/workups/${workupId}/notes/${id}`
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};
