import * as React from 'react';
import AlertDialogModal from "../../alertDialogModal/AlertDialogModal"
import Constants from "../../../languages/language"

export default function AlertNote({ handleRMToggle, setMode, updateData }) {

    const alertdata = Constants?.antigramAlertNote;
    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        setOpen(false);
    };

    const panel = () => {
        handleRMToggle(false);
        handleClose();
        setMode(false);
        updateData()
    };
    const manual = () => {
        handleRMToggle(true);
        // updateModifiedData();
        handleClose();
        setMode(false);
    }
    return (
        <AlertDialogModal
            dialogButtonName={alertdata?.test}
            dialogTitle={alertdata?.heading}
            dialogContent={alertdata?.content}
            btnYesText={alertdata?.yes}
            btnNoText={alertdata?.cancel}
            open={open}
            handleClose={panel}
            handleProceed={manual}
        />

    );
}