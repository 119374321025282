import React, { useEffect, useState, useContext, useRef } from "react";
import AntigramHeader from "./antigramHeader/AntigramHeader.js";
import AntigramTable from "./antigramTable/AntigramTable.js";
import { geSingletWorkups, autoControl } from "../../Redux/Actions/workups.js";
import { useParams, useNavigate } from "react-router-dom";
import { SpinnerContext } from "../../context/spinner-context.js";
import { WorkupIinterpretationData } from "../../context/workup-interpretation.js";
import "./index.scss";

const AntigramPanel = () => {
  const [apiResponse, setapiResponse] = useState([]);
  const [rmToggle, setRmToggle] = useState(false);
  const [ruleType, setRuleType] = useState("3 x 3");
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const [prevApiData, setPrevApiData] = useState(null);
  const prevDataRef = useRef();
  const navigate = useNavigate();
  const updateRules = (ruleTp) => {
    setRuleType(ruleTp);
  };

  const { panelId } = useParams();
  useEffect(() => {
    getWorkupsCall("onLoad");
  }, []);

  const getWorkupsCall = async (loadMode) => {
    loadMode === "onLoad" && changeSpinnerStatus(true);
    const res = await geSingletWorkups(panelId);
    if (res) {
      const responseData = res;
      setapiResponse(responseData);
    }
    if(res.status !==200)
    {
      var err = res
      if(err?.status === 400){
        navigate(`/error`,{state:{status:"400",statusText:res.statusText}})
      }
      if(err?.status === 401){
        navigate(`/error`,{state:{status:"401",statusText:res.statusText}})
      }
      if(err?.status === 402){
        navigate(`/error`,{state:{status:"402",statusText:res.statusText}})
      }
      if(err?.status === 403){
        navigate(`/error`,{state:{status:"403",statusText:"Forbidden access"}})
      }
      if(err?.status === 404){
        navigate(`/error`,{state:{status:"404",statusText:res.statusText}})
      }
      if(err?.status === 405){
        navigate(`/error`,{state:{status:"405",statusText:"Method not allowed"}})
      }
      if(err?.status === 0){
        navigate(`/error`,{state:{status:"0",statusText:"strict-origin-when-cross-origin"}})
      }
    }
    loadMode === "onLoad" && changeSpinnerStatus(false);
  };

  const handleRMToggle = (val) => {
    if (val !== null) {
      setRmToggle(val);
    }
  };
  useEffect(() => {}, [rmToggle]);

  // Keeping rules commented code for future requirements
  // const classificRules = rmToggle===false ? apiResponse?.alternate_classifications?.["3x3"] : apiResponse?.classifications?.user_solution;
  const classificRules = rmToggle===false ? apiResponse?.classifications?.algorithm_solution : apiResponse?.classifications?.user_solution;
  const [classificRule, setClassificRule] = useState();
  useEffect(() => {
    setClassificRule(classificRules);
  }, [classificRules]);

  const handleRuleUpdates = (val) => {
    setClassificRule(val);
  };

  const handleUpdateApiData = () => {
    setPrevApiData(apiResponse);
    getWorkupsCall();
  };
  let panelsLength={};
  for (var key in apiResponse?.panels) {
    panelsLength[key] = true;
  }
  const panelCount =  Object.keys(panelsLength).pop()
  const autoControlClick = (val) => {
    val();
  };

  const [panelControl,setPanelControl] = useState(false)
  const [panelExpand, setPanelExpand] = useState(panelsLength)
  const downloadPanelExpand = () =>{
    setPanelControl(true)
    setPanelExpand(panelsLength)
  }

  const [completedAlert, setCompletedAlert] = useState()
  const completeAlert = () => {
    setCompletedAlert(true);
  }
  const completeAlertFalse = () => {
    setCompletedAlert(false);
  }
  
  const [enableSave,setEnableSave] = useState(true)
  const disableSave = () =>{
    setEnableSave(false)
  }

  const defaultSave = () =>{
    setEnableSave(true)
  }

  const [autoControlval,setAutoControlVal] = useState(false)
  const handleAutoControlIndicator = () =>{
    setAutoControlVal(!autoControlval)
  }

  const panelOrder = []
  const downloadWorkupPanelOrder = (val) =>{
    panelOrder.push(val)
  }

  const [context, setContext] = useState();
  return (
    apiResponse &&
    Object.keys(apiResponse).length !== 0 && (
      <div className="antigram-panel-root">
        <WorkupIinterpretationData.Provider value={[context, setContext]}>
          <AntigramHeader
          downloadPanelExpand={downloadPanelExpand}
            apiAllData={apiResponse}
            prevApiAllData={prevApiData}
            rmToggle={rmToggle}
            ruleType={ruleType}
            updateRules={updateRules}
            handleRMToggle={handleRMToggle}
            classificRule={classificRule}
            updateApiData={handleUpdateApiData}
            panelCount={panelCount}
            completedAlert={completedAlert}
            completeAlert={completeAlert}
            completeAlertFalse={completeAlertFalse}
            enableSave={enableSave}
            disableSave={disableSave}
            defaultSave={defaultSave}
            autoControlval={autoControlval}
            panelOrder={panelOrder}
          />
          <AntigramTable
          handleAutoControlIndicator={handleAutoControlIndicator}
          panelExpand={panelExpand}
          panelControl={panelControl}
            apiAllData={apiResponse}
            panelsLength={panelsLength}
            ruleType={ruleType}
            rmToggle={rmToggle}
            handleRMToggle={handleRMToggle}
            handleRuleUpdates={handleRuleUpdates}
            workupId={panelId}
            autoControlClick={autoControlClick}
            completeAlert={completeAlert}
            enableSave={enableSave}
            disableSave={disableSave}
            downloadWorkupPanelOrder={downloadWorkupPanelOrder}
          />
        </WorkupIinterpretationData.Provider>
      </div>
    )
  );
};

export default AntigramPanel;
