import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";

export const getResults = async () => {
  try {
    let url = `${REACT_APP_API_ENDPOINT}org/results`;
    const response = await Api.get(url, true);
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};
export const getUniqueResults = async () => {
  try {
    let url = `${REACT_APP_API_ENDPOINT}org/results?no_workups=${1}`;
    const response = await Api.get(url, true);
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};
export const getSingleResult = async (panelId) => {
  try {
    const response = await Api.get(
      `${REACT_APP_API_ENDPOINT}%7BorgScope%7D/results/` + panelId,
      true
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};
export const getDownloadResults = async (panelId) => {
  try {
    const response = await Api.get(
      `${REACT_APP_API_ENDPOINT}org/results/${panelId}/appendix`,
      true
    );

    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};

export const getPanelDetails = async (panelId) => {
  try {
    const response = await Api.get(
      `${REACT_APP_API_ENDPOINT}org/panels/${panelId}/appendix`,
      true
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
    return err;
  }
};
