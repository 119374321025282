import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";

export const createWorkups = async (data) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}org/workups`,data
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};

