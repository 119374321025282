import React, { useState, useEffect,useContext } from "react";
import { AppBar, Badge, Toolbar, IconButton, Typography } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircle from "@mui/icons-material/AccountCircle";
import "./index.scss";
import MenuDropDown from "../menuDropDown/MenuDropDown";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TuneIcon from "@mui/icons-material/Tune";
import { ReactComponent as CheckCircle } from "../../assets/images/CheckCircle.svg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ReactComponent as Settings } from "../../assets/images/Settings_24px.svg";
import { Link, useNavigate } from "react-router-dom";
import { NewResultNotificationContainer } from "./newResultsNotification/NewResultNotificationContainer";
import Constants from "../../languages/language";
import { getUserInfo } from "../../Redux/Actions/user.js";
import { getStatus } from "../../utils/global";
import { SpinnerContext } from "../../context/spinner-context";
//Header Component
const demoText = Constants.downloadResults;

const Header = (props) => {
  const { userData } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const style = {
    position: "absolute",
    right: 0,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [apiResponse, setapiResponse] = useState([]);

  const getUserData = async () => {
    const res = await getUserInfo();
    const responseData = res;
    setapiResponse(responseData);
    if (res?.status !== 200) {
      var err = res;
      getStatus(err, navigate);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {userData?.app_name}         
          <div className="demo-purpose">{demoText.demo_text}</div>         
          <Typography component="div" sx={{ flexGrow: 1 }}></Typography>
            <NewResultNotificationContainer />
          <div className="header-icon-name-section" onClick={handleClick}>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle className="user-avatar" />
          </IconButton>
          <Typography variant="h7" component="div" className="user-details" >
            <span className="user-name">
              {apiResponse?.first_name + " " + apiResponse?.last_name}
            </span>
            <span className="user-destination">{userData.account_name}</span>
          </Typography>
          </div>
          <MenuDropDown
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
          />
        </Toolbar>
      </AppBar>
    </>
  );
};
export default Header;
