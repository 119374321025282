import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import RubikMedium from "../../rubikFonts/RubikMedium.js";
import RubikRegular from "../../rubikFonts/RubikRegular.js";
import RubikSemiBold_600 from "../../rubikFonts/Rubik-SemiBold-normal.js";
import Rubik_italic from "../../rubikFonts/Rubik-italic.js";
import autoTable from 'jspdf-autotable'
import { getPanelNotes } from "../../../Redux/Actions/panelnotes.js";
import dayjs from "dayjs";
import { DateFormat } from "../../../utils/dateFormat.js";
import CheckSubmit from "../../../assets/images/CheckmarkSubmit.png"
import crossMark from "../../../assets/images/crossMarkDownload.png"
import Constants from "../../../languages/language.js"
import { autoControl } from "../../../Redux/Actions/workups.js";
import {getStatus} from "../../../utils/global";

const DownloadWorkup = (downloadApi, downloadNotes, setActiveTabValue, downloadManual, downloadComplete, rmToggle, downloadFailFunc, panelNotesData,
    panelOrder) => {
    const constants = Constants?.downloadWorkup
    const name = downloadApi?.workup?.name
    const status = downloadApi?.workup?.status
    let lastModified = downloadApi?.workup?.modified?.date_time
    lastModified = dayjs(lastModified?.date_time).format(DateFormat() + " - HH:MM")

    let PanelLength = downloadApi?.panels?.length
    let sampleIds = []

    for (let i = 0; i < PanelLength; i++) {
        sampleIds.push(downloadApi?.panels[i].panel.sample.unhashed)
    }

    const current = new Date();
    const downloadDate = dayjs(current?.date_time).format(DateFormat());

    let pdf = new jsPDF("p", "px", "letter");
    pdf.setFillColor(255, 0, 0);
    pdf.addFileToVFS("Rubik-Medium-normal.ttf", RubikMedium);
    pdf.addFont("Rubik-Medium-normal.ttf", "Rubik-Medium", "normal");
    pdf.addFileToVFS("Rubik-Regular-normal.ttf", RubikRegular);
    pdf.addFont("Rubik-Regular-normal.ttf", "Rubik-Regular", "normal");
    pdf.addFileToVFS('Rubik-SemiBold-normal.ttf', RubikSemiBold_600);
    pdf.addFont('Rubik-SemiBold-normal.ttf', 'Rubik-SemiBold', 'normal');
    pdf.addFileToVFS('Rubik-Italic-normal.ttf', Rubik_italic);
    pdf.addFont('Rubik-Italic-normal.ttf', 'Rubik-Italic', 'normal');
    let headData, headWidth, headHeight, panelData, panelWidth, panelHeight, antigenData, antigenWidth, antigenHeight = null;
    let save = null;
    const head = document.getElementById("workup-download")
    const classificationRow = document.getElementById("classification-row")

    const regex = /(<([^>]+)>)/ig;
    let downloadNotesNew = [];
    for (let a = 0; a < downloadNotes.length; a++) {
        downloadNotesNew.push(downloadNotes[a]?.content?.replace(regex, ''))
    }

    let submitFlag = false
    let subHeadPosition = 0;
    let pageCount = 1;
    html2canvas(head, {
        scale: 3,
    }).then((canvas) => {
        antigenData = canvas.toDataURL("image/png");
        let scaleBy = 3;
        const imgProps = pdf.getImageProperties(antigenData);
        antigenWidth = pdf.internal.pageSize.getWidth() - 40;
        antigenHeight = ((imgProps.height * antigenWidth) / imgProps.width);
        let context = canvas.getContext('2d');
        context.scale(scaleBy, scaleBy);

        if (name.length >= 43) {
            subHeadPosition = 18;
        }

        if (downloadApi?.workup?.submitted !== null && downloadApi?.workup?.recommended === false) {
            pdf.setFont("Rubik-SemiBold");
            pdf.setFontSize(16)

            pdf.text(115, 145 + ((sampleIds?.length - 1) * 14) + subHeadPosition, constants?.manual)
            pdf.text(40, 145 + ((PanelLength - 1) * 14) + subHeadPosition, constants?.submitted_small)
            pdf.addImage(CheckSubmit, 'PNG', 20, 136 + ((sampleIds?.length - 1) * 14) + subHeadPosition)
            submitFlag = true
            downloadManual(true)
            setTimeout(Manual, 2000);
        }
        else {
            pdf.setFont("Rubik-SemiBold");
            pdf.setFontSize(16)
            if (downloadApi?.workup?.submitted === null) {
                pdf.text(20, 145 + ((sampleIds?.length - 1) * 14) + subHeadPosition, constants?.recommended)
            }
            else {
                pdf.text(115, 145 + ((sampleIds?.length - 1) * 14) + subHeadPosition, constants?.recommended)
                pdf.addImage(CheckSubmit, 'PNG', 20, 136 + ((sampleIds?.length - 1) * 14) + subHeadPosition)
                pdf.text(40, 145 + ((PanelLength - 1) * 14) + subHeadPosition, constants?.submitted_small)
                submitFlag = true
            }
            if (rmToggle === true) {
                downloadManual(false)
                setTimeout(firstClassificationRow, 3000);
                setTimeout(Recommended, 4000);
            }
            else {
                firstClassificationRow();
                Recommended();
            }

        }
    })

    const firstClassificationRow = async () => {
        if (classificationRow === null || undefined) {
            downloadFailFunc()
        }
        else {
            await html2canvas(classificationRow, {
                scale: 3,
            }).then((canvas) => {
                antigenData = canvas.toDataURL("image/png");
                let scaleBy = 3;
                const imgProps = pdf.getImageProperties(antigenData);
                antigenWidth = pdf.internal.pageSize.getWidth() - 40;
                antigenHeight = ((imgProps.height * antigenWidth) / imgProps.width);
                let context = canvas.getContext('2d');
                context.scale(scaleBy, scaleBy);
                pdf.setPage(1)
                pdf.addImage(antigenData, 'PNG', 20, 170 + ((sampleIds?.length - 1) * 14) + subHeadPosition, antigenWidth, antigenHeight)
            })
        }
    }

    let y_position = 0;

    const heading = (value) => {
        pdf.setPage(value);
        pdf.setFont("Rubik-Regular")
        pdf.setFontSize(10)
        pdf.text(250, 26, constants?.download_date + downloadDate)
        pdf.text(360, 26, constants?.page + value + constants?.of)

        pdf.setFontSize(15);
        pdf.setFont("Rubik-Regular");
        pdf.text(20, 28, constants?.ortho_logo);
        pdf.setFont("Rubik-SemiBold");
        pdf.setFontSize(20);
        let position = 0;
        if (name.length <= 21) {
            pdf.text(20, 60, name)
        }
        else {
            if (name.length >= 42) {
                position = position + 21
                pdf.text(20, 60, name.substring(0, 21))
                pdf.text(20, 75, name.substring(21, 42))
                pdf.text(20, 90, name.substring(42, name.length))
            }
            else {
                position = position + 8
                pdf.text(20, 60, name.substring(0, 21))
                pdf.text(20, 75, name.substring(21, name.length))
            }
        }
        pdf.setFont("Rubik-Regular")
        pdf.setFontSize(10)
        pdf.text(50, 80 + position, status)
        pdf.text(155, 80 + position, lastModified)
        pdf.text(85, 93 + position, downloadApi?.workup?.modified?.first_name + " " + downloadApi?.workup?.modified?.last_name)
        pdf.setFont("Rubik-SemiBold");
        pdf.setFontSize(10)
        pdf.text(20, 80 + position, constants?.status)
        pdf.text(100, 80 + position, constants?.last_modified)
        pdf.text(20, 93 + position, constants?.last_modified_by)

        for (let i = 0; i < sampleIds.length; i++) {
            let x_position = 0;
            if (sampleIds[i].length > 16) {
                x_position = (sampleIds[i].length - 16) * 3;
            }
            pdf.setFont("Rubik-SemiBold");
            pdf.setFontSize(12)
            pdf.text(20, 107 + position, "Sample ID: ")
            pdf.text(152 + x_position, 107 + position, "/  Sample Date: ")
            pdf.setFont("Rubik-Regular")
            pdf.setFontSize(10)
            pdf.text(72, 107 + position, sampleIds[i])
            pdf.text(225 + x_position, 107 + position, dayjs(downloadApi?.panels[i]?.panel?.sample?.date_time).format(DateFormat()))
            if (sampleIds.length > 1) {
                position = position + 14
                y_position = position - 14
            }

        }

        pdf.line(20, 120 + y_position , antigenWidth + 20, 120 + y_position)
        pdf.setLineDash([3, 1], 10);
        pdf.roundedRect(300, 40, 140, 60, 5, 5, 'S')
        pdf.setFont("Rubik-Regular")
        pdf.setFontSize(10)
        pdf.text(348, 73, constants?.patient_label)
    }

    if (name.length >= 43) {
        y_position = y_position + 15
    }

    const printAntigenTyping = async () => {
        let antigen = document.getElementById("download-antigen");
        if (antigen === null || undefined) {
            downloadFailFunc()
        }
        else {
            await html2canvas(antigen, {
                scale: 3,
            }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                let scaleBy = 3;
                const imgProps = pdf.getImageProperties(imgData);
                const imgWidth = pdf.internal.pageSize.getWidth() - 40;
                const imgHeight = ((imgProps.height * imgWidth) / imgProps.width);
                let context = canvas.getContext('2d');
                context.scale(scaleBy, scaleBy);
                heading(1);
                pdf.setFont("Rubik-Italic")
                pdf.setFontSize(10)
                pdf.text(20, 437 + y_position + subHeadPosition, constants?.dat_info)
                pdf.text(20, 252 + y_position + subHeadPosition, constants?.autoControl_info)
                pdf.setFont("Rubik-Medium")
                pdf.setFontSize(12)
                pdf.text(20, 425 + y_position + subHeadPosition, constants?.dat)
                pdf.text(20, 335 + y_position + subHeadPosition, constants?.antigen)
                pdf.text(20, 240 + y_position + subHeadPosition, constants?.autoControl)
                pdf.addImage(imgData, 'PNG', 20, 350 + y_position + subHeadPosition, imgWidth, imgHeight);
                setActiveTabValue(2)
                setTimeout(Autocontrol, 1000);
            })
        }
    }

    const datImage = async () => {
        let datWrapper = document.getElementById("datWrapper")
        if (datWrapper === null || undefined) {
            downloadFailFunc()
        }
        else {
            await html2canvas(datWrapper, {
                scale: 3,
            }).then((canvas) => {
                const datData = canvas.toDataURL("image/png");
                let scaleBy = 3;
                const imgProps = pdf.getImageProperties(datData);
                const imgWidth = pdf.internal.pageSize.getWidth() - 40;
                const imgHeight = ((imgProps.height * imgWidth) / imgProps.width);
                let context = canvas.getContext('2d');
                context.scale(scaleBy, scaleBy);
                pdf.addImage(datData, 'PNG', 20, 442 + y_position + subHeadPosition, imgWidth, imgHeight);
            })
            DownloadWorkupNotes()
        }

    }

    const Autocontrol = async () => {
        let autControl = document?.getElementById("autoControl")
        if (autoControl === null || undefined) {
            downloadFailFunc()
        }
        else {
            await html2canvas(autControl, {
                scale: 8,
            }).then((canvas) => {
                const datData = canvas.toDataURL("image/png");
                let scaleBy = 8;
                const imgProps = pdf.getImageProperties(datData);
                const imgWidth = pdf.internal.pageSize.getWidth() - 300;
                const imgHeight = ((imgProps.height * imgWidth) / imgProps.width) - 880;
                let context = canvas.getContext('2d');
                context.scale(scaleBy, scaleBy);
                pdf.addImage(datData, 'PNG', 20, 260 + y_position + subHeadPosition, imgWidth, imgHeight);
            })
            setActiveTabValue(3)
            setTimeout(datImage, 500);
        }

    }

    const secondClassificationRow = async () => {
        if (classificationRow === null || undefined) {
            downloadFailFunc()
        }
        else {
            await html2canvas(classificationRow, {
                scale: 3,
            }).then((canvas) => {
                const secondAntigen = canvas.toDataURL("image/png");
                let scaleBy = 3;
                const imgProps = pdf.getImageProperties(antigenData);
                antigenWidth = pdf.internal.pageSize.getWidth() - 40;
                antigenHeight = ((imgProps.height * antigenWidth) / imgProps.width);
                let context = canvas.getContext('2d');
                context.scale(scaleBy, scaleBy);
                pdf.addPage();
                pageCount = pageCount + 1;
                pdf.setPage(pageCount)
                heading(pageCount)
                if (submitFlag === true) {
                    pdf.addImage(crossMark, 'PNG', 20, 134 + y_position)
                }
                if (downloadApi?.workup?.submitted !== null && downloadApi?.workup?.recommended === false) {
                    pdf.setFont("Rubik-SemiBold");
                    pdf.setFontSize(16)
                    pdf.text(132, 145 + ((sampleIds?.length - 1) * 14) + subHeadPosition, constants?.recommended)
                    pdf.text(40, 145 + ((sampleIds?.length - 1) * 14) + subHeadPosition, constants?.notSubmitted_small)
                }
                else {
                    if (status === "COMPLETED") {
                        pdf.setFont("Rubik-SemiBold");
                        pdf.setFontSize(16)
                        pdf.text(132, 145 + ((sampleIds?.length - 1) * 14) + subHeadPosition, constants?.manual)
                        pdf.text(40, 145 + ((sampleIds?.length - 1) * 14) + subHeadPosition, constants?.notSubmitted_small)
                    }
                    else {
                        pdf.setFont("Rubik-SemiBold");
                        pdf.setFontSize(16)
                        pdf.text(20, 145 + ((sampleIds?.length - 1) * 14) + subHeadPosition, constants?.manual)
                    }
                }
                pdf.addImage(secondAntigen, 'PNG', 20, 170 + ((sampleIds?.length - 1) * 14) + subHeadPosition, antigenWidth, antigenHeight)

            })
            if (downloadApi?.workup?.submitted === null || (downloadApi?.workup?.recommended !== false && downloadApi?.workup?.submitted !== null)) {
                setTimeout(Manual, 1000)
            }
        }

    }

    let emptyRowRec = []

    const Recommended = async () => {
        setActiveTabValue(0)
        if (head === null || undefined) {
            downloadFailFunc()
        }
        else {
            await html2canvas(head, {
                ignoreElements: function (element) {
                    if ('clear-button' == element.id) {
                        return true;
                    }
                },
                scale: 3,
            }).then((canvas) => {
                headData = canvas.toDataURL("image/png");
                let scaleBy = 3;
                const imgProps = pdf.getImageProperties(headData);
                headWidth = pdf.internal.pageSize.getWidth() - 40;
                headHeight = ((imgProps.height * headWidth) / imgProps.width);
                let context = canvas.getContext('2d');
                context.scale(scaleBy, scaleBy);
            })
        }

        for (let panelCount = 0; panelCount < PanelLength; panelCount++) {
            const doc = document.getElementById(panelOrder[panelCount] + "download");
            if (doc === null || undefined) {
                downloadFailFunc()
            }
            else {
                await html2canvas(doc, {
                    scale: 3,
                }).then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");
                    let scaleBy = 5;
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth() - 40;
                    const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width);
                    let context = canvas.getContext('2d');
                    context.scale(scaleBy, scaleBy);
                    pdf.addPage()
                    pageCount = pageCount + 1
                    emptyRowRec.push(pageCount)
                    pdf.setPage(pageCount)
                    heading(pageCount)
                    pdf.setFont("Rubik-Medium")
                    pdf.setFontSize(10)
                    if (status === "COMPLETED" && downloadApi?.workup?.recommended === false) {
                        pdf.setLineDash(0, 0)
                        pdf.rect(20, 137 + y_position, 63, 12)
                        pdf.rect(88, 137 + y_position, 61, 12)
                        pdf.text(90, 145 + y_position, constants?.recommended)
                        pdf.text(22, 145 + y_position, constants?.notSubmitted)
                    }
                    else {
                        if (status === "COMPLETED") {
                            pdf.setLineDash(0, 0)
                            pdf.rect(83, 137 + y_position, 61, 12)
                            pdf.rect(20, 137 + y_position, 46, 12)
                            pdf.text(85, 145 + y_position, constants?.recommended)
                            pdf.text(22, 145 + y_position, constants?.submitted)
                        }
                        else {
                            pdf.setLineDash(0, 0)
                            pdf.rect(20, 137 + y_position, 61, 12)
                            pdf.text(22, 145 + y_position, constants?.recommended)
                        }
                    }
                    pdf.addImage(headData, 'PNG', 20, 160 + y_position, headWidth, headHeight);

                    pdf.addImage(imgData, 'PNG', 20, 207 + y_position, pdfWidth, pdfHeight + 10);
                })
            }

        }



        if (downloadApi?.workup?.submitted !== null && downloadApi?.workup?.recommended === false) {
            setTimeout(panelHead, 2000)
        }
        else {
            await downloadManual(true)
            setTimeout(secondClassificationRow, 3000);

        }

    }

    const Manual = async () => {
        if (head === null || undefined) {
            downloadFailFunc()
        }
        else {
            await html2canvas(head, {
                ignoreElements: function (element) {
                    if ('clear-button' == element.id) {
                        return true;
                    }

                },
                scale: 3,
            }).then((canvas) => {
                headData = canvas.toDataURL("image/png");
                let scaleBy = 3;
                const imgProps = pdf.getImageProperties(headData);
                headWidth = pdf.internal.pageSize.getWidth() - 40;
                headHeight = ((imgProps.height * headWidth) / imgProps.width);
                let context = canvas.getContext('2d');
                context.scale(scaleBy, scaleBy);
            })
        }

        let emptyRow = []
        for (let panelCount = 0; panelCount < PanelLength; panelCount++) {
            const doc = document.getElementById(panelOrder[panelCount] + "download");
            if (doc === null || undefined) {
                downloadFailFunc()
            }
            else {
                await html2canvas(doc, {
                    scale: 3,
                }).then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");
                    let scaleBy = 5;
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth() - 40;
                    const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width);
                    let context = canvas.getContext('2d');
                    context.scale(scaleBy, scaleBy);
                    pdf.addPage()
                    pageCount = pageCount + 1
                    emptyRow.push(pageCount)
                    pdf.setPage(pageCount)
                    heading(pageCount)
                    pdf.setFont("Rubik-Medium")
                    pdf.setFontSize(10)
                    if (status === "COMPLETED" && downloadApi?.workup?.recommended === false) {
                        pdf.setLineDash(0, 0)
                        pdf.rect(20, 137 + y_position, 46, 12)
                        pdf.rect(78, 137 + y_position, 36, 12)
                        pdf.text(80, 145 + y_position, constants?.manual)
                        pdf.text(22, 145 + y_position, constants?.submitted)
                    }
                    else {
                        if (status === "COMPLETED") {
                            pdf.setLineDash(0, 0)
                            pdf.rect(20, 137 + y_position, 63, 12)
                            pdf.rect(93, 137 + y_position, 36, 12)
                            pdf.text(95, 145 + y_position, constants?.manual)
                            pdf.text(22, 145 + y_position, constants?.notSubmitted)
                        }
                        else {
                            pdf.setLineDash(0, 0)
                            pdf.rect(20, 137 + y_position, 36, 12)
                            pdf.text(22, 145 + y_position, constants?.manual)
                        }
                    }
                    pdf.addImage(headData, 'PNG', 20, 160 + y_position, headWidth, headHeight);

                    pdf.addImage(imgData, 'PNG', 20, 207 + y_position, pdfWidth, pdfHeight + 10);
                })
            }

        }
        if ((downloadApi?.workup?.submitted !== null && downloadApi?.workup?.recommended === true) || (downloadApi?.workup?.submitted === null || undefined))
            for (let rowCount = 0; rowCount < PanelLength; rowCount++) {
                const panel = document.getElementById(panelOrder[rowCount] + "panel");
                if (panel === null || undefined) {
                    downloadFailFunc()
                }
                else {
                    await html2canvas(panel, {
                        ignoreElements: function (element) {
                            if ('seeDetails' == element.id || 'panelNotes' == element.id) {
                                return true;
                            }

                        },
                        scale: 3,
                    }).then((canvas) => {
                        panelData = canvas.toDataURL("image/png");
                        let scaleBy = 3;
                        const imgProps = pdf.getImageProperties(panelData);
                        panelWidth = pdf.internal.pageSize.getWidth() - 40;
                        panelHeight = ((imgProps.height * panelWidth) / imgProps.width);
                        let context = canvas.getContext('2d');
                        context.scale(scaleBy, scaleBy);
                        pdf.setPage(emptyRowRec[rowCount])
                        pdf.addImage(panelData, 'PNG', 20, 191 + y_position, panelWidth, panelHeight)
                    })
                }
            }
        for (let rowCount = 0; rowCount < PanelLength; rowCount++) {
            const panel = document.getElementById(panelOrder[rowCount] + "panel");
            if (classificationRow === null || undefined) {
                downloadFailFunc()
            }
            else {
                await html2canvas(panel, {
                    ignoreElements: function (element) {
                        if ('seeDetails' == element.id) {
                            return true;
                        }

                    },
                    scale: 3,
                }).then((canvas) => {
                    panelData = canvas.toDataURL("image/png");
                    let scaleBy = 3;
                    const imgProps = pdf.getImageProperties(panelData);
                    panelWidth = pdf.internal.pageSize.getWidth() - 40;
                    panelHeight = ((imgProps.height * panelWidth) / imgProps.width);
                    let context = canvas.getContext('2d');
                    context.scale(scaleBy, scaleBy);
                    pdf.setPage(emptyRow[rowCount])
                    pdf.addImage(panelData, 'PNG', 20, 191 + y_position, panelWidth, panelHeight)
                })
            }
        }

        if (downloadApi?.workup?.submitted !== null && downloadApi?.workup?.recommended === false) {
            firstClassificationRow();
            await downloadManual(false);
            setTimeout(secondClassificationRow, 2000)
            setTimeout(Recommended, 2000);
        }
        else {
            setActiveTabValue(1)
            setTimeout(printAntigenTyping, 2000)
        }

    }
    const panelHead = async () => {
        if (downloadApi?.workup?.submitted !== null && downloadApi?.workup?.recommended === false) {
            for (let rowCount = 0; rowCount < PanelLength; rowCount++) {
                pdf.setPage(emptyRowRec[rowCount])
                const panel = document.getElementById(panelOrder[rowCount]+ "panel");
                if (panel === null || undefined) {
                    downloadFailFunc()
                }
                else {
                    await html2canvas(panel, {
                        ignoreElements: function (element) {
                            if ('seeDetails' == element.id || 'panelNotes' == element.id) {
                                return true;
                            }

                        },
                        scale: 3,
                    }).then((canvas) => {
                        panelData = canvas.toDataURL("image/png");
                        let scaleBy = 3;
                        const imgProps = pdf.getImageProperties(panelData);
                        panelWidth = pdf.internal.pageSize.getWidth() - 40;
                        panelHeight = ((imgProps.height * panelWidth) / imgProps.width);
                        let context = canvas.getContext('2d');
                        context.scale(scaleBy, scaleBy);

                        pdf.addImage(panelData, 'PNG', 20, 191 + y_position, panelWidth, panelHeight)
                    })
                }
            }
        }
        setActiveTabValue(1)
        setTimeout(printAntigenTyping, 2000)
    }
    const DownloadWorkupNotes = () => {
        pdf.addPage();

        let workupEndScale = 160 + y_position;
        if (downloadNotes.length === 0) {
            pdf.setFontSize(12)
            pdf.setFont("Rubik-Medium")
            pdf.autoTable({
                margin: { top: workupEndScale },

                head: [[{ content: constants?.no_workup_notes, styles: { fillColor: "#F3F6F7", textColor: "#34383C", font: "Rubik-Medium", cellPadding: { top: 9, right: 16, bottom: 8, left: 8 } } },
                ]]
            })
        }
        else {
            for (var i = 0; i < downloadNotes.length; i++) {

                pdf.autoTable({
                    pageBreak: "auto",
                    margin: { top: workupEndScale },

                    head: [[{ content: downloadNotes[i].created.first_name + " " + downloadNotes[i].created.last_name + "              " + dayjs(downloadNotes[i].created.date_time).format(DateFormat() + " - HH:mm"), styles: { fillColor: "#F3F6F7", textColor: "#34383C", font: "Rubik-Medium", cellPadding: { top: 9, right: 16, bottom: 8, left: 8 } } },
                    ]],
                    body: [[{ content: downloadNotesNew[i], styles: { fillColor: "#F3F6F7", font: "Rubik-Regular", cellPadding: { top: 0, right: 26, bottom: 16, left: 8 } } }]]
                })


            }
        }
        for (var pageLoop = pageCount + 1; pageLoop <= pdf.internal.getNumberOfPages(); pageLoop++) {
            pdf.setPage(pageLoop)
            pdf.setFont("Rubik-Medium")
            pdf.setFontSize(16)
            pdf.text(20, 140 + y_position, constants?.workup_notes)
            heading(pageLoop)
        }
        DownloadPanelNotes(pageLoop);
    }

    const DownloadPanelNotes = (param) => {
        pdf.addPage();
        pdf.setFont("Rubik-Medium")
        pdf.setFontSize(16)
        let panelEndScale = 210 + y_position
        let panelTypeCount = 0;
        for (var i = 0; i < panelNotesData.length; i++) {
            for (let a = param; a <= panelTypeCount; a++) {

                pdf.setPage(a)
                pdf.setFontSize(12)
                pdf.setFont("Rubik-Medium")
                pdf.text(constants?.panel + downloadApi?.panels[i - 1]?.panel?.panel_type, 30, panelEndScale - 42);
                let row_position = 0;
                let sample_position = 0;
                pdf.setFontSize(10)
                pdf.setFont("Rubik-Medium")
                pdf.text(30, panelEndScale - 22, "Lot no.")
                if (downloadApi?.panels[i - 1].panel?.lot_number.length >= 6) {
                    row_position = row_position + (3 *
                        (downloadApi?.panels[i - 1].panel?.lot_number.length - 5));
                }
                if (downloadApi?.panels[i - 1].panel?.sample?.unhashed.length >= 17) {
                    sample_position = sample_position + ((downloadApi?.panels[i - 1].panel?.sample?.unhashed.length - 16) * 3)
                }
                pdf.text(89 + row_position, panelEndScale - 22, "/ Exp. date:")
                pdf.text(30, panelEndScale - 10, "Sample ID: ")
                pdf.text(145 + sample_position, panelEndScale - 10, "/ Sample Date: ")
                pdf.setFont("Rubik-Regular")
                pdf.text(59, panelEndScale - 22, downloadApi?.panels[i - 1].panel?.lot_number)
                pdf.text(70, panelEndScale - 10, downloadApi?.panels[i - 1].panel?.sample?.unhashed)
                pdf.text(200 + sample_position, panelEndScale - 10, dayjs(downloadApi?.panels[i - 1]?.panel?.sample?.date_time).local().format(DateFormat()))
                pdf.text(132 + row_position, panelEndScale - 22, dayjs(downloadApi?.panels[i - 1]?.panel?.expiration_date).local().format(DateFormat()))
            }
            if (i > 0) {
                param = panelTypeCount + 1;
            }
            for (let k = 0; k < panelNotesData[i].length; k++) {
                pdf.setFont("Rubik-Medium")

                if (k < 1 && i > 0) {
                    pdf.addPage()
                }

                pdf.autoTable({
                    pageBreak: "auto",
                    margin: { top: panelEndScale },
                    head: [[{ content: panelNotesData[i][k].created.first_name + " " + panelNotesData[i][k].created.last_name + "              " + dayjs(panelNotesData[i][k].created.date_time).local().format(DateFormat() + " - HH:mm"), styles: { fillColor: "#F3F6F7", textColor: "#34383C", font: "Rubik-Medium", cellPadding: { top: 9, right: 0, bottom: 8, left: 8 } } }]],
                    body: [[{ content: panelNotesData[i][k].content.replace(regex, ''), styles: { fillColor: "#F3F6F7", font: "Rubik-Regular", cellPadding: { top: 0, right: 26, bottom: 16, left: 8 }, colSpan: 2 } }]]
                })
                panelTypeCount = pdf.internal.getCurrentPageInfo().pageNumber

            }

            if (panelNotesData[i]?.length === 0 || null || undefined) {
                if (i > 0) {
                    pdf.addPage();
                }
                pdf.autoTable({
                    margin: { top: panelEndScale },
                    head: [[{ content: constants?.no_panel_notes, styles: { fillColor: "#F3F6F7", textColor: "#34383C", font: "Rubik-Medium", cellPadding: { top: 9, right: 0, bottom: 8, left: 8 } } }]]
                })
                panelTypeCount = pdf.internal.getCurrentPageInfo().pageNumber

            }


            for (var pageLoop = param; pageLoop <= pdf.internal.getNumberOfPages(); pageLoop++) {
                pdf.setPage(pageLoop)
                pdf.setFont("Rubik-Medium")
                pdf.setFontSize(16)
                pdf.text(20, 140 + y_position, constants?.panel_notes)
                heading(pageLoop)
            }
        }
        for (let b = param; b <= panelTypeCount; b++) {

            pdf.setPage(b)
            pdf.setFontSize(12)
            pdf.setFont("Rubik-Medium")
            pdf.text(constants?.panel + downloadApi?.panels[i - 1]?.panel?.panel_type, 30, panelEndScale - 42);
            let row_position = 0;
            let sample_position = 0;
            pdf.setFontSize(10)
            pdf.setFont("Rubik-Medium")
            pdf.text(30, panelEndScale - 22, "Lot no.")
            if (downloadApi?.panels[i - 1].panel?.lot_number.length >= 6) {
                row_position = row_position + (3 *
                    (downloadApi?.panels[i - 1].panel?.lot_number.length - 5));
            }
            if (downloadApi?.panels[i - 1].panel?.sample?.unhashed.length >= 17) {
                sample_position = sample_position + ((downloadApi?.panels[i - 1].panel?.sample?.unhashed.length - 16) * 3)
            }
            pdf.text(89 + row_position, panelEndScale - 22, "/ Exp. date:")
            pdf.text(30, panelEndScale - 10, "Sample ID: ")
            pdf.text(145 + sample_position, panelEndScale - 10, "/ Sample Date: ")
            pdf.setFont("Rubik-Regular")
            pdf.text(59, panelEndScale - 22, downloadApi?.panels[i - 1].panel?.lot_number)
            pdf.text(70, panelEndScale - 10, downloadApi?.panels[i - 1].panel?.sample?.unhashed)
            pdf.text(200 + sample_position, panelEndScale - 10, dayjs(downloadApi?.panels[i - 1]?.panel?.sample?.date_time).local().format(DateFormat()))
            pdf.text(132 + row_position, panelEndScale - 22, dayjs(downloadApi?.panels[i - 1]?.panel?.expiration_date).local().format(DateFormat()))
        }
        downloadComplete();
        pdf.setFont("Rubik-Regular")
        pdf.setFontSize(10)
        for (let header = 1; header <= pdf.internal.getNumberOfPages(); header++) {
            pdf.setPage(header)
            pdf.text(panelTypeCount.toString(), 397, 26)
        }

        save = pdf.save("workup-" + downloadApi?.workup?.name + ".pdf")
    }
    save;
}

export default DownloadWorkup