import React from "react";

const SlashMask = ({ fillColor }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.93968 9.01999L5.11968 5.83999L5.95968 4.99999L9.13968 1.81999C9.37968 1.57999 9.37968 1.21999 9.13968 0.979988C8.89968 0.739988 8.53968 0.739988 8.29968 0.979988L5.11968 4.15999L4.27968 4.99999L1.09968 8.17999C0.859683 8.41999 0.859683 8.77999 1.09968 9.01999C1.21968 9.13999 1.33968 9.19999 1.51968 9.19999C1.69968 9.19999 1.81968 9.13999 1.93968 9.01999Z"
        fill={fillColor ? fillColor : "#34383C"}
      />
    </svg>
  );
};
const CrossMask = ({ fillColor, width, height }) => {
  return (
    <svg
      width={width ? width : "10"}
      height={height ? height : "10"}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0198 8.17999C9.2598 8.41999 9.2598 8.77999 9.0198 9.01999C8.8998 9.13999 8.7798 9.19999 8.5998 9.19999C8.4198 9.19999 8.2998 9.13999 8.1798 9.01999L4.9998 5.83999L1.8198 9.01999C1.6998 9.13999 1.5798 9.19999 1.3998 9.19999C1.2198 9.19999 1.0998 9.13999 0.979805 9.01999C0.739805 8.77999 0.739805 8.41999 0.979805 8.17999L4.1598 4.99999L0.979805 1.81999C0.739805 1.57999 0.739805 1.21999 0.979805 0.979988C1.2198 0.739988 1.5798 0.739988 1.8198 0.979988L4.9998 4.15999L8.1798 0.979988C8.4198 0.739988 8.7798 0.739988 9.0198 0.979988C9.2598 1.21999 9.2598 1.57999 9.0198 1.81999L5.8398 4.99999L9.0198 8.17999Z"
        fill={fillColor ? fillColor : "#34383C"}
      />
    </svg>
  );
};
const EllipseMask = ({ fillColor }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7.9998"
        cy="7.99999"
        r="6.2"
        stroke={fillColor ? fillColor : "#34383C"}
        strokeWidth="2"
      />
    </svg>
  );
};
const GreenTick = ({ fillColor, width, height }) => {
  return (
    <svg
      width={width ? width : "20"}
      height={height ? height : "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
        fill={fillColor ? fillColor : "#789D4A"}
      />
    </svg>
  );
};

const ErrorCode = ({ fillColor, width, height }) => {
  return (
    <svg
      width={width ? width : "197"}
      height={height ? height : "196"}
      viewBox="0 0 197 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        width={width ? width : "196"}
        height={height ? height : "196"}
        rx="98"
        fill="#FDEBF0"
      />
      <path
        d="M102.667 106.333H94.3333V85.4999H102.667M102.667 123H94.3333V114.667H102.667M52.6667 135.5H144.333L98.5 56.3333L52.6667 135.5Z"
        fill={fillColor ? fillColor : "#8F191C"}
      />
    </svg>
  );
};

export { GreenTick };
export { SlashMask };
export { CrossMask };
export { EllipseMask };
export { ErrorCode };
