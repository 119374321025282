import React, { useState, useEffect, useRef, useContext } from "react";
import { getWorkups } from "../../../Redux/Actions/workups.js";
import { getWorkupsDetails } from "../../../Redux/Actions/workups.js";
import WorkupTable from "./WorkupTable";
import Constants from "../../../languages/language";
import { nameFormatter } from "../../../utils/tableValueFormatter";
import {
  saveFiltersToApi,
  updtSingletWorkups,
  geSingletWorkups,
} from "../../../Redux/Actions/workups";
import { getDatePartDayJs, getTableDateTimeFormat } from "../../../utils/date";
import { getMatchingField as getMatchingFieldUtil } from "../../../utils/search";
import {
  caseInsensitiveTextSortAsc,
  caseInsensitiveTextSortDesc,
} from "../../../utils/column-sort.js";
import { SpinnerContext } from "../../../context/spinner-context.js";
import { WorkupTestDetails } from "./workupTestTable/WorkupTestDetails.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Link, Stack } from "@mui/material";
import {
  addToWorkupStorage,
  createOrEditWorkupStorage,
  notificationStorage,
  resetCreateOrEditWorkupFlow,
  resetNotificationFlow
} from "../../../services/communicationService";
import { ErrorPopup } from "../../errorMessage/errorPopup.js";
import { userInfo } from "../../../Redux/Store/user-info.js";
import { CrossMask, GreenTick } from "../../../utils/icons.js";
import { getStatus } from "../../../utils/global.js";

const statusSort = Constants.testTable.statusSorting;
const workupDetails = Constants.workupHomePage.workupLabels;
const errorMessage = Constants.workupHomePage.existingWorkupErrorMeassage;
const workupErrorMessage = Constants.workupHomePage.workupError;
const workupSuccessMessage = Constants?.testTable?.testTableHeader;
const sidebar = Constants.SideBar_Navigation;

// Checks if there is any field where the searchtext matches.
const matcher = {
  result_dt: (value, searchText) => {
    return getTableDateTimeFormat(value).includes(searchText);
  },
};

const getMatchingField = (row, searchText) => {
  return getMatchingFieldUtil(row, searchText, matcher);
};

const getDataSatisfyingSearch = (data, searchText) => {
  if (data.length === 0 || searchText === "") {
    return data;
  }

  const lowerCaseSearchText = searchText.toLowerCase();
  const filteredData = data.filter((p) => {
    const matchingField = getMatchingField(p, lowerCaseSearchText);
    return matchingField ?? false;
  });

  return filteredData;
};

const getFilteredData = (data, filters) => {
  let filteredData = data;

  if (filters.statuses && filters.statuses.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.statuses.includes(p.status)
    );
  }

  if (filters.panelTypes && filters.panelTypes.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.panelTypes.includes(p.panel_type)
    );
  }

  if (filters.lastUpdatedBy && filters.lastUpdatedBy.length > 0) {
    filteredData = filteredData.filter((p) =>
      filters.lastUpdatedBy.includes(p.last_updated_by)
    );
  }

  if (filters.startDate) {
    const startDateDayJS = getDatePartDayJs(filters.startDate);

    filteredData = filteredData.filter(
      (p) =>
        p.result_dt && getDatePartDayJs(new Date(p.result_dt)) >= startDateDayJS
    );
  }

  if (filters.endDate) {
    const endDateDayJS = getDatePartDayJs(filters.endDate);
    filteredData = filteredData.filter(
      (p) =>
        p.result_dt && getDatePartDayJs(new Date(p.result_dt)) <= endDateDayJS
    );
  }

  return filteredData;
};

const sortAscFuncs = {
  status: (a, b) =>
    statusSort[a["status"].replace(" ", "")] <
    statusSort[b["status"].replace(" ", "")]
      ? -1
      : 1,
  regular: (col) => (a, b) => caseInsensitiveTextSortAsc(a[col], b[col]),
  date: (col) => (a, b) => a[col] < b[col] ? -1 : 1,
};

const sortDescFuncs = {
  status: (a, b) => sortAscFuncs.status(a, b) * -1,
  regular: (col) => (a, b) => caseInsensitiveTextSortDesc(a[col], b[col]),
  date: (col) => (a, b) => a[col] < b[col] ? 1 : -1,
};

const getFlattenedPosts = (posts) => {
  return posts.map((p) => {
    const flattenedData = {
      id: p.id,
      workup_name: p.name,
      status: p.status,
      submitted_date: p.submitted?.date_time,
    };
    if (p.patient) {
      flattenedData.patient_id = p.patient.id;
      flattenedData.patient_name = nameFormatter(
        p.patient.last_name,
        p.patient.first_name
      );
      flattenedData.last_updated_by = nameFormatter(
        p.patient.last_name,
        p.patient.first_name
      );
    }
    if (p.modified) {
      flattenedData.last_updated_by = nameFormatter(
        p.modified.first_name,
        p.modified.last_name
      );
      flattenedData.result_dt = p.modified.date_time;
    }
    return flattenedData;
  });
};

const WorkupHomePage = () => {
  const initilizationStatusRef = useRef({ state: "initializing" });
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { isCreateOrEditWorkupFlow, isNotificationFlow } = location.state || {};
  const isAddToWorkupFlow =
    location.state?.isAddToWorkupFlow ||
    (location.search && location.search.includes("addToWorkup=true"));
  const { selectedTestResult } = addToWorkupStorage;
  const postsRef = useRef({ allPosts: [] });
  const [apiError, setApiError] = useState();
  const [posts, setPosts] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [sortParam, setSortParam] = useState({});
  const [searchText, setSearchText] = useState(""); // text entered by user in search box
  const apiSearchTextRef = useRef(""); // ref of text being used to do backend filtering
  const useBackendFilteringRef = useRef(false);
  const [workupDetailsRequestById, setWorkupDetailsRequestById] = useState({});
  const [filterValues, setFilterValues] = useState({ lastUpdatedBy: [] });
  const [selectedWorkups, setSelectedWorkups] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showRefreshAlert, setShowRefreshAlert] = useState(
    isAddToWorkupFlow && selectedTestResult === null
  );
  const [allowAddToWorkup, setAllowAddToWorkup] = useState(false);
  const [showWorkupChangeAlert, setShowWorkupChangeAlert] = useState(
    isCreateOrEditWorkupFlow && createOrEditWorkupStorage.isTheFlowSuccessful
  );

  // id
  const [highlightedWorkupIds, setHighlightedWorkupIds] = useState(
    isNotificationFlow &&
      notificationStorage.notificationData.related_object?.id != null
      ? [notificationStorage.notificationData.related_object.id]
      : []
  );

  useEffect(() => {
    const hightlightedIds =
      isNotificationFlow &&
      notificationStorage.notificationData.related_object?.id != null
        ? [notificationStorage.notificationData.related_object.id]
        : [];
    setHighlightedWorkupIds(hightlightedIds);
  }, [notificationStorage.notificationData]);

  const updateFromApiRef = useRef();
  const searchTextClearedRef = useRef(false);

  const getWorkupsCall = async () => {
    const res = await getWorkups(apiSearchTextRef.current);

    if (res.status !== 200) {
      var err = res;
      if (err?.status === 400) {
        navigate(`/error`, {
          state: { status: err?.status, statusText: res.statusText },
        });
      }
      if (err?.status === 401) {
        navigate(`/error`, {
          state: { status: "401", statusText: res.statusText },
        });
      }
      if (err?.status === 402) {
        navigate(`/error`, {
          state: { status: "402", statusText: res.statusText },
        });
      }
      if (err?.status === 403) {
        navigate(`/error`, {
          state: { status: "403", statusText: "Forbidden access" },
        });
      }
      if (err?.status === 404) {
        navigate(`/error`, {
          state: { status: "404", statusText: res.statusText },
        });
      }
      if (err?.status === 405) {
        navigate(`/error`, {
          state: { status: "405", statusText: "Method not allowed" },
        });
      }
      if (err?.status === 0) {
        navigate(`/error`, {
          state: { status: "0", statusText: "strict-origin-when-cross-origin" },
        });
      }
    }
    changeSpinnerStatus(false);
    if (res) {
      updateAllData(res?.workups);
    }
    setApiError(null);
  };

  const getPostsToShow = (searchText, filters) => {
    const flattenedPosts = getFlattenedPosts(postsRef.current.allPosts);
    const lastUpdatedByList = [
      ...new Set(
        flattenedPosts
          .map((item) => item.last_updated_by)
          .filter((i) => i != null)
      ),
    ];

    setFilterValues({ lastUpdatedBy: lastUpdatedByList });
    let postsToShow = getDataSatisfyingSearch(flattenedPosts, searchText);
    postsToShow = getFilteredData(postsToShow, filters);

    if (isAddToWorkupFlow) {
      postsToShow = postsToShow.filter((r) => r.status != "COMPLETED");
    }

    return postsToShow;
  };

  updateFromApiRef.current = getWorkupsCall;

  useEffect(() => {
    changeSpinnerStatus(true);
    initialize();
    const timer = setInterval(() => updateFromApiRef.current(), 30000);
    return () => clearInterval(timer);
  }, []);

  const initialize = async () => {
    await getWorkupsCall();
    if (!isAddToWorkupFlow) {
    var filters = userInfo.data.workups_filter;

    if (filters) {
      setFilters(JSON.parse(filters) || {});
    }
  }
    changeSpinnerStatus(false);
    initilizationStatusRef.current.state = "initialized";
  };

  const updateAllData = (data) => {
    postsRef.current.allPosts = data;
    updatePostsToShow();
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // Get current posts //
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // sorting for TestTable //

  const handleSortChanged = (field, sortDirection) => {
    setSortParam({ [field]: sortDirection });
  };

  //  On Page Change //
  const onChange = (event, nextPage) => {
    setCurrentPage(nextPage);
  };

  const updateFilters = async (filters) => {
    setFilters(filters);
    setCurrentPage(1);
    const res = await saveFiltersToApi(filters);
    if (res?.status !== 200) {
      var err = res;
      getStatus(err, navigate);
    }
    userInfo.data.workups_filter = JSON.stringify(filters);
  };

  useEffect(() => {
    if (searchTextClearedRef.current === true) {
      searchTextClearedRef.current = false;
      getWorkupsCall();
      return;
    }

    updatePostsToShow();
  }, [searchText, filters, sortParam, highlightedWorkupIds, isAddToWorkupFlow]);

  const updatePostsToShow = () => {
    const textToSearch = useBackendFilteringRef.current ? "" : searchText;
    const filteredPosts = getPostsToShow(textToSearch, filters);
    let sortedPosts = sortDataList(filteredPosts, sortParam);

    if (isNotificationFlow) {
      sortedPosts = [
        ...sortedPosts.filter(
          (r) => highlightedWorkupIds.find((id) => id == r.id) != null
        ),
        ...sortedPosts.filter(
          (r) => highlightedWorkupIds.find((id) => id == r.id) == null
        ),
      ];
    }

    setPosts(sortedPosts);
  };

  const sortDataList = (data, sortParam) => {
    let sortFunc;

    const keys = Object.keys(sortParam);
    // no sort param available
    if (keys.length == 0) {
      sortFunc = sortDescFuncs["date"]("result_dt");
    } else {
      const field = keys[0];

      // Ascending
      if (sortParam[field] == 0) {
        if (sortAscFuncs[field]) {
          sortFunc = sortAscFuncs[field];
        } else {
          sortFunc = sortAscFuncs["regular"](field);
        }
      }

      // Descending
      else if (sortParam[field] == 1) {
        if (sortDescFuncs[field]) {
          sortFunc = sortDescFuncs[field];
        } else {
          sortFunc = sortDescFuncs["regular"](field);
        }
      }
    }

    return data.sort(sortFunc);
  };

  const handleSearchText = (searchText) => {
    useBackendFilteringRef.current = false;
    setSearchText(searchText);
    setCurrentPage(1);
  };

  const handleSearchTextEnter = () => {
    apiSearchTextRef.current = searchText;
    useBackendFilteringRef.current = true;
    getWorkupsCall();
  };

  const handleClearSearch = () => {
    apiSearchTextRef.current = "";
    searchTextClearedRef.current = true;
    setSearchText("");
  };

  const fetchWorkupDetail = async ({ id }) => {
    setWorkupDetailsRequestById({
      ...workupDetailsRequestById,
      [id]: {
        isLoading: true,
      },
    });
    const data = await getWorkupsDetails(id);
    setWorkupDetailsRequestById({
      ...workupDetailsRequestById,
      [id]: { workupDetails: data },
    });

    if (data.status !== 200) {
      var err = data;
      getStatus(err, navigate);
      changeSpinnerStatus(false);
    }
  };

  const handleRowExpanded = (workup, isExpanded) => {
    if (isExpanded) {
      setExpandedRows([...expandedRows, workup.id]);
      fetchWorkupDetail(workup);
    } else {
      setExpandedRows(expandedRows.filter((id) => id != workup.id));
    }
  };

  const handleWorkupSelected = (workup, isSelected) => {
    if (isSelected) {
      setSelectedWorkups([...selectedWorkups, workup.id]);
    } else {
      setSelectedWorkups(selectedWorkups.filter((id) => id != workup.id));
    }
  };

  useEffect(() => {
    // if the flow is addtoworkup and there is any workup selected which has same name as the test result, dont proceed
    if (isAddToWorkupFlow) {
      setShowAlert(false);
      setAllowAddToWorkup(selectedWorkups.length > 0);
      checkIfWorkupOfTestResultSelected();
    } else {
      // normal flow
      setAllowAddToWorkup(selectedWorkups.length > 0);
    }
  }, [selectedWorkups]);

  const checkIfWorkupOfTestResultSelected = () => {
    var shallContinue = true;

    selectedWorkups.forEach(async (workupId) => {
      if (!shallContinue) {
        return;
      }

      const workupDetails = await getWorkupsDetails(workupId);
      const existingRefItem = (workupDetails?.panels || []).find(
        (p) =>
          p.test_result_reference_key ===
          selectedTestResult.test_result_reference_key
      );

      if (existingRefItem) {
        shallContinue = false;
        setShowAlert(true);
        setAllowAddToWorkup(false);
        return;
      }
    });
  };

  const handleAddToWorkup = async () => {
    const backgroundTasks = [];

    selectedWorkups.forEach(async (workupId) => {
      const workup = currentPosts.find((w) => workupId == w.id);
      if (workup) {
        const res = await geSingletWorkups(workup.id);

        const apiObj = {
          e_tag: res.e_tag,
          name: workup?.workup_name,
          reference_key_map: [
            {
              antigram_reference_key:
                selectedTestResult?.antigram_reference_key,
              test_result_reference_key:
                selectedTestResult?.test_result_reference_key,
            },
          ],
        };

        const task = updtSingletWorkups(workupId, apiObj);
        backgroundTasks.push(task);
        if (task.status !== 200) {
          var err = task;
          if (err?.status === 400) {
            navigate(`/error`, { state: { status: "400" } });
          }
          if (err?.status === 401) {
            navigate(`/error`, { state: { status: "401" } });
          }
          if (err?.status === 402) {
            navigate(`/error`, { state: { status: "402" } });
          }
          if (err?.status === 403) {
            navigate(`/error`, { state: { status: "403" } });
          }
          if (err?.status === 404) {
            navigate(`/error`, { state: { status: "404" } });
          }
          if (err?.status === 405) {
            navigate(`/error`, { state: { status: "405" } });
          }
          if (err?.status === 0) {
            navigate(`/error`, { state: { status: "0" } });
          }
        }
        changeSpinnerStatus(false);
      }
    });

    await Promise.all(backgroundTasks);
    addToWorkupStorage.isAddToWorkupFlowSuccess = true;
    addToWorkupStorage.isEditWorkup = true;

    navigate(`/`, {
      state: {
        isAddToWorkupFlow: true,
      },
    });
  };

  const handleNewWorkup = () => {
    resetCreateOrEditWorkupFlow();
    createOrEditWorkupStorage.isEdit = false;

    navigate(`/single-workup`, {
      state: {
        isAddToWorkupFlow,
        sidebarSelection: sidebar.Workups,
      },
    });
  };

  const handleWorkupEdit = (workupId) => {
    const workup = currentPosts.find((r) => r.id == workupId);
    resetCreateOrEditWorkupFlow();
    createOrEditWorkupStorage.workupName = workup.workup_name;
    createOrEditWorkupStorage.isEdit = true;
  };

  const handleCancelAddToWorkup = () => {
    navigate(`/`);
  };

  const handleSuccessClose = () => {
    setShowWorkupChangeAlert(false);
  };

  
  const handleClickedOutsideTable = () => {
    if (isNotificationFlow) {
      resetNotificationFlow();
      navigate(`/workup-home`);
    }
  };

  return (
    <div>
      {showWorkupChangeAlert && (
        <div className="workup-edit-success-message">
          <GreenTick width={25} height={25} />
          {workupSuccessMessage?.workupEditedMessage}
          <div style={{ cursor: "pointer" }} onClick={handleSuccessClose}>
            <CrossMask width={15} height={15} />
          </div>
        </div>
      )}
      {showAlert && (
        <ErrorPopup onClose={() => setShowAlert(false)}>
          {errorMessage}
        </ErrorPopup>
      )}
      {showRefreshAlert && (
        <ErrorPopup onClose={() => setShowRefreshAlert(false)}>
          {workupErrorMessage}
        </ErrorPopup>
      )}
      {isAddToWorkupFlow && (
        <WorkupTestDetails testResult={selectedTestResult}></WorkupTestDetails>
      )}
      {!isAddToWorkupFlow && (
        <h2 className="page-title">{workupDetails.workup}</h2>
      )}
      <WorkupTable
        posts={currentPosts}
        setPosts={setPosts}
        filters={filters}
        filterValues={filterValues}
        updateFilters={updateFilters}
        onSearchTextChange={handleSearchText}
        onEnter={handleSearchTextEnter}
        onClearSearch={handleClearSearch}
        searchText={searchText}
        onSortChanged={handleSortChanged}
        sortParam={sortParam}
        workupDetailsRequestById={workupDetailsRequestById}
        serverError={apiError}
        isAddWorkup={isAddToWorkupFlow}
        selectedWorkups={selectedWorkups}
        onWorkupSelected={handleWorkupSelected}
        highlightedWorkupIds={highlightedWorkupIds}
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        onChange={onChange}
        lastIndex={indexOfLastPost}
        startIndex={indexOfFirstPost}
        currentPosts={currentPosts}
        expandedRows={expandedRows}
        onRowExpanded={handleRowExpanded}
        onNewWorkup={handleNewWorkup}
        allowCreateWorkup={!(selectedTestResult === null && isAddToWorkupFlow)}
        onWorkupEdit={handleWorkupEdit}
        onClickedOutsideTable={handleClickedOutsideTable}
      />
      {isAddToWorkupFlow && (
        <AddToWorkUpActions
          onAddToWorkup={handleAddToWorkup}
          onCancelAddToWorkup={handleCancelAddToWorkup}
          allowToProceed={allowAddToWorkup}
        />
      )}
    </div>
  );
};

export default WorkupHomePage;

const AddToWorkUpActions = ({
  onAddToWorkup,
  onCancelAddToWorkup,
  allowToProceed,
}) => {
  return (
    <div className="button-container">
      <Stack direction="row">
        <Link
          className="cancel"
          variant="outlined"
          onClick={onCancelAddToWorkup}
        >
          {workupDetails.cancel}
        </Link>
      </Stack>{" "}
      <Stack direction="row">
        <Button
          className={allowToProceed ? "submit" : "submit btn-disabled"}
          type="button"
          onClick={onAddToWorkup}
          disabled={!allowToProceed}
        >
          {workupDetails.addToWorkup}
        </Button>
      </Stack>
    </div>
  );
};
