import * as React from "react";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import CloseIcon from "@mui/icons-material/Close";
import "./notes.scss";
import SearchFilter from "../filter/SearchFilter";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useState, useEffect, useContext } from "react";
import { getPanelNotes, savePanelNotes } from "../../Redux/Actions/panelnotes";
import Grid from "@mui/material/Grid";
import PanelNotesMenu from "./PanelNotesMenu.js";
import { Done, Close } from "@mui/icons-material";
import { DeleteWarning } from "./DeleteWarning";
import ReactQuill from "react-quill";
import { getUserInfo } from "../../Redux/Actions/user";
import "react-quill/dist/quill.snow.css";
import {
  updateEditedPanelNotesContent,
  deletePanelNotes,
} from "../../Redux/Actions/panelnotes";
import dayjs from "dayjs";
import { DateFormat } from "../../utils/dateFormat";
import "@progress/kendo-theme-default/dist/all.css";
import { CrossMask, GreenTick } from "../../utils/icons";
import Constants from "../../languages/language";
import LoadingSpinner from "./LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { getStatus } from "../../utils/global";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
const { Bold, Italic, Underline, OrderedList, UnorderedList } = EditorTools;
const stylesFont = `
  .k-content{
      font-family:"Rubik",sans-serif;
    }
`;
const PanelNotes = (props) => {
  const navigate = useNavigate();
  const [inputText, setInputText] = useState("");
  const [plainInputText, setPlainInputText] = useState("");
  const [data, setData] = useState([]);
  const [olddata, setoldData] = useState([]);
  const [editingNotesId, setEditingNotesId] = useState();
  const [deletingNotesId, setDeletingNotesId] = useState();
  const [notesSubmittedSuccessFully, setNotesSubmittedSuccessfully] =
    useState();
  const [isSendClicked, setIsSendClicked] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [previousEditContent, setPreviousEditContent] = useState(null);
  const [newlyUpdatedText, setNewlyUpdatedText] = useState(null);
  const [isEditedNotesUpdated, setIsEditedNotesUpdated] = useState();
  const [userMailAddress, setUserMailAddress] = useState();
  const [searchText, setsrchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteNoteStatus, setdeleteNoteStatus] = useState(false);
  const warningNote = Constants.WorkupNotes;
  const panelNotes =Constants.AntigenHeader;
  const waringMsg = Constants?.WorkupNotes;
  const noNotes = Constants.AntigenHeader.nopanelNotes;
  const panelID = props.panelId;
  const fontEditor = (event) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement("style");
    style.appendChild(iframeDocument.createTextNode(stylesFont));
    iframeDocument.body.appendChild(style);
  };
  const handleFlyOutPopUpClose = () => {
    props.handlePopupClose();
  };
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    updateNotes();
    getUserDetails();
  }, []);
  const getUserDetails = async () => {
    var res = await getUserInfo();
      setUserMailAddress(res.email);
      if(res?.status !== 200)
      {
        var err = res
        getStatus(err, navigate);
      }
  };
  const updateEditNotes = (value) => {
    const valueHtml = value.html.replace(/^\s*(\[\s*url\s*=\s*)?(https?:\/\/)?(http?:\/\/)?(www\.|\S+?\.)(\S+?\.)?\S+\s*$\s*/mg, '');
    setNewlyUpdatedText(valueHtml);
    let notesContent = [...data];
    let content = notesContent.filter((x) => x.id === editingNotesId).content;
    if (previousEditContent === null) {
      setPreviousEditContent(content);
    }
    notesContent
      .filter((x) => x.id === editingNotesId)
      .map((x) => (x.content = valueHtml));
    setData([...notesContent]);
    setoldData([...notesContent]);
  };
  const sendNotes = async (enteredText) => {
    setIsLoading(true);
    setIsSendClicked(true);
    const sendNotesObj = {
      content: inputText,
    };
    const res = await savePanelNotes(panelID, sendNotesObj);
    setNotesSubmittedSuccessfully(true);
    if(res?.status !== 200)
    {
      var err = res;
      getStatus(err, navigate);
    }
    setInputText("");
    setPlainInputText("");
    setIsLoading(false);
  };
  useEffect(() => {
    if (notesSubmittedSuccessFully && isSendClicked) {
      updateNotes();
      setIsSendClicked(false);
      setNotesSubmittedSuccessfully(false);
    }
  }, [notesSubmittedSuccessFully]);
  const updateNotes = async () => {
    const res = await getPanelNotes(panelID);
    if(res?.status !== 200)
    {
      var err = res;
      getStatus(err, navigate);
    }
    setData(res);
    setoldData(res);
  };
  const handleOnClick = (value, index) => {
    if (value === "Edit") {
      setEdit(true);
      setEditingNotesId(index);
      let oldNotesData = data.filter((x) => x.id === index);
      setPreviousEditContent(oldNotesData[0].content);
    } else if (value === "Delete") {
      setIsLoading(true);
      setDeletingNotesId(index);
      setIsDeleteClicked(true);
      setIsLoading(false);
    }
  };
  const handleOnClose = () => {};
  const handleDeleteWarningYes = (value) => {
    setIsDeleteClicked(false);
    deleteNote(panelID, deletingNotesId);
  };
  const deleteNote = async (panelID, index) => {
    const res = await deletePanelNotes(panelID, index);
      setIsLoading(true);
      setdeleteNoteStatus(true);
      setDeletingNotesId(0);
      updateNotes();
      if(res?.status !== 200)
      {
        var err = res;
        getStatus(err, navigate);
      }
    setIsLoading(false);
  };
  const updateSelectedNotes = async (panelID, index) => {
    const sendNotesObj = {
      content: newlyUpdatedText,
    };
    if(sendNotesObj.content){
      const res = await updateEditedPanelNotesContent(
      panelID,
      index,
      sendNotesObj
    );   
    if(res?.status !== 200)
    {
      var err = res;
      getStatus(err, navigate);
    }
    }
  };
  useEffect(() => {
    if (isEditedNotesUpdated) {
      setIsEditedNotesUpdated(false);
      updateNotes();
    }
  }, [isEditedNotesUpdated]);
  const handleDeleteWarningClose = (value) => {
    setIsDeleteClicked(false);
    setDeletingNotesId(0);
  };
  const handleEditCancel = async (index) => {
    let newdata = data
    newdata.map( (x) => {
      if(x.id === index){
        x.content = previousEditContent
      }
    })
    setData(newdata);
    setEditingNotesId(0);
    setNewlyUpdatedText("");
    setPreviousEditContent(null);
  };
  const handleEditSubmit = () => {
    setEditingNotesId(0);
    updateSelectedNotes(panelID, editingNotesId);
  };
  const populateNewComment = (event) => {
    setInputText(event.html);
    var plainText = event.html.replace(/<[^>]*>/g, "");
    plainText = plainText.replace(/^\s*(\[\s*url\s*=\s*)?(https?:\/\/)?(http?:\/\/)?(www\.|\S+?\.)(\S+?\.)?\S+\s*$\s*/mg, '');
    setPlainInputText(plainText);
  };
  const handletextChange = (enteredText) => {
    setsrchText(enteredText);
  };
  useEffect(() => {
    let notesList = olddata;
    if (searchText && searchText.length > 0) {
      notesList = notesList.filter(function (x) {
        let name =
          x.created.first_name + " " + x.created.last_name.toLowerCase().trim();
        return (
          x.content
            .toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim()) ||
          x.created.last_name
            .toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim()) ||
          x.created.first_name
            .toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim()) ||
          name.toLowerCase().includes(searchText.toLowerCase()) ||
          new Date(x.created.date_time)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .toLowerCase()
            .includes(searchText)
        );
      });
      setData(notesList);
    } else {
      setData(olddata);
    }
  }, [searchText]);
  const handleDeleteMessageClose = () => {
    setdeleteNoteStatus(false);
  };

  return (
    <div>
      {deleteNoteStatus === true ? (
        <div className="deletenote-success-message">
          <GreenTick width={25} height={25} />
          <b>{warningNote.note}&nbsp;:</b>
          {warningNote.message}
          <div style={{ cursor: "pointer", marginLeft:"auto" }} onClick={handleDeleteMessageClose}>
            <CrossMask width={15} height={15} />
          </div>
        </div>
      ) : (
        ""
      )}
      {deletingNotesId > 0 ? (
        <DeleteWarning title={waringMsg.deleteLogo} bodyTitle={waringMsg.title} deleteMsg={waringMsg?.accept} 
          handleDeleteWarningClose={(value) => {
            handleDeleteWarningClose(value);
          }}
          handleDeleteWarningYes={(value) => {
            handleDeleteWarningYes(value);
          }}
        />
      ) : (
        ""
      )}
      <div className="notes-header">
        <span>{panelNotes?.panelNotes}</span>
        <div className="notes-icons">
          <CloseIcon onClick={() => handleFlyOutPopUpClose()} />
        </div>
      </div>
      {props.statusFilter !== "COMPLETED" ? (
        <div>
          <div>
            <Editor
              className="Flyout-root"
              tools={[[Bold, Italic, Underline, OrderedList, UnorderedList]]}
              contentStyle={{
                width: "100%",
              }}
              value={inputText}
              onMount = { fontEditor }
              onChange={(e) => populateNewComment(e)}
            />
          </div>
          <div
            className="notes-save"
            onClick={sendNotes}
            disabled={plainInputText.length > 0 ? "" : "disbaled"}
          >
            <h3>{panelNotes.save}</h3>
            <SendOutlinedIcon className="save-btn" />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="notes-section">
        <div className="workup-root">
          <div className="left-sec">
            <SearchFilter
              className="search-filter"
              searchText={searchText}
              onSearchTextChange={(value) => handletextChange(value)}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <LoadingSpinner className="loading-spinner-flyout" />
      ) : (
        <div className="notes-content">
          {data && data?.length < 1 ? <div className="noNotes">{noNotes}</div> :
          <Grid container spacing={1} key="">
            {data && data.length > 0
              ? data.map((x, index) => (
                  <div key={x.id} className="notes-bg">
                    <div className="name-dis">
                      <div className="fName-lName" item xm={5}>
                        {x.created.first_name + " " + x.created.last_name}
                      </div>
                      <span className="date-time" item xl={5}>
                        {dayjs(x?.modified?.date_time ? x?.modified?.date_time:x?.created?.date_time)
                          .format(DateFormat() + " - h:mm A")}
                      </span>
                      <Grid
                        item
                        xm={1}
                        style={{ position: "relative", left: "2.8rem" }}
                      >
                        {x.created && x.created.email === userMailAddress && props.statusFilter !== "COMPLETED" ? (
                          <PanelNotesMenu
                            className="note-menu"
                            handleOnClick={(value) =>
                              handleOnClick(value, x.id)
                            }
                            handleOnClose={() => handleOnClose()}
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                    </div>
                    <Grid item xs={12} style={{ height: "fitContent" }}>
                      {editingNotesId === x.id ? (
                        <>
                          <Editor
                            className="Flyout-comment"
                            tools={[
                              [
                                Bold,
                                Italic,
                                Underline,
                                OrderedList,
                                UnorderedList,
                              ],
                            ]}
                            onMount = { fontEditor }
                            contentStyle={{
                              width: '100%',
                              opacity: 8,
                            }}
                            value={x.content}
                            onChange={(e) => updateEditNotes(e)}
                          />
                          <div className="action-strap">
                            <Close
                              className="icons"
                              onClick={() => handleEditCancel(x.id)}
                            />
                            <Done
                              id="done-icon"
                              className="icons"
                              onClick={() => handleEditSubmit()}
                            />
                          </div>
                        </>
                      ) : (
                        <ReactQuill
                          theme={false}
                          defaultValue={x.content}
                          style={{ minHeight: "fitContent" }}
                          readOnly={true}
                        />
                      )}
                    </Grid>
                  </div>
                ))
              : ""}
          </Grid>}
        </div>
      )}
    </div>
  );
};
export default PanelNotes;
