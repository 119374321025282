import React, { useState, useEffect } from "react";
import AlertDialogModal from "../../alertDialogModal/AlertDialogModal";
import SubmitModalSelector from "../submitModalSelector/submitModalSelector";
import RuledTab from "../antigramHeader/ClassificationRow/RuledTab";
import "../antigramHeader/ClassificationRow/index.scss";
import Constants from '../../../languages/language'
const HeaderReviewModal = (props) => {
  let ruleTyp = props.ruleType;
  const [selectedClassific, setselectedClassific] = useState(props.btnDisabled);
  
  useEffect(() => {
    setselectedClassific(props.btnDisabled);
  }, [props]);
  const selectedAnalysisKey = localStorage.getItem("selectedAnalysisKey");
  const [onRecChange, setOnRecChange] = useState("#F3F6F7");
  const view = Constants.workupDetails.editData.SelectFinal;
  const steps = Constants.workupDetails.editData.Steps;
  const modelButton =Constants.AntigenHeader;
  const handleRecColor = (val) => {
    setOnRecChange(val);
    setOnManualChange("#F3F6F7");
    setselectedClassific("Recommended");
  };
  const [onManualChange, setOnManualChange] = useState("#F3F6F7");
  const handleManualColor = (val) => {
    setOnManualChange(val);
    setOnRecChange("#F3F6F7");
    setselectedClassific("Manual");
  };
  const dualogTitleContent = () => {
    return (
      <>
        <div
          className="dialog-content"
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#000000",
          }}
        >
          { view }
          <span
            style={{
              fontSize: "14px",
              paddingLeft: "5px",
              fontWeight: "400",
              color: "#000000",
            }}
          >
            { steps }
          </span>
          <div className="classification-tab">
          <div className="label-row"
          style={{
            marginTop: "1.531rem",
          }}>
            <RuledTab />
          </div>
        </div>
        </div>
      </>
    );
  };
  const Styles = {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "100%",
        height: "500px",
        borderRadius: "8px",
        padding: "30px",
      },
      "& .MuiDialogTitle-root": {
        padding: "0px",
        "& .classification-tab":{
          padding:"0px",
         }
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
        overflow:"hidden",
        "& .MuiTypography-root": {
          "& .MuiFormControl-root": {
            width: "100%",
            "& .MuiFormGroup-root": {
              "& .recommended-container": {
                // display: "flex",
                // flexDirection: "column",
                background: selectedAnalysisKey === "Recommended" ? onRecChange : "#F3F6F7",
                border: selectedAnalysisKey === "Recommended" ? "2px solid #425E66" : "0px",
                padding: "2px 18px 2px 18px",
                borderRadius: "8px",
                marginBottom: "1rem",
                "& .classification-tab": {
                  // paddingLeft: "25px",
                  padding: "0 0 1rem",
                  // width: "100%",
                  "& .label-row": {
                    display: "none",
                  },
                },
              },
              "& .manual-container": {
                // display: "flex",
                // flexDirection: "column",
                background: selectedAnalysisKey === "Manual" ? onManualChange : "#F3F6F7",
                border: selectedAnalysisKey === "Manual" ? "2px solid #425E66" : 0,
                padding: "2px 18px 2px 18px",
                borderRadius: "8px",
                flexGrow: 0,
                "& .classification-tab": {
                  // paddingLeft: "25px",
                  padding: "0 0 1rem",
                  "& .label-row": {
                    display: "none",
                  },
                },
              },
            },
          },
        },
        "& .MuiTypography-body1 ": {
          fontWeight: "500",
          color: "#000000",
        },
      },
    },
  };

  return (
    <AlertDialogModal
      sx={Styles}
      dialogButtonName="test"
      dialogTitle={dualogTitleContent()}
      dialogContent={
        <SubmitModalSelector
          handleManualColor={handleManualColor}
          handleRecColor={handleRecColor}
          classificRule={props?.classificRule}
          ruleTyp={ruleTyp}
          ruledOutTab={props?.ruledOutTab}
        />
      }
      btnYesText={modelButton?.next}
      btnNoText={modelButton?.cancel}
      selectedClassific={selectedClassific}
      open={props?.reviewOpen}
      handleClickOpen={props?.handleReviewClickOpen}
      handleClose={props?.handleReviewClose}
      handleProceed={props?.handleClickOpen}
      showCloseicn={false}
    />
  );
};
export default HeaderReviewModal;
