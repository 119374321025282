import React, { useState, useContext, useEffect } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import PaginationView from "../pagination/PaginationView";
import { ClickAwayListener, Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TextField } from "@mui/material";
import LoadingSpinner from "../Notes/LoadingSpinner";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import "./TestTable.scss";
import dayjs from "dayjs";
import Filter from "../filter/Filter";
import MenuSelect from "./MenuSelect";
import SearchFilter from "../filter/SearchFilter";
import Stack from "@mui/material/Stack";
import Constants from "../../languages/language";
import { ColumnSort } from "../columnSort/ColumnSort";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { NoResultsFound } from "../noResultsFound/NoResultsFound";
import { ServerError } from "../serverError/ServerError";
import { DateFormat } from "../../utils/dateFormat";
import { createOrEditWorkupStorage } from "../../services/communicationService";
import {
  saveSampleIDsToApi,
  getUnlinkedSampleIds,
  deleteUnlinkedSampleId,
} from "../../Redux/Actions/test-sampleids.js";
import SampleId from "./sampleId/SampleId";
import DrawerData from "./sampleId/DrawerData";
import { getStatus } from "../../utils/global";
import { SpinnerContext } from "../../context/spinner-context.js";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const enterSample = Constants?.Enter_Sample;
const samplisStatus = Constants.sampleIDStatus;
const deleteSample = Constants.deleteSampleId;
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#34383c",
    maxWidth: 240,
    lineHeigth: 21,
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    PaddingRight: 16,
    borderRadius: 8,
    fontSize: 14,
    whiteSpace: "nowrap",
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))",
  },
}));

const LightTool = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#CF2F44",
    maxWidth: 240,
    lineHeigth: 21,
    fontWeight: 500,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    PaddingRight: 16,
    borderRadius: 8,
    fontSize: 14,
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))",
  },
}));
const TestTable = ({
  posts,
  loading,
  onSortChanged,
  sortParam,
  filters,
  updateFilters,
  filterValues,
  showLaboratory,
  searchText,
  onSearchTextChange,
  addTestResults,
  serverError,
  onAddToWorkup,
  selected,
  onSelectedChange,
  highlighted,
  onAddTestResults,
  onCancelAddTestResult,
  postsPerPage,
  totalPosts,
  onChange,
  lastIndex,
  startIndex,
  currentPosts,
  showSampleIdDetails,
  ignoredFilters,
  onClickedOutsideTable
}) => {
  const navigate = useNavigate();
  const { workupId } = useParams();
  const isWorkupEdit = createOrEditWorkupStorage.isEdit;
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [unlinkedSampleIDs, setunlinkedSampleIDs] = useState();
  const [isTagDeleteClicked, setisTagDeleteClicked] = useState(false);
  const [deleteSampleId, setdeleteSampleId] = useState();
  const [deleteSampleIdStatus, setdeleteSampleIdStatus] = useState(false);
  const [addSampleIdStatus, setaddSampleIdStatus] = useState(false);
  const [addSampleNumber, setaddSampleNumber] = useState();
  const [isLoading, setIsLoading] = useState();

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };
  const unlinkedSampleList = async () => {
    setIsLoading(true);
    setOpenUnlinkedSampleIds(true);
    setisTagDeleteClicked(false);
    const response = await getUnlinkedSampleIds();
    if (response.status !== 200) {
      getStatus(response, navigate);
    }
    setunlinkedSampleIDs([...new Set(response)]);
    setIsLoading(false);

    return (
      <DrawerData
        open={openUnlinkedSampleIds}
        onClose={() => setOpenUnlinkedSampleIds(false)}
        title={enterSample.unlinked_title}
        list_note={enterSample.sample_list_note}
        sample_id={enterSample.sample_id}
        done_btn={enterSample?.done_btn}
        boxClass={"sample-id-box"}
      />
    );
  };

  var arraynew;
  if (arraynew && arraynew == "undefined") {
    arraynew = ["1"];
  }
  const handleCheckboxChange = async (e, rowData) => {
    const checked = e.target.checked;
    onSelectedChange(rowData, checked);
  };

  const handleCancelAddTestResult = () => {
    onCancelAddTestResult(workupId);
  };
  const [textAreaMessage, setTextAreaMessage] = useState("");
  const handleInputChange = (event) => {
    const sampleIDinput = event.target.value
      .replace(/(\t)/gm, ", ")
      .replace(/(\n)/gm, ", ");
    const datasampleID = sampleIDinput.replace(
      /^\s*(\[\s*url\s*=\s*)?(https?:\/\/)?(www\.|\S+?\.)(\S+?\.)?\S+\s*$\s*/gm,
      ""
    );
    setTextAreaMessage(datasampleID);
  };

  const _testTableHeaders = Constants?.testTable?.testTableHeader;
  const enterSampleConst = Constants?.Enter_Sample;
  var modalOpen = "true";

  const getRowClass = (isSelected, isHighlighted) => {
    const classes = [];
    if (isSelected) {
       classes.push("selected");
    }

    if (isHighlighted) {
      classes.push("highlighted");
    }

    if (classes.length == 0) {
      return null;
    }

    return classes.join(" ");
  };

  const handleClickAway = () => {
    onClickedOutsideTable();
    setSelectedRowIndex(-1);
    
  };

  const getTable = () => {
    return (
      <div className="test-table-root">
        <Box>
          <ClickAwayListener  onClickAway={handleClickAway}>
            <TableContainer className="table-container">
              <Table className="table" arai-label="simple">
                <TableHead>
                  <TableRow>
                    {addTestResults && (
                      <TableCell className="table-header-cell"></TableCell>
                    )}
                    <TableCell className="table-header-cell">
                      {_testTableHeaders.sample_id}
                    </TableCell>
                    <TableCell className="table-header-cell">
                      {_testTableHeaders.lot}
                    </TableCell>
                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">
                          {_testTableHeaders.result_date}
                        </span>
                        <span className="table-sorting-icons">
                          <ColumnSort
                            fieldName="result_dt"
                            sortDirection={sortParam["result_dt"]}
                            onSortChanged={onSortChanged}
                          />
                        </span>
                      </span>
                    </TableCell>

                    {showLaboratory && (
                      <TableCell className="table-header-cell">
                        <span className="table-col-heading-wrapeer">
                          <span className="table-col-heading">
                            {_testTableHeaders.lab}
                          </span>
                          <span className="table-sorting-icons">
                            <ColumnSort
                              fieldName="laboratory"
                              sortDirection={sortParam["laboratory"]}
                              onSortChanged={onSortChanged}
                            />
                          </span>
                        </span>
                      </TableCell>
                    )}
                    <TableCell className="table-header-cell">
                      <span className="table-col-heading-wrapeer">
                        <span className="table-col-heading">
                          {_testTableHeaders.panel_type}
                        </span>
                        <span className="table-sorting-icons">
                          <ColumnSort
                            fieldName="panel_type"
                            sortDirection={sortParam["panel_type"]}
                            onSortChanged={onSortChanged}
                          />
                        </span>
                      </span>
                    </TableCell>
                    {!addTestResults && (
                      <TableCell className="table-header-cell">
                        <span className="table-col-heading-wrapeer">
                          <span className="table-col-heading">
                            {_testTableHeaders.workup}
                          </span>
                          <span className="table-sorting-icons">
                            <ColumnSort
                              fieldName="workup_name"
                              sortDirection={sortParam["workup_name"]}
                              onSortChanged={onSortChanged}
                            />
                          </span>
                        </span>
                      </TableCell>
                    )}

                    {!addTestResults && (
                      <TableCell className="table-header-cell">
                        <span className="table-col-heading-wrapeer">
                          <span className="table-col-heading">
                            {_testTableHeaders.status}
                          </span>
                          <span className="table-sorting-icons">
                            <ColumnSort
                              fieldName="status"
                              sortDirection={sortParam["status"]}
                              onSortChanged={onSortChanged}
                            />
                          </span>
                        </span>
                      </TableCell>
                    )}

                    <TableCell className="table-header-cell"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {posts &&
                    posts.map((user, index) => {
                      return (
                        <TableRow
                          key={index}
                          onClick={() => handleRowClick(index)}
                          className={getRowClass(
                            index === selectedRowIndex,
                            highlighted.find((id) => id == user.id) != null
                          )}
                        >
                          {addTestResults && (
                            <TableCell>
                              <FormControlLabel
                                label={""}
                                control={
                                  <Checkbox
                                    multiple
                                    checked={
                                      selected.find(
                                        (test_result_reference_key) => {
                                          return (
                                            test_result_reference_key ==
                                            user?.test_result_reference_key
                                          );
                                        }
                                      ) != null
                                    }
                                    onChange={(e) =>
                                      handleCheckboxChange(e, user)
                                    }
                                    sx={{
                                      [`&, &.Mui-checked`]: {
                                        color: "#CF2F44",
                                      },
                                    }}
                                  />
                                }
                                className="test-item"
                                key={index}
                                style={{ maxWidth: "0rem" }}
                              />
                            </TableCell>
                          )}
                          <TableCell>
                            <span>
                              {user?.sample_id?.length >= 25 ? (
                                <LightTooltip
                                  className="view-only-hover-popUp"
                                  title={user.sample_id}
                                  arrow
                                  placement="top-start"
                                >
                                  <div className="sample-id-workup-container">
                                    {user.sample_id &&
                                      user.sample_id.substring(0, 20) + "..."}
                                  </div>
                                </LightTooltip>
                              ) : (
                                <div className="sample-id-workup-container">
                                  {user.sample_id}
                                </div>
                              )}
                            </span>
                          </TableCell>
                          <TableCell>
                            {user.lot_number === null || undefined ? (
                              "-"
                            ) : (
                              <span>{user.lot_number}</span>
                            )}
                          </TableCell>
                          <TableCell>
                            {user.result_dt === null ||
                            user.result_dt === undefined ? (
                              "-"
                            ) : (
                              <span>
                                {dayjs(user.result_dt).format(
                                  DateFormat() + " - HH:mm"
                                )}
                              </span>
                            )}
                          </TableCell>
                          {showLaboratory && (
                            <TableCell>
                              {user.laboratory === null ||
                              user.laboratory === undefined ? (
                                "-"
                              ) : (
                                <span>{user?.laboratory}</span>
                              )}
                            </TableCell>
                          )}
                          <TableCell>
                            {user.panel_type === null ||
                            user.panel_type === undefined ? (
                              "-"
                            ) : (
                              <span>{user?.panel_type}</span>
                            )}
                          </TableCell>
                          {!addTestResults && (
                            <TableCell>
                              {user.workup_name === null ||
                              user.workup_name === undefined ? (
                                <Button
                                  id="add-button"
                                  variant="outlined"
                                  onClick={() => onAddToWorkup(user)}
                                >
                                  +ADD
                                </Button>
                              ) : (
                                <Link
                                  to={`/workup-interpretation/${user?.workup_id}`}
                                  state={modalOpen}
                                  target="_blank"
                                >
                                  <span className="user-workupname-container">
                                    {user?.workup_name?.length >= 20 ? (
                                      <LightTool
                                        className="view-only-hover-popUp"
                                        title={user?.workup_name}
                                        arrow
                                        placement="top-start"
                                      >
                                        <div className="sample-id-workup-container">
                                          {user.workup_name.substring(0, 16) +
                                            "..."}
                                        </div>
                                      </LightTool>
                                    ) : (
                                      <div className="sample-id-workup-container">
                                        {user.workup_name}
                                      </div>
                                    )}
                                  </span>
                                </Link>
                              )}
                            </TableCell>
                          )}

                          {!addTestResults && (
                            <TableCell>
                              <span className="status">
                                {user?.status?.toLowerCase() === "new" ? (
                                  <span className="new">{user.status}</span>
                                ) : null ||
                                  user?.status?.toLowerCase() ===
                                    "in progress" ? (
                                  <span className="in-progress">
                                    {user.status}
                                  </span>
                                ) : null ||
                                  user?.status?.toLowerCase() ===
                                    "in review" ? (
                                  <span className="in-review">
                                    {user.status}
                                  </span>
                                ) : null ||
                                  user?.status?.toLowerCase() ===
                                    "completed" ? (
                                  <span className="completed">
                                    {user.status}
                                  </span>
                                ) : null}
                              </span>
                            </TableCell>
                          )}
                          <TableCell>
                            <MenuSelect
                              panelId={posts[selectedRowIndex]?.id}
                              onAddToWorkup={() => onAddToWorkup(user)}
                              date={posts[selectedRowIndex]?.result_dt}
                              testResult={user}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </ClickAwayListener>
        </Box>
        <PaginationView
          addTestResults={addTestResults}
          postsPerPage={postsPerPage}
          totalPosts={totalPosts}
          onChange={onChange}
          lastIndex={lastIndex}
          startIndex={startIndex}
          currentPosts={currentPosts}
          unlinkedSampleList={unlinkedSampleList}
          showSampleIdDetails={showSampleIdDetails}
        />
        {addTestResults && (
          <div className="button-container">
            <Stack direction="row">
              <div
                className="cancel"
                variant="outlined"
                onClick={handleCancelAddTestResult}
              >
                {workupConsts.cancel}
              </div>
            </Stack>
            <Stack direction="row">
              <div
                className="submit"
                onClick={onAddTestResults}
                variant="contained"
                type="button"
              >
                {createConsts.addTestResultsConsts}
              </div>
            </Stack>
          </div>
        )}
      </div>
    );
  };
  const [istrue, setIstrue] = useState(false);
  useEffect(() => {
    if (posts.length === 0) {
      setTimeout(setIstrue(true), 2000);
    }
  }, []);
  const getComponent = () => {
    if (serverError) {
      return <ServerError />;
    }
    if (posts?.length === 0 && istrue) {
      return <NoResultsFound></NoResultsFound>;
    }
    return getTable();
  };
  const [open, setOpen] = useState(false);
  const [openUnlinkedSampleIds, setOpenUnlinkedSampleIds] = useState(false);
  const handleChange = () => {
    setOpen(true);
    setTextAreaMessage("");
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveSampleIDs = async () => {
    let sampleIds = textAreaMessage.split(/[\s,]+/).filter((value) => {
      return value !== null && value !== "";
    });

    saveSampleIDsToApi(sampleIds); //array
    const response = await saveSampleIDsToApi(sampleIds);
    setaddSampleNumber(response);
    setaddSampleIdStatus(true);
    setTextAreaMessage("");
    if (response?.status !== 200) {
      var err = response;
      getStatus(err, navigate);
    }
  };
  const workupConsts = Constants?.workupDetails?.workupform;
  const createConsts = Constants?.workupDetails?.createData;

  const getWorkupText = () => {
    if (addTestResults) {
      return isWorkupEdit ? createConsts.editWorkup : createConsts.createWorkup;
    }
    return workupConsts.tests;
  };
  const handleRemoveTag = async (sampleId) => {
    setisTagDeleteClicked(true);
    setdeleteSampleId(sampleId);
  };
  const handleDeleteWarningYes = async (value) => {
    setisTagDeleteClicked(false);
    const res = await deleteUnlinkedSampleId(deleteSampleId);
    if (res?.status !== 200) {
      var err = res;
      getStatus(err, navigate);
    }
    setdeleteSampleIdStatus(true);
  };
  const handleDeleteWarningClose = async (value) => {
    setisTagDeleteClicked(false);
    setdeleteSampleId(null);
    setdeleteSampleIdStatus(false);
    const response = await getUnlinkedSampleIds();
    setunlinkedSampleIDs(response);
    if (response?.status !== 200) {
      var err = response;
      getStatus(err, navigate);
    }
  };
  const handleDeleteMessageClose = () => {
    setdeleteSampleIdStatus(false);
  };
  const handleAddMessageClose = () => {
    setaddSampleIdStatus(false);
  };
  return (
    <>
      <div>
        <h2 className="page-title">{getWorkupText()}</h2>
      </div>

      <div className="create-workup-page">
        {addTestResults && (
          <b className="add-test-tilte">{createConsts.addTestResultsConsts}</b>
        )}
        <div className="filter-root">
          <div className="left-sec">
            <SearchFilter
              searchText={searchText}
              onSearchTextChange={onSearchTextChange}
            />
          </div>
          <DrawerData
            open={open}
            boxClass={"sample-id-box"}
            onClose={handleClose}
            addSampleIdStatus={addSampleIdStatus}
            samplIdHeading={samplisStatus?.samplIdHeading}
            saved={addSampleNumber?.saved}
            failedLength={addSampleNumber?.failed.length}
            sampleIdFailedMessage={samplisStatus?.sampleIdFailedMessage}
            sampleIDSucessMessage={samplisStatus?.sampleIDSucessMessage}
            sample_id={enterSample.sample_id}
            done_btn={enterSample?.done_btn}
            handleAddMessageClose={handleAddMessageClose}
            title={enterSample?.Add_Sample}
            list_note={enterSample?.Sample_Note}
            placeholder_Sample={enterSampleConst?.placeholder_Sample}
            handleInputChange={handleInputChange}
            textAreaMessage={textAreaMessage}
            handleSaveSampleIDs={handleSaveSampleIDs}
            Add_Sample={enterSampleConst?.Add_Sample}
            Separate_Sample={enterSampleConst?.Separate_Sample}
            textAreaBox={true}
          />
          <DrawerData
            boxClass={"drawer-sample-id"}
            deleteSampleIdStatus={deleteSampleIdStatus}
            open={openUnlinkedSampleIds}
            onClose={() => setOpenUnlinkedSampleIds(false)}
            samplisStatus={samplisStatus}
            handleDeleteMessageClose={handleDeleteMessageClose}
            isTagDeleteClicked={isTagDeleteClicked}
            deleteSample={deleteSample}
            handleDeleteWarningClose={handleDeleteWarningClose}
            handleDeleteWarningYes={handleDeleteWarningYes}
            enterSampleConst={enterSampleConst}
            onClick={() => setOpenUnlinkedSampleIds(false)}
            unlinkedSampleIDs={unlinkedSampleIDs}
            handleRemoveTag={handleRemoveTag}
            isLoading={isLoading}
            deleteSampleId={deleteSampleId}
            done_btn={enterSampleConst?.done_btn}
            title={enterSampleConst?.unlinked_title}
            list_note={enterSampleConst?.sample_list_note}
            sample_id={enterSampleConst?.sample_id}
          />
          <div className="right-sec">
            <Filter
              updateFilters={updateFilters}
              filters={filters}
              filterValues={filterValues}
              ignoredFilters={ignoredFilters}
            />
            <SampleId
              handleChange={handleChange}
              showSampleIdDetails={showSampleIdDetails}
            />
          </div>
        </div>
        {getComponent()}
      </div>
    </>
  );
};

export default TestTable;
