import React from 'react'
import { Pagination } from "@mui/material";
import { Box } from "@mui/system";
const WorkupPagination = ({
    onChange,
    postsPerPage,
    totalPosts,
    startIndex,
    lastIndex,
  }) => {

    var text="";

if(totalPosts === 0){
  text="Showing 0 of 0 records"
}
else{
text = `Showing ${startIndex + 1} to ${
  lastIndex > totalPosts ? totalPosts : lastIndex
} results of ${totalPosts} records`;
}
  return (
    <div>
        <Box className="box-wrapper">
      <span>{text}</span>

      <Pagination
        count={Math.ceil(totalPosts / postsPerPage)}
        onChange={onChange}
        siblingCount={0}
        boundaryCount={1}
      />
    </Box>
    </div>
  )
}

export default WorkupPagination