import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";

export const getUserInfo = async () => {
  try {
    const response = await Api.get(`${REACT_APP_API_ENDPOINT}me`, {});
    if (response) {
      return response?.data;
    }
  } catch (err) {
    return err;  
  }
};