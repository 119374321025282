import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import language from "../../languages/language"
const menulist =language.munudropdown;
function PanelNotesMenu(props) {
  const [anchorel, setanchorel] = React.useState(null);
  const open = Boolean(anchorel);
  const handleClick = (event) => {
    setanchorel(event.currentTarget);
  };
  const handleClose = () => {
    setanchorel(null);
    props.handleOnClose();
  };
  const handleMenuOptionClicked = (value) => {
    props.handleOnClick(value);
    setanchorel(null);
  }
  return (
    <div className="notes-menu-root">
      <IconButton onClick={handleClick}>
        <MoreVertIcon className="more-icon" 
         id="long-button"
         aria-controls={open ? "long-menu" : undefined}
         aria-expanded={open ? "true" : undefined}
         aria-haspopup="true"
        />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorel}
        className="edit-delet-workup"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={5}
        PaperProps={{
          style:{
            maxHeight:25*4,
            width:'40ch',
            right:'10',            
        }
        }}
      >
        <MenuItem style={{ textDecoration: "none", color: "black"  }}
        className="workup-menu-item" onClick={() => {handleMenuOptionClicked('Edit')}}>{menulist.edit}</MenuItem>
        <MenuItem style={{ textDecoration: "none", color: "black" }}
        className="workup-menu-item" onClick={() =>{handleMenuOptionClicked('Delete')}}>{menulist.delete}</MenuItem>
      </Menu>
    </div>
  );
}
export default PanelNotesMenu;