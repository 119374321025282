import { Amplify, Auth } from "aws-amplify";
import {
  COGNITO_DOMAIN,
  COGNITO_REGION,
  COGNITO_SIGN_IN_URL,
  COGNITO_SIGN_OUT_URL,
  COGNITO_USERPOOL_ID,
  COGNITO_USERPOOL_WEB_CLIENT_ID,
} from "./constants";



Amplify.configure({
  Auth: {
    region: COGNITO_REGION,
    userPoolId: COGNITO_USERPOOL_ID,
    userPoolWebClientId: COGNITO_USERPOOL_WEB_CLIENT_ID,

    oauth: {
      domain: COGNITO_DOMAIN,
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn:COGNITO_SIGN_IN_URL,
      redirectSignOut:COGNITO_SIGN_OUT_URL,
      responseType: "code",
    },
  },
});

export const currentConfig = Auth.configure();
