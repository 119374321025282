import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import loadingSpinner from "../../../assets/images/LoadingSpinner.png";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Box,
  Collapse,
  ClickAwayListener,
} from "@mui/material";
import "./index.scss";
import { Button, FormControlLabel, Checkbox } from "@mui/material";
import dayjs from "dayjs";
import WorkupMenu from "./workupMenu/Workupmenu";
import Constants from "../../../languages/language";
import { ColumnSort } from "../../columnSort/ColumnSort";
import SearchWorkups from "../searchWorkups";
import WorkupFilters from "./workupFilters/WorkupFilters";
import { ServerError } from "../../serverError/ServerError";
import { NoResultsFound } from "../../noResultsFound/NoResultsFound";
import WorkupPagination from "./workupPagination/WorkupPagination.js";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { DateFormat } from "../../../utils/dateFormat";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#CF2F44",
    maxWidth: 240,
    lineHeigth: 21,
    fontWeight: 500,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    PaddingRight: 16,
    borderRadius: 8,
    fontSize: 14,
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))",
  },
}));
const LightTool = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#34383c",
    maxWidth: 240,
    lineHeigth: 21,
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    PaddingRight: 16,
    borderRadius: 8,
    fontSize: 14,
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))",
  },
}));
const WorkupTable = ({
  posts,
  onSortChanged,
  sortParam,
  filterValues,
  filters,
  updateFilters,
  serverError,
  searchText,
  onSearchTextChange,
  onEnter,
  onClearSearch,
  workupDetailsRequestById,
  postsPerPage,
  totalPosts,
  onChange,
  lastIndex,
  startIndex,
  currentPosts,
  isAddWorkup,
  onWorkupSelected,
  selectedWorkups,
  highlightedWorkupIds,
  expandedRows,
  onRowExpanded,
  onNewWorkup,
  allowCreateWorkup,
  onWorkupEdit,
  onClickedOutsideTable,
}) => {
  const _workupTableHeader = Constants.workupHomePage.workupTable;
  const createWorkupBtn = Constants.AntigenHeader;
  const getTable = () => {
    return (
      <>
        <div className="workup-table-root">
          <Box>
            <ClickAwayListener onClickAway={handleClickAway}>
              <TableContainer className="table-container">
                <Table className="table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell className="table-header-cell"></TableCell>
                      <TableCell className="table-header-cell">
                        <span className="table-col-heading-wrapeer">
                          <span className="table-col-heading ">
                            {_workupTableHeader.workup_name}
                          </span>
                          <span className="table-sorting-icons">
                            <ColumnSort
                              fieldName="workup_name"
                              sortDirection={sortParam["workup_name"]}
                              onSortChanged={onSortChanged}
                            />
                          </span>
                        </span>
                      </TableCell>
                      <TableCell className="table-header-cell">
                        <span className="table-col-heading-wrapeer">
                          <span className="table-col-heading">
                            {_workupTableHeader.last_updated}
                          </span>
                          <span className="table-sorting-icons">
                            <ColumnSort
                              fieldName="result_dt"
                              sortDirection={sortParam["result_dt"]}
                              onSortChanged={onSortChanged}
                            />
                          </span>
                        </span>
                      </TableCell>
                      <TableCell className="table-header-cell">
                        <span className="table-col-heading-wrapeer">
                          <span className="table-col-heading">
                            {_workupTableHeader.last_updated_by}
                          </span>
                          <span className="table-sorting-icons">
                            <ColumnSort
                              fieldName="last_updated_by"
                              sortDirection={sortParam["last_updated_by"]}
                              onSortChanged={onSortChanged}
                            />
                          </span>
                        </span>
                      </TableCell>
                      <TableCell className="table-header-cell">
                        <span className="table-col-heading-wrapeer">
                          <span className="table-col-heading">
                            {_workupTableHeader.status}
                          </span>
                          <span className="table-sorting-icons">
                            <ColumnSort
                              fieldName="status"
                              sortDirection={sortParam["status"]}
                              onSortChanged={onSortChanged}
                            />
                          </span>
                        </span>
                      </TableCell>
                      <TableCell className="table-header-cell"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table-body">
                    {posts.map((workup, index) => (
                      <CollapsibleTable
                        row={workup}
                        key={index}
                        workupDetailsRequest={
                          workupDetailsRequestById[workup.id]
                        }
                        rowExpand={_workupTableHeader}
                        isAddWorkup={isAddWorkup}
                        onWorkupSelected={onWorkupSelected}
                        isSelected={selectedWorkups.includes(workup.id)}
                        isExpanded={expandedRows.includes(workup.id)}
                        isHighlighted={
                          highlightedWorkupIds.find((id) => id === workup.id) !=
                          null
                        }
                        onExpanderToggled={onRowExpanded}
                        onWorkupEdit={onWorkupEdit}
                        selectedRowIndex={selectedRowIndex}
                        index={index}
                        handleRowClick={handleRowClick}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </ClickAwayListener>
          </Box>
          <WorkupPagination
            postsPerPage={postsPerPage}
            totalPosts={totalPosts}
            onChange={onChange}
            lastIndex={lastIndex}
            startIndex={startIndex}
            currentPosts={currentPosts}
          />
        </div>
      </>
    );
  };
  const [istrue, setIstrue] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };
  useEffect(() => {
    if (posts.length === 0) {
      setTimeout(setIstrue(true), 2000);
    }
  }, []);
  const getComponent = () => {
    if (serverError) {
      return <ServerError />;
    }
    if (posts.length === 0 && istrue) {
      return <NoResultsFound></NoResultsFound>;
    }
    return getTable();
  };

  const handleNewWorkup = () => {
    onNewWorkup();
  };

  const handleClickAway = () => {
    onClickedOutsideTable();
    setSelectedRowIndex(-1);
  };

  return (
    <Box>
      <div className="workup-root">
        <div className="left-sec">
          <SearchWorkups
            onSearchTextChange={onSearchTextChange}
            searchText={searchText}
            onEnter={onEnter}
            onClearSearch={onClearSearch}
          />
        </div>
        <div className="right-sec">
          <WorkupFilters
            className="filter-component"
            updateFilters={updateFilters}
            filters={filters}
            filterValues={filterValues}
          />
          <Button
            className={allowCreateWorkup ? "" : "btn-disabled"}
            variant="outlined"
            onClick={handleNewWorkup}
            disabled={!allowCreateWorkup}
          >
            {createWorkupBtn.createWorkup}
          </Button>
        </div>
      </div>
      {getComponent()}
    </Box>
  );
};

export default WorkupTable;

function CollapsibleTable({
  row,
  workupDetailsRequest,
  rowExpand,
  isAddWorkup,
  onWorkupSelected,
  isSelected,
  isHighlighted,
  isExpanded,
  onExpanderToggled,
  onWorkupEdit,
  selectedRowIndex,
  index,
  handleRowClick,
}) {
  const { workupDetails = {}, isLoading } = workupDetailsRequest || {};

  const { id } = row;

  const handleToggleClick = () => {
    onExpanderToggled(row, !isExpanded);
  };

  const handleCheckboxSelection = (e) => {
    onWorkupSelected(row, !isSelected);
  };

  var modalOpen = "true";

  const getExpander = () => {
    return (
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={handleToggleClick}
        className="expand-icon-wrapper"
      >
        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    );
  };

  const getCheckbox = () => {
    return (
      <FormControlLabel
        label={""}
        control={
          <Checkbox
            multiple
            checked={isSelected}
            onChange={handleCheckboxSelection}
            sx={{
              [`&, &.Mui-checked`]: {
                color: "#CF2F44",
              },
            }}
          />
        }
      />
    );
  };

  const getWorkupDetails = () => {
    return (
      <Box>
        <Table size="small" aria-label="collapsible table">
          <TableRow>
            <TableCell></TableCell>
            <TableCell
              rowSpan={2}
              className="expand-row-label"
              sx={{ minWidth: 0 }}
            >
              {rowExpand.workup_details}
            </TableCell>
            <TableCell className="expand-row-headers">
              {rowExpand.submission_date ? rowExpand.submission_date : "--"}
            </TableCell>
            <TableCell className="expand-row-headers"></TableCell>
            <TableCell className="expand-row-headers"></TableCell>
            <TableCell className="expand-row-headers"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell className="expand-row-label">
              {!isLoading &&
              (row.submitted_date === null ||
                row.submitted_date === undefined) ? (
                "--"
              ) : (
                <span>
                  {dayjs(row.submitted_date).format(DateFormat() + " - HH:mm")}
                </span>
              )}
            </TableCell>
            <TableCell className="expand-row-label"></TableCell>
            <TableCell className="expand-row-label"></TableCell>
            <TableCell className="expand-row-label"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell rowSpan={20} className="expand-row-label">
              {rowExpand.panel_details}
            </TableCell>
            <TableCell className="expand-row-headers">
              {rowExpand.panel_type}
            </TableCell>
            <TableCell className="expand-row-headers">
              {rowExpand.sample_id}
            </TableCell>
            <TableCell className="expand-row-headers">
              {rowExpand.sample_date}
            </TableCell>
            <TableCell className="expand-row-headers">
              {rowExpand.lab}
            </TableCell>
          </TableRow>
          {workupDetails.panels && workupDetails.panels.length > 0 ? (
            workupDetails.panels.map((panel, index) => (
              <PanelTableRow key={index} panel={panel}></PanelTableRow>
            ))
          ) : (
            <EmptyPanelTableRow></EmptyPanelTableRow>
          )}
        </Table>
      </Box>
    );
  };
  const getRowClass = (isSelected, isHighlighted) => {
    const classes = [];
    if (isSelected) {
      classes.push("selected");
    }

    if (isHighlighted) {
      classes.push("highlighted");
    }

    if (classes.length == 0) {
      return null;
    }

    return classes.join(" ");
  };

  return (
    <React.Fragment>
      <TableRow
        key={index}
        onClick={() => handleRowClick(index)}
        className={getRowClass(index === selectedRowIndex, isHighlighted)}
      >
        <TableCell>{isAddWorkup ? getCheckbox() : getExpander()}</TableCell>
        <TableCell>
          <Link
            to={`/workup-interpretation/${row?.id}`}
            state={modalOpen}
            target="_blank"
          >
            {row.workup_name.length <= 20 ? (
              <div>{row.workup_name}</div>
            ) : (
              <LightTooltip
                className="view-only-hover-popUp"
                title={row.workup_name}
                arrow
                placement="top-start"
              >
                <div>{row.workup_name.substring(0, 16) + "..."}</div>
              </LightTooltip>
            )}
          </Link>
        </TableCell>
        <TableCell>
          {row.result_dt === null || row.result_dt === undefined ? (
            "--"
          ) : (
            <span>
              {dayjs(row.result_dt).format(DateFormat() + " - HH:mm")}
            </span>
          )}
        </TableCell>
        <TableCell>
          {row.last_updated_by === null || row.last_updated_by === undefined ? (
            "--"
          ) : (
            <span>{row.last_updated_by}</span>
          )}
        </TableCell>
        <TableCell>
          <span className="status">
            {row?.status?.toLowerCase() === "new" ? (
              <span className="new">{row?.status}</span>
            ) : null || row?.status?.toLowerCase() === "in progress" ? (
              <span className="in-progress">{row?.status}</span>
            ) : null || row?.status?.toLowerCase() === "in review" ? (
              <span className="in-review">{row?.status}</span>
            ) : null || row?.status?.toLowerCase() === "completed" ? (
              <span className="completed">{row?.status}</span>
            ) : null}
          </span>
        </TableCell>
        {isAddWorkup ? (
          <TableCell>{isAddWorkup ? getExpander() : undefined}</TableCell>
        ) : (
          <TableCell>
            <WorkupMenu
              workupId={id}
              status={row.status}
              onEditClicked={() => onWorkupEdit(id)}
            />
          </TableCell>
        )}
      </TableRow>
      <TableRow className="expand-row-container">
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={loadingSpinner}
                  alt="loading spinner"
                  className="spinner"
                />
              </div>
            ) : (
              getWorkupDetails()
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const PanelTableRow = ({ panel }) => {
  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell className="expand-row-label">{panel.panel_type}</TableCell>
      <TableCell className="expand-row-label">
        {panel?.sample?.unhashed?.length <= 25 ? (
          <span>{panel?.sample?.unhashed}</span>
        ) : (
          <LightTool
            className="view-only-hover-popUp"
            title={panel?.sample?.unhashed}
            arrow
            placement="top-start"
          >
            {panel?.sample?.unhashed &&
              panel?.sample?.unhashed?.substring(0, 16) + "..."}
          </LightTool>
        )}
      </TableCell>
      <TableCell className="expand-row-label">
        {dayjs(panel?.sample?.date_time).format(DateFormat())}
      </TableCell>
      <TableCell className="expand-row-label">
        {panel.laboratory ? panel.laboratory : "--"}
      </TableCell>
    </TableRow>
  );
};

const EmptyPanelTableRow = () => {
  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell className="expand-row-label">--</TableCell>
      <TableCell className="expand-row-label">--</TableCell>
      <TableCell className="expand-row-label">--</TableCell>
      <TableCell className="expand-row-label">--</TableCell>
    </TableRow>
  );
};
