import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";

export const getPanelNotes = async (panelId) => {
    try {
        const response = await Api.get(`${REACT_APP_API_ENDPOINT}{orgScope}/panels/${panelId}/notes`)
        if (response) {
            return response?.data?.notes.reverse();     
        }
    } catch (err) {
        return err
    }
};
export const getPanelNotesCount = async (panelId) => {
    try {
        const response = await Api.get(`${REACT_APP_API_ENDPOINT}{orgScope}/panels/${panelId}/notes`)
        if (response) {
            return response?.data?.notes?.length;     
        }
    } catch (err) {
        return err;
    }
};
export const savePanelNotes = async (panelId, entText) => {
    try {
        const response = await Api.post(`${REACT_APP_API_ENDPOINT}{orgScope}/panels/${panelId}/notes`,entText);
        if (response) {
            return response;
        }
    } catch (err) {
        return err
    }
};
export const updateEditedPanelNotesContent = async (panelId,id, content) => {
    try {
        const response = await Api.post(`${REACT_APP_API_ENDPOINT}{orgScope}/panels/${panelId}/notes/${id}`, content);
        if (response) {
            return response?.data;
        }
    } catch (err) {
        return err;
    }
};
export const deletePanelNotes = async (panelId,id) => {
    try {
        const response = await Api.delete(`${REACT_APP_API_ENDPOINT}{orgScope}/panels/${panelId}/notes/${id}`);
        if (response) {
            return response;
        }
    }
    catch(err){
        return err;
    }
};
