import { React, useState, useContext, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import loadingSpinner from "../../assets/images/LoadingSpinner.png";
import { SpinnerContext } from "../../context/spinner-context";
import "./index.scss";

export const SpinnerComponent = ({ className = "spinner" }) => {
  return (
    <img src={loadingSpinner} alt="loading spinner" className={className} />
  );
};

export const Spinner = () => {
  const { showSpinner } = useContext(SpinnerContext);

  if (!showSpinner) {
    return undefined;
  }

  return (
    <div className="spinner-root">
      <div className="spinner-container"></div>
      <SpinnerComponent />
    </div>
  );
};

export const SpinnerProvider = ({ children }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const changeSpinnerStatusRef = useRef((show) => setShowSpinner(show));
  const changeSpinnerStatus = changeSpinnerStatusRef.current;

  return (
    <SpinnerContext.Provider value={{ showSpinner, changeSpinnerStatus }}>
      <>
        <Spinner></Spinner>
        {children}
      </>
    </SpinnerContext.Provider>
  );
};
