import React from 'react';
import { CrossMask} from "../../utils/icons.js";
import crossMark from '../../assets/images/crossMark.png'
import "./index.scss";

export const ErrorPopup = ({ children, onClose }) => {
  return (
    <>
      <div className="error-popup">
      <img src={crossMark} alt="crossMark" className="crossMark" />
        {children}
        <div style={{ cursor: "pointer" }} onClick={onClose}>
          <CrossMask width={15} height={15} />
        </div>
      </div>
    </>
  );
};
