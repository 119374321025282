import React, { useState, useEffect, useRef, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { createWorkups } from "../../Redux/Actions/create-workups";
import {
  addToWorkupStorage,
  createOrEditWorkupStorage,
} from "../../services/communicationService";
import { useLocation, useNavigate } from "react-router-dom";
import { CrossMask } from "../../utils/icons";
import { useParams } from "react-router-dom";
import { SpinnerContext } from "../../context/spinner-context";
import RemoveTestModal from "./RemoveTestModal";
import { DateFormat } from "../../utils/dateFormat";
import Constants from "../../languages/language";
import { getStatus } from "../../utils/global";
import WarningIcon from "@mui/icons-material/Warning";
import {
  getWorkupsDetails,
  updtSingletWorkups,
  geSingletWorkups,
  unlinkTestData,
} from "../../Redux/Actions/workups";
import dayjs from "dayjs";
import "./index.scss";
import { ConsoleLogger } from "@aws-amplify/core";
import { getTestDataFromPanel } from "../../utils/workup";
import PositionedSnackbar from "../../components/SnackbarPopup/SnackbarPopup.js";

const workupConsts = Constants.workupDetails.workupform;
const createConsts = Constants.workupDetails.createData;
const editConsts = Constants.workupDetails.editData;
const editWorkupDeleteMessage =
  Constants.workupDetails.createData.editWorkupDelete;
const createWorkup = Constants.workupDetails.createData.createWorkup;
const editWorkup = Constants.workupDetails.createData.editWorkup;
const sidebar = Constants.SideBar_Navigation;


const LightTool = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#34383c",
    maxWidth: 240,
    lineHeigth: 21,
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    PaddingRight: 16,
    borderRadius: 8,
    fontSize: 14,
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))",
  },
}));

const SingleWorkup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { isAddToWorkupFlow, isCreateOrEditWorkupFlow } = location.state || {};
  const { workupId } = useParams();
  let isEdit = isCreateOrEditWorkupFlow && workupId != null;

  const [workupName, setWorkupName] = useState(
    isCreateOrEditWorkupFlow ? createOrEditWorkupStorage.workupName : ""
  );

  const [hasError, setHasError] = useState(false);

  const getTestDataDetails = () => {
    // isCreateOrEditWorkupFlow gets more priority provided some selected test details are there
    if (isCreateOrEditWorkupFlow) {
      return createOrEditWorkupStorage.selectedTestResults;
    }

    if (isAddToWorkupFlow) {
      return [addToWorkupStorage.selectedTestResult];
    }

    return [];
  };

  // The test details data might come from test result or workup details.
  const [allTestDataDetails, setAllTestDataDetails] = useState(
    getTestDataDetails()
  );

  const testDataInRemoval = useRef();
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] =
    useState(false);

  useEffect(() => {
    if (isEdit && !createOrEditWorkupStorage.isWorkupDataLoaded) {
      var action = async () => {
        changeSpinnerStatus(true);
        const res = await getWorkupsDetails(workupId);

        if (res?.status !== 200) {
          var err = res;
          getStatus(err, navigate);
        }
        changeSpinnerStatus(false);
        createOrEditWorkupStorage.workupOriginalData = res;
        setWorkupName(res.workup.name);

        // transform the test data details
        const newTestDataDetails = res.panels.map((p) =>
          getTestDataFromPanel(p)
        );

        setAllTestDataDetails(newTestDataDetails);
        changeSpinnerStatus(false);
      };

      action();
    }
  }, []);

  const [workupNameLimit, setWorkupNameLimit] = useState()

  const handleInputChange = (e) => {
    if(e.target.value.length > 60){
      setWorkupNameLimit(true)
    }
    else{
    const value = e.target.value;
    setWorkupName(e.target.value);
    setHasError(false);
    }
  };
  const handleDeleteTestItem = () => {
    window.location.reload();
  };

  const saveAddToWorkupFlowData = async () => {
    const apiObj = {
      name: workupName,
      reference_key_map: allTestDataDetails.map((tr) => ({
        antigram_reference_key: tr.antigram_reference_key,
        test_result_reference_key: tr.test_result_reference_key,
      })),
    };
    return createWorkups(apiObj);
  };

  const handleCreateWorkupCall = async (e) => {
    e.preventDefault();
    let apiObj = {};
    if (workupName == null || workupName == "") {
      setHasError(true);
      return;
    }
    if (isAddToWorkupFlow) {
      changeSpinnerStatus(true);
      await saveAddToWorkupFlowData();
      setTimeout(() => {
        addToWorkupStorage.isAddToWorkupFlowSuccess = true;
        addToWorkupStorage.isNewWorkup = true;
        navigate(`/`, {
          state: {
            isAddToWorkupFlow: true,
          },
        });
        changeSpinnerStatus(false);
      }, 1000);
    } else {
      changeSpinnerStatus(true);
      apiObj.name = workupName;
      if (
        allTestDataDetails &&
        (allTestDataDetails[0]?.antigram_reference_key ||
          allTestDataDetails[0]?.test_result_reference_key)
      ) {
        let reference_key_map = [];
        allTestDataDetails &&
          allTestDataDetails.map((testData, idx) => {
            let data = {
              antigram_reference_key:
                testData &&
                (testData[0]?.antigram_reference_key ||
                  testData?.antigram_reference_key),
              test_result_reference_key:
                testData[0]?.test_result_reference_key ||
                testData?.test_result_reference_key,
            };
            reference_key_map.push(data);
            apiObj.reference_key_map = reference_key_map;
          });
      }

      const apiResult = await createWorkups(apiObj);
      if (apiResult.status !== 200) {
        var err = apiResult;
        getStatus(err, navigate);
        changeSpinnerStatus(false);
        createOrEditWorkupStorage.isTheFlowSuccessful = false;
      }
      if (
        apiResult &&
        apiResult?.status === 200 &&
        Object.keys(apiResult).length > 0
      ) {
        createOrEditWorkupStorage.isTheFlowSuccessful = true;
        setTimeout(() => {
          navigate(`/workup-interpretation/${apiResult?.data?.workup_id}`, {
            state: {
              modalOpen: true,
              isAddToWorkupFlow,
              isCreateOrEditWorkupFlow: true,
            },
          });
          changeSpinnerStatus(false);
        }, 1000);
      }
    }
  };
  const handleEditWorkupCall = async (e) => {
    e.preventDefault();
    if (workupName == null || workupName == "") {
      setHasError(true);
      return;
    }

    changeSpinnerStatus(true);

    // Unlink the removed test data
    if (createOrEditWorkupStorage.testDataToRemove.length > 0) {
      const unlinkTasks = createOrEditWorkupStorage.testDataToRemove.map((t) =>
        unlinkTestData(workupId, t.id)
      );
      await Promise.all(unlinkTasks);
    }
    const workupData = createOrEditWorkupStorage.workupOriginalData;
    const workupRes = await getWorkupsDetails(workupId);

    const apiObj = { name: workupName, e_tag: workupRes.workup.e_tag };

    let reference_key_map_data = [];
    const newTestData = allTestDataDetails.filter((t) => {
      return (
        workupData.panels.find((p) => p.sample.unhashed == t.sample_id) == null
      );
    });

    newTestData.map((testData, idx) => {
      let data = {
        antigram_reference_key: testData.antigram_reference_key,
        test_result_reference_key: testData.test_result_reference_key,
      };
      reference_key_map_data.push(data);
    });

    apiObj.reference_key_map = reference_key_map_data;
    const res = await updtSingletWorkups(workupId, apiObj);
    if (res?.status !== 200) {
      var err = res;
      getStatus(err, navigate);
      createOrEditWorkupStorage.isTheFlowSuccessful = false;
    }
    if (res && res?.status == 200) {
      if (Object.keys(res).length > 0) {
        createOrEditWorkupStorage.isTheFlowSuccessful = true;
        const url =
          createOrEditWorkupStorage.origin == "antigram"
            ? `/workup-interpretation/${workupId}`
            : "/workup-home";

        setTimeout(() => {
          navigate(url, {
            state: {
              modalOpen: true,
              isAddToWorkupFlow,
              isCreateOrEditWorkupFlow,
            },
          });
          changeSpinnerStatus(false);
        }, 2000);
      }
    } else {
      changeSpinnerStatus(false);
    }
  };

  const handleRemoveTestData = (testData) => {
    testDataInRemoval.current = testData;
    setIsRemoveConfirmationOpen(true);
  };

  const handleRemoveConfirmationClose = () => {
    testDataInRemoval.current = null;
    setIsRemoveConfirmationOpen(false);
  };
  const handleTestDataRemoveConfirmation = () => {
    setIsRemoveConfirmationOpen(false);
    const testItem = testDataInRemoval.current;

    if (testItem == null) {
      return;
    }

    let arr = allTestDataDetails.filter(
      (t) => t.test_result_reference_key != testItem.test_result_reference_key
    );

    if (
      createOrEditWorkupStorage.workupOriginalData != null &&
      createOrEditWorkupStorage.workupOriginalData.panels.find(
        (p) => p.test_result_reference_key == testItem.test_result_reference_key
      ) != null
    ) {
      createOrEditWorkupStorage.testDataToRemove.push(testItem);
    }

    setAllTestDataDetails(arr);
    setIsRemoveConfirmationOpen(false);
  };

  const handleAddTestResults = () => {
    createOrEditWorkupStorage.workupName = workupName;
    createOrEditWorkupStorage.workupId = workupId;
    createOrEditWorkupStorage.selectedTestResults = allTestDataDetails;
    createOrEditWorkupStorage.isWorkupDataLoaded = true;
  };
  const warningMessage = () => {
    return (
      <React.Fragment>
        <WarningIcon className="warning-icon" style={{ color: "#F2A900" }} />
        <span className="alert-note">
          {workupConsts?.nameLimit}
        </span>
      </React.Fragment>
    );
  };
  const workupNameAlert = () =>{
    return(
        <PositionedSnackbar
        fwdStyles={{
          color: "#34383C",
          backgroundColor: "#FFF2D0",
          borderColor: "#F2A900",
        }}>
          {warningMessage()}
        </PositionedSnackbar>
    )
    }
  return (
    <div className="create-workup-container">
      {workupNameLimit && workupNameAlert()}
      <RemoveTestModal
        open={isRemoveConfirmationOpen}
        handleClose={handleRemoveConfirmationClose}
        handleProceed={handleTestDataRemoveConfirmation}
        text={
          createOrEditWorkupStorage.isEdit ? editWorkupDeleteMessage : undefined
        }
      />
      <div className="create-workup-header">
        <span>{isEdit ? "Edit Workup" : "Create Workup"}</span>
      </div>
      <div className="workup-information">
        <b>{workupConsts.workupInfo}</b>
        <label className="workup-label">
          {workupConsts.workupName} <span id="mandatory">*</span>
        </label>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className="workup-input"
        >
          <TextField
            placeholder="Enter Workup Name"
            id="filled-basic"
            InputProps={{
              disableUnderline: true,
              inputProps: {
                style: { paddingTop: "5px", paddingLeft: "0px" },
              },
            }}
            label=""
            variant="filled"
            value={workupName}
            onChange={(e) => handleInputChange(e)}
          />
          {hasError && (
            <p style={{ color: "red" }}>{workupConsts.workupNameErrMsg}</p>
          )}
        </Box>
      </div>
      {/* patient ui neeeded  */}
      {/* <div className="patient-information-container">
        <b>
          Patient Information
          <span className="optional"> (Optional)</span>
        </b>
        <Stack spacing={2} direction="row">
          <Link
            className="button"
            to={isEdit ? `/patient-home/${workupId}` : "/patient-home"}
            state={{ addPatientDetails: true }}
            variant="outlined"
          >
            {isEdit ? "Edit" : "Add"} patient
          </Link>
        </Stack>
        {allPatientData && allPatientData.length > 0 ? (
          allPatientData.map((patientItem, index) => {
            return (
              <div className="patient-info" key={index}>
                <div className="patient-column">
                  <label id="patient-heading">Patient Name</label>
                  <div id="data">{`${patientItem?.last_name} , ${patientItem?.first_name}`}</div>
                </div>
                <div className="patient-column">
                  <label id="patient-heading">Patient ID</label>
                  <div id="data">{patientItem?.id}</div>
                </div>
                <div className="patient-column">
                  <label id="patient-heading">Age</label>
                  <div id="data">{patientItem?.age}</div>
                </div>
                <div className="patient-column">
                  <label id="patient-heading">Date of birth</label>
                  <div id="data">
                    {patientItem?.date_of_birth &&
                      dayjs(patientItem?.date_of_birth)
                        .local()
                        .format("MM/DD/YYYY")}
                  </div>
                </div>
                <div className="patient-column">
                  <label id="patient-heading">Gender</label>
                  <div id="data">{patientItem?.gender}</div>
                </div>
                <div className="patient-column">
                  <label id="patient-heading">ABD/Rh</label>
                  <div id="data">{patientItem?.abo_rh}</div>
                </div>
                {isEdit && patientItem && (
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => handleDeletePatientItem}
                  >
                    <CrossMask fillColor={"#CF2F44"} width="14" height="14" />
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="patient-info">
            <div className="patient-column">
              <label id="patient-heading">Patient Name</label>
              <div id="data">{"--"}</div>
            </div>
            <div className="patient-column">
              <label id="patient-heading">Patient ID</label>
              <div id="data">{"--"}</div>
            </div>
            <div className="patient-column">
              <label id="patient-heading">Age</label>
              <div id="data">--</div>
            </div>
            <div className="patient-column">
              <label id="patient-heading">Date of birth</label>
              <div id="data">--</div>
            </div>
            <div className="patient-column">
              <label id="patient-heading">Gender</label>
              <div id="data">--</div>
            </div>
            <div className="patient-column">
              <label id="patient-heading">ABD/Rh</label>
              <div id="data">{"--"}</div>
            </div>
          </div>
        )}
      </div> */}
      <div className="test-info-container">
        <b>
          {isEdit
            ? `${createConsts.add}/${editConsts.remove}`
            : createConsts.add}{" "}
          {workupConsts.testResults}
          <span className="optional"> ({workupConsts.optional})</span>
        </b>
        <Stack direction="row">
          <Link
            className="button"
            onClick={handleAddTestResults}
            to={isEdit ? `/results/${workupId}` : "/"}
            state={{
              isAddToWorkupFlow,
              isCreateOrEditWorkupFlow: true,
              sidebarSelection: sidebar.Workups,
            }}
            variant="outlined"
          >
            {isEdit ? editConsts.edit : createConsts.add}{" "}
            {workupConsts.testResults}
          </Link>
        </Stack>

        {allTestDataDetails.length > 0 ? (
          allTestDataDetails?.map((testItem, index) => {
            return (
              <div className="test-info" key={index}>
                <div className="test-column">
                  <label id="test-heading">{workupConsts.sampleId}</label>
                  <div id="data">
                    {testItem?.sample_id?.length > 25 ? (
                      <LightTool
                        className="view-only-hover-popUp"
                        title={testItem.sample_id}
                        arrow
                        placement="top"
                      >
                        <span>
                          {testItem?.sample_id &&
                            testItem?.sample_id?.substring(0, 25) + "..."}
                        </span>
                      </LightTool>
                    ) : (
                      <span>{testItem?.sample_id}</span>
                    )}
                  </div>
                </div>
                <div className="test-column">
                  <label id="test-heading">{workupConsts.lot}</label>
                  <div id="data">{testItem?.lot_number}</div>
                </div>
                <div className="test-column">
                  <label id="test-heading">{workupConsts.timeOfResult}</label>
                  <div id="data">
                    {testItem?.result_dt &&
                      dayjs(testItem?.result_dt).format(
                        DateFormat() + " - hh:mm "
                      )}
                  </div>
                </div>
                <div className="test-column">
                  <label id="test-heading" className="test-column">
                    {workupConsts.lab}
                  </label>
                  {testItem?.laboratory === null ||
                  testItem?.laboratory === undefined ? (
                    "--"
                  ) : (
                    <div id="data">{testItem?.laboratory}</div>
                  )}
                </div>
                <div className="test-column">
                  <label id="test-heading">{workupConsts.panel}</label>
                  <div id="data">{testItem?.panel_type}</div>
                </div>
                {allTestDataDetails.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => handleRemoveTestData(testItem)}
                  >
                    <CrossMask fillColor={"#CF2F44"} width="14" height="14" />
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="test-info">
            <div className="test-column">
              <label id="test-heading">{workupConsts.sampleId}</label>
              <div id="data">--</div>
            </div>
            <div className="test-column">
              <label id="test-heading">{workupConsts.lot}</label>
              <div id="data">--</div>
            </div>
            <div className="test-column">
              <label id="test-heading">{workupConsts.timeOfResult}</label>
              <div id="data">--</div>
            </div>
            <div className="test-column">
              <label id="test-heading">{workupConsts.lab}</label>
              <div id="data">--</div>
            </div>
            <div className="test-column">
              <label id="test-heading">{workupConsts.panel}</label>
              <div id="data">--</div>
            </div>
          </div>
        )}
      </div>

      <div className="button-container">
        <Stack direction="row">
          <Link
            className="cancel"
            variant="outlined"
            to="/workup-home"
            state={{ addTestResults: false }}
          >
            {workupConsts.cancel}
          </Link>
        </Stack>{" "}
        <Stack direction="row">
          <Button
            className="submit"
            type="button"
            onClick={
              isEdit ? handleEditWorkupCall : (e) => handleCreateWorkupCall(e)
            }
            variant="contained"
          >
            {isEdit ? createConsts.save : createConsts.createWorkup}
          </Button>
        </Stack>
      </div>
    </div>
  );
};
export default SingleWorkup;
