import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SearchWorkups = ({
  onSearchTextChange,
  searchText,
  onEnter,
  onClearSearch,
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  const handleSearchChange = (e) => {
    const {
      target: { value },
    } = e;

    onSearchTextChange(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onEnter();
    }
  };

  const handleInputFocus = () => {
    setHasFocus(true);
  };

  const handleInputLostFocus = () => {
    setHasFocus(false);
  };

  return (
    <div>
      <TextField
        className="search"
        placeholder={hasFocus ? "" : "Search Workup "}
        type="text"
        variant="outlined"
        fullWidth
        size="small"
        onChange={handleSearchChange}
        onKeyPress={handleKeyPress}
        onFocus={handleInputFocus}
        onBlur={handleInputLostFocus}
        value={searchText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: hasFocus ? "#CF2F44" : null }} />
            </InputAdornment>
          ),

          endAdornment: searchText && (
            <IconButton
              aria-label="toggle password visibility"
              onClick={onClearSearch}
            >
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default SearchWorkups;
