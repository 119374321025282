import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import "./SnackbarPopup.scss";

export default function PositionedSnackbar(props) {
  const [open, setOpen] = React.useState(true);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = state;
  const handleClose = () => {
    setOpen(false);

    if (props.onClose) {
      props.onClose();
    }
  };
  const action = (
    <React.Fragment>
      <IconButton className="close-icon" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="snackbar-alert-message">
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        key={vertical + horizontal}
      >
        <div className="alert-info-message">
          <Alert icon={false} action={action} sx={props.fwdStyles}>
            {props.children}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
}
