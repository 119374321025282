import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";
export const saveSampleIDsToApi = async (sampleIds) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}{orgScope}/results/samples`, 
      { sample_ids: [... new Set(sampleIds)] }
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
     return err;
  }
};


export const getUnlinkedSampleIds = async () => {
    try {
      const response = await Api.get(
        `${REACT_APP_API_ENDPOINT}{orgScope}/results/samples`
      );
      if (response) {
        return response?.data?.unmatched;
      }
    } catch (err) {
       return err;
    }
  };

  export const deleteUnlinkedSampleId = async (sampleId) => {
    try {
      const response = await Api.delete(
        `${REACT_APP_API_ENDPOINT}{orgScope}/results/samples/${sampleId}`
      );
      if (response) {
        return response;
      }
    } catch (err) {
       return err;
    }
  };