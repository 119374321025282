import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './PositionedSnackbar.scss';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Constants from "../../languages/language";
export default function PositionedSnackbar() {
    const [open, setOpen] = React.useState(true);
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal } = state;
  const handleClose = (event, reason) => {
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton className='close-icon'
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  
  return (
    <div className='alert-message'>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        key={vertical + horizontal}
      >
        <div  className='alert-info-message' >
       {/* Commented for future use */}
        {/* <Alert icon={false} action={action} sx= { { color: '#34383C', backgroundColor: '#E9F0F3', borderColor: 'green' }}>
        <InfoIcon className='info-icon'/><span className='alert-note'>{Constants?.filters?.noresultsExist}
        </span></Alert> */}
        </div>
      </Snackbar>
      </div>
  );
}
