import React, {useState} from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayjs from "dayjs";
import { DateFormat } from "../../../utils/dateFormat";
import Constants from "../../../languages/language";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const labels = Constants.notificationLabels;
export const NotificationItem = ({ data, onClick, onMarkRead, type }) => {
  const [isHover, setIsHover] = useState(false);
  const getNewTestResultText = () => {
    return (
      <div className="first-row-container">
        <div onClick={() => onClick(data)}>
          <strong>{labels.newResults}</strong>
          <span>
            {labels.resultMessage1}
           <span className="actual-content">{data.sample_id}</span> 
            {labels.resultMessage2}
          </span>
        </div>

        {type === "unread" && (
         <span
         className="check-circle  second-column"
         onClick={() => onMarkRead(data)}
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={()=>setIsHover(false)}
       >
        {isHover? <CheckCircleIcon/>:<CheckCircleOutlineIcon/> }
       </span>
        )}
      </div>
    );
  };

  const getStatusChangeText = () => {
    return (
      <div className="first-row-container">
        <div onClick={() => onClick(data)}>
          <strong>{labels.statusChange}</strong>
          <span>
            {labels.statusMessage1} <span className="actual-content">{data.name}</span> {labels.statusMessage2}
          </span>
        </div>
        {type === "unread" && (
          <span
            className="check-circle  second-column"
            onClick={() => onMarkRead(data)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={()=>setIsHover(false)}
          >
           {isHover? <CheckCircleIcon/>:<CheckCircleOutlineIcon/> }
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="notification-results-wrapper">
      <div className="new-test-results">
        {data.type === "NEW_RESULT" && getNewTestResultText()}
        {data.type === "STATUS_CHANGE" && getStatusChangeText()}
        <p>
          {dayjs(data.arrived_dt)
            .format(DateFormat() + " - HH:mm")}
        </p>
      </div>
    </div>
  );
};
