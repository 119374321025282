import React from "react";
import { Button } from "@mui/material";
import "../../components/testTable/sampleId/sampleId.scss"
const UnlinkSampleId = ({ unlinkedSampleList, showSampleIdDetails, lang }) => {
  return (
      showSampleIdDetails && (
        <Button
          type="button"
          onClick={unlinkedSampleList}
          className="sample-unlink-btn"
        >
          {lang}
        </Button>
      )
  );
};
export default UnlinkSampleId;
