import React from "react";
import { ReactComponent as Error } from "../../assets/images/Error.svg";
import Constants from "../../languages/language";
import './index.scss';

const DetectBrowserComponent = () => {
  const browsertitle = Constants.userDesktop;
  return (

    <div className="mobile-device-banner">
      <div className="banner-wrapper">
        <div className="circle"><Error></Error></div>
        <div className="herderfont">{browsertitle?.desktopTitle}</div>
        <div className="bodyfont">{browsertitle?.desktopBody} </div>
        <div className="bodyfont">{browsertitle?.desktopBodytitle}</div>
      </div>
    </div>
  )
}
export default DetectBrowserComponent