import React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { ReactComponent as Settings } from "../../../assets/images/Settings_24px.svg";
import { Link } from "react-router-dom";
import "./index.scss";
import { Box } from "@mui/material";
import Constants from "../../../languages/language";
import ResultNotificationFilter from "./ResultNotificationFilter";
import { NotificationItem } from "./NotificationItem";
import { getDatePartDayJs, getTableDateTimeFormat } from "../../../utils/date";
import dayjs from "dayjs";
import { SpinnerComponent } from "../../spinner/spinner";
const utc = require("dayjs/plugin/utc");
const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(utc);
dayjs.extend(localizedFormat);

const labels = Constants.notificationLabels;
const style = {
  position: "absolute",
  right: 0,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewResultNotification = ({
  isOpen,
  onClose,
  updateFilters,
  filters,
  allNotifications,
  onMarkAllRead,
  onNotificationClick,
  onMarkNotificationAsRead,
  viewAllRead,
  viewAllUnread,
  selectedTab,
  onTabChange,
  onViewAll,
  showSpinner,
}) => {
  const handleUpdateFilters = (filters) => {
    updateFilters(filters);
  };

  const getNotificationsToShow = () => {
    if (selectedTab == "unread") {
      let notifications = allNotifications?.unread?.notifications;

      if (filters?.filterCount && filters?.filterCount > 0) {
        notifications = getFilteredData(notifications, filters);
      } else {
        notifications = viewAllUnread
          ? notifications
          : notifications?.slice(0, 10);
      }

      return notifications;
    }

    if (selectedTab == "read") {
      let notifications = allNotifications.read.notifications;

      if (filters.filterCount && filters.filterCount > 0) {
        notifications = getFilteredData(notifications, filters);
      } else {
        notifications = viewAllRead
          ? notifications
          : notifications?.slice(0, 10);
      }

      return notifications;
    }
  };

  const getFilteredData = (data, filters) => {
    let filteredData = data;

    if (filters.types && filters?.types?.length > 0) {
      filteredData = filteredData.filter((r) => filters.types.includes(r.type));
    }

    if (filters.startDate) {
      const startDateDayJS = getDatePartDayJs(filters.startDate);

      filteredData = filteredData.filter((p) => {
        const dt = getDatePartDayJs(new Date(p.arrived_dt));
        return dt >= startDateDayJS;
      });
    }

    if (filters.endDate) {
      const endDateDayJS = getDatePartDayJs(filters.endDate);

      filteredData = filteredData.filter((p) => {
        const dt = getDatePartDayJs(new Date(p.arrived_dt));
        return dt <= endDateDayJS;
      });
    }

    return filteredData;
  };

  const handleSettingsClick = () => {
    onClose();
  };

  const notificationsToShow = getNotificationsToShow();

  return (
    <Modal
      className="notification-container"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="notification-label">
          <span className="notification-data">{labels.notifications}</span>
          <span className="movert-icon">
            <Link to={"/manage-account"} onClick={handleSettingsClick}>
              <Settings />
            </Link>
          </span>
        </div>

        <div className="notification-buttons">
          <Button
            className={"button" + (selectedTab != "unread" ? " inactive" : "")}
            onClick={() => onTabChange("unread")}
          >
            {labels.unread} ({allNotifications?.unread?.total_records})
          </Button>
          <Button
            className={"button" + (selectedTab != "read" ? " inactive" : "")}
            onClick={() => onTabChange("read")}
          >
            {labels.read}
          </Button>
          <ResultNotificationFilter
            updateFilters={handleUpdateFilters}
            filters={filters}
          />
        </div>
        <div className="notification-items">
          {showSpinner && (
            <div className="notification-spinner-root">
              {<SpinnerComponent />}
            </div>
          )}
          {notificationsToShow?.length > 0 ? (
            notificationsToShow?.map((n) => (
              <NotificationItem
                key={n.id}
                data={n}
                onClick={onNotificationClick}
                onMarkRead={onMarkNotificationAsRead}
                type={selectedTab}
              />
            ))
          ) : (
            <div className="no-notifications">
              <NoNotificationMessage selectedTab={selectedTab} />
            </div>
          )}
        </div>

        <div className="all-read-wrapper">
          <span
            onClick={onMarkAllRead}
            className={selectedTab === "read" ? "disabled" : ""}
          >
            {labels.markAllRead}
          </span>
          <span className="view-all" onClick={onViewAll}>
            {labels.viewAll}
          </span>
        </div>
      </Box>
    </Modal>
  );
};

export default NewResultNotification;

const NoNotificationMessage = ({ selectedTab }) => {
  if (selectedTab === "read") {
    return <p>{labels.noReadNotiications}</p>;
  }
  if (selectedTab === "unread") {
    return <p>{labels.noUnReadNotiications}</p>;
  }
};
