import { flexbox } from "@mui/system";
import React, { useState, useContext } from "react";
import AlertDialogModal from "../../alertDialogModal/AlertDialogModal";
import ClassificationRow from "../antigramHeader/ClassificationRow/ClassificationRow";
import { SpinnerContext } from "../../../context/spinner-context";
import RuledTab from "../antigramHeader/ClassificationRow/RuledTab";
import "../antigramHeader/ClassificationRow/index.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Constant from '../../../languages/language'
const SubmitModal = (props) => {
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const warning = Constant.antigramTable?.panelDetails?.submitModal;
  const steps = Constant.workupDetails.editData.step2;
  const submit = Constant.workupDetails.editData.Submit;
  const analysis = Constant.workupDetails.editData.analysis;
  const modelCancel =Constant.AntigenHeader;
  
  const Styles = {
    "&.dialog-box":{
      zIndex: 777,
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "100%",
          height: "500px",
          borderRadius: "8px",
          paddingTop: "5px",
          // left: "30px",
          padding: "30px",
        },
        "& .MuiDialogTitle-root": {
          padding: "0px",
          "& .classification-tab":{
            padding:"0px",
          }
        },
        "& .MuiDialogContent-root ": {
          padding: "0px",
          overflow:"hidden",
          "& .MuiTypography-root": {
            "& .recommended-manual-container": {
              background: "#E9F0F3",
              display: "flex",
              width: "100%",
              padding: "0.25rem 1rem 1rem",
              borderRadius: "8px",
              flexDirection: "column",
              // margin:"5px",
              flexGrow: 0,
              boxSizing: "border-box",
              "& .MuiTypography-root":{
                color: "#000000",
                textTransform: "uppercase",
                fontWeight:500,
              },
              "& .classification-tab": {
                width: "100%",
                padding: "initial",
                "& .label-row": {
                  display: "none",
                },
              },
            },
          },
        },
      },
    },
  };
  const selectedAnalysis = localStorage.getItem("selectedAnalysis");
  const selectedAnalysisData = selectedAnalysis && JSON.parse(selectedAnalysis);
    const submitModalContent = () => {
    return (
      <div className="recommended-manual-container">
        <FormControlLabel
        checked={localStorage.getItem("selectedAnalysisKey") == null  ? false : true}
          value={localStorage.getItem("selectedAnalysisKey")}
          control={
            <Radio
              sx={{
                "&, &.Mui-checked": {
                  color: "#CF2F44",
                },
              }}
            />
          }
          label={localStorage.getItem("selectedAnalysisKey")}
        />
        <ClassificationRow
          ruledOutTab={props?.ruledOutTab}
          ruledClassificObj={selectedAnalysisData}
        />
      </div>
    );
  };
  const dualogTitleContent = () => {
    return (
      <div
        className="dialog-content"
        style={{
          fontSize: "20px",
          fontWeight: "600",
          color: "#000000",
        }}
      >
        {submit + localStorage.getItem("selectedAnalysisKey") + analysis}
        <span
          style={{
            fontSize: "14px",
            paddingLeft: "5px",
            fontWeight: "400",
            color: "#000000",
          }}
        >
          { steps }
        </span>
        <p
          style={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "22px",
            color: " #000000",
          }}
        >
          { warning }
        </p>
        <div className="classification-tab">
          <div className="label-row"
          style={{
            marginTop: "1.531rem",
          }}>
            <RuledTab />
          </div>
        </div>
      </div>
    );
  };

  return (
    <AlertDialogModal
      sx={Styles}
      dialogButtonName="test"
      dialogTitle={dualogTitleContent()}
      dialogContent={submitModalContent()}
      btnYesText={modelCancel.submit}
      btnNoText={modelCancel.cancel}
      open={props?.open}
      handleClickOpen={props?.handleClickOpen}
      handleClose={props?.handleClose}
      handleProceed={props?.handleSubmitWorkupData}
      showCloseicn={false}
    />
  );
};
export default SubmitModal;
