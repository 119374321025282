import React from "react";
import PositionedSnackbar from "../testTable/PositionedSnackbar";
import {ReactComponent as Search} from "../../assets/images/Search.svg"
import Constant from '../../languages/language'
export const NoResultsFound=()=>{
  const Warning = Constant?.notificationLabels?.noresultsFound;
    return (
        <div className="no-result-found">         
          <div><PositionedSnackbar/></div>
          <div className="circle"><Search></Search></div>
          <p>
          { Warning }
          </p>
        </div>
    );
}