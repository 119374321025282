import React, { useState, useEffect,useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PatientData } from "./views/patientData/PatientData";
import { Routes, Route, Navigate } from "react-router-dom";
import AntigramPanel from "./components/antigramPanel/AnitgramPanel";
import { currentConfig } from "./utils/amplify-config";
import { Amplify, Auth } from "aws-amplify";
import ProtectedRoute from "./routes";
import "./App.scss";
import PatientTable from "./components/patientTable/patientTable";
import PatientHome from "./components/patientTable/patientHome";
import CreatePatient from "./components/createPatient/createPatient";
import WorkupHomePage from "./components/workups/workupHomePage/WorkupHomePage";
import { SpinnerProvider } from "./components/spinner/spinner";
import SingleWorkup from "./views/singleWorkup/SingleWorkup";
import TestResultsPanel from "./components/testTable/antigramPanel/TestResultsPanel";
import ManageAccount from "./components/header/manageAccount/ManageAccount";
import LabSopOverview from "./components/header/labSopOverview/LabSopOverview";
import { getUserInfo } from "./Redux/Actions/user";
import { userInfo } from "./Redux/Store/user-info";
import AboutPageOverview from "./components/header/aboutOverview/AboutPageOverview";
import NotFound from "./components/notFound/NotFound";
import Error from "./components/error/Error";
import Header from "./components/header/Header";
import SessionTimeout from "./components/sessionTimeout/SessionTimeout.js";
import {getStatus} from "./utils/global";
import {AUTH_PROVIDER} from "./utils/constants";
import {useNavigate} from "react-router-dom";
import { SpinnerContext } from "./context/spinner-context.js";
import ServerErr from "./components/serverErr/ServerErr"
Amplify.configure(currentConfig);
function App() {
  const navigate=useNavigate()
  const [userSession, setUserSession] = useState({});
  const [userInfoPresent, setUserInfoPresent] = useState(false);
  const theme = createTheme({
    typography: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
    },
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      Auth.federatedSignIn({ provider: AUTH_PROVIDER });
    });
    getSession();

    getUserInfo().then((data) => {
      userInfo.data = data;
      setUserInfoPresent(true);
      if(data?.status !== 200)
      {
        var err = data;
        getStatus(err, navigate);
      }
    });
  }, []);

  const getSession = async () => {
    await Auth.currentSession().then((userSession) => {
      setUserSession(userSession);
      if (userSession?.idToken?.jwtToken == null) {
        window.location.reload();
      }
    });
  };
  const signIn = () => {
    Auth.currentAuthenticatedUser().catch(() => {
      Auth.federatedSignIn({ provider: AUTH_PROVIDER });
    });
  };

  const FeedbackPlugin = () => {
    var g = function (e, h, f, g) {
      this.get = function (a) {
        for (
          var x = a + "=", c = document.cookie.split(";"), b = 0, e = c.length;
          b < e;
          b++
        ) {
          for (var d = c[b]; " " == d.charAt(0); ) d = d.substring(1, d.length);
          if (0 == d.indexOf(a)) return d.substring(a.length, d.length);
        }
        return null;
      };

      this.set = function (a, c) {
        var x = "",
          b = new Date();
        b.setTime(b.getTime() + 6048e5);
        b = "; expires=" + b.toGMTString();
        document.cookie = a + "=" + c + b + "; path=/; ";
      };

      this.check = function () {
        var a = this.get(f);
        if (a) a = a.split(":");
        else if (100 != e)
          "v" == h && (e = Math.random() >= e / 100 ? 0 : 100),
            (a = [h, e, 0]),
            this.set(f, a.join(":"));
        else return !0;
        var c = a[1];
        if (100 == c) return !0;
        switch (a[0]) {
          case "v":
            return !1;
          case "r":
            return (
              (c = a[2] % Math.floor(100 / c)),
              a[2]++,
              this.set(f, a.join(":")),
              !c
            );
        }
        return !0;
      };

      this.go = function () {
        if (this.check()) {
          var a = document.createElement("script");
          a.type = "text/javascript";
          a.src = g;
          document.body && document.body.appendChild(a);
        }
      };

      this.start = function () {
        var t = this;
        "complete" !== document.readyState
          ? window.addEventListener
            ? window.addEventListener(
                "load",
                function () {
                  t.go();
                },
                !1
              )
            : window.attachEvent &&
              window.attachEvent("onload", function () {
                t.go();
              })
          : t.go();
      };
    };

    try {
      new g(
        100,
        "r",
        "QSI_S_ZN_e9ZXuS2Um85RXJY",
        "https://zne9zxus2um85rxjy-orthoclinical.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_e9ZXuS2Um85RXJY"
      ).start();
    } catch (i) {
      (".");
    }

    <div id="ZN_e9ZXuS2Um85RXJY"></div>;
  };

  return userSession &&
    userInfoPresent &&
    Object.keys(userSession).length !== 0 ? (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SpinnerProvider>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute
                  component={() => <PatientData addTestResults={false} />}
                />
              }
            />
            <Route
              path="/results/:workupId"
              element={
                <ProtectedRoute
                  component={() => <PatientData addTestResults={false} />}
                />
              }
            />
            <Route
              path="/workup-home"
              element={<ProtectedRoute component={WorkupHomePage} />}
            />
            <Route
              path="/workup-interpretation/:panelId"
              element={<ProtectedRoute component={AntigramPanel} />}
            />
            <Route
              path="/patient-home"
              element={
                <ProtectedRoute
                  component={() => <PatientHome addPatientDetails={false} />}
                />
              }
            />
            <Route
              path="/patient-home/:workupId"
              element={
                <ProtectedRoute
                  component={() => <PatientHome addPatientDetails={false} />}
                />
              }
            />
            <Route
              path="/create-patient"
              element={<ProtectedRoute component={CreatePatient} />}
            />
            <Route
              path="/single-workup"
              element={<ProtectedRoute component={SingleWorkup} />}
            />
            <Route
              path="/single-workup/:workupId"
              element={<ProtectedRoute component={SingleWorkup} />}
            />
            <Route
              path="/view-test-results/:panelId/:date"
              element={<ProtectedRoute component={TestResultsPanel} />}
            />
            <Route
              path="/manage-account"
              element={<ProtectedRoute component={ManageAccount} />}
            />
            <Route
              path="/lab-sop"
              element={<ProtectedRoute component={LabSopOverview} />}
            />
            <Route
              path="/about-page"
              element={<ProtectedRoute component={AboutPageOverview} />}
            />
            <Route
              path="/workup-interpretation/:panelId"
              element={<ProtectedRoute component={AntigramPanel} />}
            />
            <Route path="/error" element={<Error />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/not-found" />} />
            <Route path="/server-error" element={<ServerErr/>} />
          </Routes>
        </SpinnerProvider>
        <SessionTimeout />
      </ThemeProvider>
      <FeedbackPlugin></FeedbackPlugin>
    </div>
  ) : (
    // window.location.replace("https://extdev.ad.orthoclinical.com/en-us/home")
    signIn()
  );
}

export default App;
