import React, { useEffect, useState, useRef } from "react";
import NewResultNotification from "./NewResultNotification";
import {
  getReadNotifications,
  getUnreadNotifications,
  markRead,
  saveFilters,
} from "../../../Redux/Actions/notifications";
import { Badge, IconButton } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useNavigate } from "react-router-dom";
import {
  notificationStorage,
  resetNotificationFlow,
} from "../../../services/communicationService";
import { userInfo } from "../../../Redux/Store/user-info";
import {getStatus} from "../../../utils/global"
const getSavedFilters = (userInfoData) => {
  const filtersValue = userInfoData?.data?.notifications_filter;
  let savedFilters = {};

  if (filtersValue) {
    savedFilters = JSON.parse(filtersValue) || {};
  }

  return savedFilters;
};

export const NewResultNotificationContainer = () => {
  const [isOpened, setIsOpened] = React.useState(false);
  const [filters, setFilters] = useState(getSavedFilters(userInfo));
  const [viewAllRead, setViewAllRead] = useState(false);
  const [viewAllUnread, setViewAllUnread] = useState(false);
  const [selectedTab, setSelectedTab] = useState("unread");
  const [showSpinner, setShowSpinner] = useState(true);

  const [allNotifications, setAllNotifications] = useState({
    read: { notifications: [] },
    unread: { notifications: [], total_records: 0 },
  });

  const allNotificationsRef = useRef();
  allNotificationsRef.current = allNotifications;

  const navigate = useNavigate();

  useEffect(() => {
    const action = async () => {
      setShowSpinner(true);
      await updateUnreadNotifications();
      setShowSpinner(false);
    };

    action();
  }, [viewAllUnread]);

  const updateUnreadNotifications = async () => {
    const limit = viewAllUnread ? null : 10;
    const unreadNotifications = await getUnreadNotifications(limit);
    if(unreadNotifications?.status !== 200)
    {
      var err = unreadNotifications;
      getStatus(err, navigate);
    }
    setAllNotifications({
      ...allNotificationsRef.current,
      unread: unreadNotifications,
    });
  };

  const handleUpdateReadNotifications = async () => {
    setShowSpinner(true);
    await updateReadNotifications();
    setShowSpinner(false);
  };

  const updateReadNotifications = async () => {
    const limit = viewAllRead ? null : 10;
    const readNotifications = await getReadNotifications(limit);
    if(readNotifications?.status !== 200)
    {
      var err = readNotifications;
      getStatus(err, navigate);
    }
    setAllNotifications({
      ...allNotificationsRef.current,
      read: readNotifications,
    });
  };

  const unreadNotificationCallRef = useRef();
  unreadNotificationCallRef.current = updateUnreadNotifications;

  useEffect(() => {
    const timer = setInterval(() => unreadNotificationCallRef.current(), 30000);
    return () => clearInterval(timer);
  }, []);

  const handleOpen = () => setIsOpened(true);
  const handleClosed = () => setIsOpened(false);

  const handleMarkAllRead = async () => {
    setShowSpinner(true);
    const res =await markRead(allNotifications.unread.notifications.map((n) => n.id));
    if(res?.status !== 200)
    {
      var err = res;
      getStatus(err, navigate);
    }
    await updateUnreadNotifications();
    setShowSpinner(false);
  };

  const handleTabChange = async (name) => {
    setSelectedTab(name);

    if (name === "read") {
      handleUpdateReadNotifications();
    }
  };

  const handleUpdateFilters = (filters) => {
    setFilters(filters);
     saveFilters(filters).then((res)=>{
    }).catch((err)=>{
      if(err?.status !== 200)
    {
      getStatus(err, navigate);
    }
    });
  };

  const handleNotificationClick = async (notificationData) => {
    setIsOpened(false);
    await markRead([notificationData.id]);
    updateUnreadNotifications();
    updateReadNotifications();
    resetNotificationFlow();
    notificationStorage.isNotificationFlow = true;
    notificationStorage.notificationData = notificationData;
    const path =
      notificationData.type === "STATUS_CHANGE" ? "/workup-home" : "/";

    navigate(path, {
      state: { isNotificationFlow: true },
    });
  };

  const handleMarkNotificationAsRead = async (notificationData) => {
    await markRead([notificationData.id]);
    updateUnreadNotifications();
    updateReadNotifications();
  };

  const handleViewAll = async () => {
    if (selectedTab === "read") {
      setViewAllRead(true);
      await handleUpdateReadNotifications();
    }
    if (selectedTab === "unread") {
      setViewAllUnread(true);
    }
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="show 17 new notifications"
        color="inherit"
        onClick={handleOpen}
      >
        <Badge
          badgeContent={allNotifications?.unread?.total_records}
          max={allNotifications?.unread?.total_records}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          color="error"
        >
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
      {isOpened && (
        <NewResultNotification
          allNotifications={allNotifications}
          isOpen={true}
          onClose={handleClosed}
          onMarkAllRead={handleMarkAllRead}
          updateFilters={handleUpdateFilters}
          filters={filters}
          onNotificationClick={handleNotificationClick}
          onMarkNotificationAsRead={handleMarkNotificationAsRead}
          viewAllRead={viewAllRead}
          viewAllUnread={viewAllUnread}
          selectedTab={selectedTab}
          onTabChange={handleTabChange}
          onViewAll={handleViewAll}
          showSpinner={showSpinner}
        />
      )}
    </>
  );
};
