import React, { Fragment, useState, useEffect } from "react";
import HomePageLayout from "./views/homePageLayout/HomePageLayout";
import { TermsOfService } from "./components/termsOfService/TermsOfService";
import { userInfo } from "./Redux/Store/user-info";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const shouldAcceptTerms = userInfo.data?.terms_of_service === false;

  return (
    <Fragment>
      <HomePageLayout>
        {shouldAcceptTerms ? <TermsOfService /> : <Component />}
      </HomePageLayout>
    </Fragment>
  );
};

export default ProtectedRoute;
