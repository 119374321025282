import React from "react";
import dayjs from "dayjs";
import "./index.scss";
import Constants from "../../../../languages/language";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { DateFormat } from "../../../../utils/dateFormat";

const LightTool = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#34383c",
    maxWidth: 240,
    lineHeigth: 21,
    fontWeight: 400,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    PaddingRight: 16,
    borderRadius: 8,
    fontSize: 14,
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))",
  },
}));
export const WorkupTestDetails = ({ testResult }) => {
  const _testTableHeaders = Constants.testTable.testTableHeader;
  const workupDetails = Constants.workupHomePage.workupLabels;
  const addToWorkupText = Constants.workupHomePage.workupTestDetails;
  return (
    <div className="workup-test-detail-container">
      <h2 className="add-workup-title">{workupDetails.addToWorkup}</h2>
      <div className="test-info">
        <div className="test-column">
          <label id="test-heading">{_testTableHeaders.sample_id}</label>
          {testResult?.sample_id === null ||
          testResult?.sample_id === undefined ? (
            "--"
          ) : (
            <div className="test-data">
              {testResult && testResult?.sample_id?.substring(0, 25)}
            </div>
          )}
        </div>
        <div className="test-column">
          <label id="test-heading">{_testTableHeaders.lot}</label>
          {testResult?.lot_number === null ||
          testResult?.lot_number === undefined ? (
            "--"
          ) : (
            <div className="test-data">{testResult?.lot_number}</div>
          )}
        </div>
        <div className="test-column">
          <label id="test-heading">{_testTableHeaders.result_date}</label>

          <div>
            {testResult?.result_dt == null ? (
              "--"
            ) : (
              <div className="test-data">
                {dayjs(testResult?.result_dt)
                  .format(DateFormat() + " - HH:mm ")}
              </div>
            )}
          </div>
        </div>
        <div className="test-column">
          <label id="test-heading" className="test-column">{_testTableHeaders.lab}</label>
          {testResult?.laboratory === null ||
          testResult?.laboratory === undefined ? (
            "--"
          ) : (
            <div className="test-data">{testResult?.laboratory}</div>
          )}
        </div>
        <div className="test-column">
          <label id="test-heading">{_testTableHeaders.panel_type}</label>
          {testResult?.panel_type === null ||
          testResult?.panel_type === undefined ? (
            "--"
          ) : (
            <div className="test-data">{testResult?.panel_type}</div>
          )}
        </div>
      </div>
      <div className="add-workup-information">
        <h4>{workupDetails.workup}</h4>
        <p>{addToWorkupText}</p>
      </div>
    </div>
  );
};
