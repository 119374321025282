import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import './manageAccount.scss'
import { CHANGE_PASSWORD_URL } from '../../../utils/constants.js'
import Constants from '../../../languages/language'
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { changeNotificationsApi } from '../../../Redux/Actions/workups';
import { getStatusFromApi, getResultFromApi } from '../../../Redux/Actions/workups';
import { useNavigate } from 'react-router-dom';
import {getStatus} from "../../../utils/global";
const data = Constants.manageAccount;
const ManageAccount = () => {
  const ToggleSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#CF2F44",
      '&:hover': {
        backgroundColor: alpha("#CF2F44", theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#CF2F44",
    },
  }));

  const [resultState, setResultState] = useState();
  const [statusState, setStatusState] = useState();

  useEffect(() => {
    getStatusAPI();
    getResultAPI();
  }, []);
     const navigate = useNavigate();

  const getStatusAPI = async () => {
    const res = await getStatusFromApi();
    const responseData = res;
    setStatusState(responseData);
    if(res?.status !== 200)
  {
    var err = res;
    getStatus(err, navigate);
  }
  };
  const getResultAPI = async () => {
    const res = await getResultFromApi();
    const responseData = res;
    setResultState(responseData);
    if(res?.status !== 200)
  {
    var err = res;
    getStatus(err, navigate);
  }
  };

  const setStatusAPI = async () => {
    const res1 = await changeNotificationsApi("result_notifications", resultState);
    if (res1?.status !== 200) {
      getStatus(res1, navigate);
    }
    const res2 = await changeNotificationsApi("status_notifications", statusState);
    if (res1?.status !== 200) {
      getStatus(res1, navigate);
    }
  }

  const resultChange = () => {
    setResultState(resultState === true ? false : true)
  }
  const statusChange = () => {
    setStatusState(statusState === true ? false : true)
  }

  return (
    <div className='manage-accout'>
      <div className="manage-acc">{data?.heading?.Manage_account}</div>
      <div className="acc-preference">
        <div className="account-heading">{data?.AccountPreferences?.heading}</div>
        <a href={CHANGE_PASSWORD_URL}>
          <Box className='acc-preference-box'>
            <div className="change-pwd">{data?.AccountPreferences?.changePassword}</div>
            <div className='pwd-box-note'>{data?.AccountPreferences?.body}</div>
          </Box>
        </a>
      </div>

      <div className="notification-box">
        <div className="notification-box-heading">{data?.notification?.heading}</div>
        <Box className='new-results-box'>
          <div className="notification-box-content">
            <div className="new-results">{data?.notification?.newResults}</div>
          </div>
        </Box>
        <Box className='inApp-note-box'>
          <div className='result-box-note'>{data?.notification?.note}
            <div className="switch-note">{resultState === true ? data?.on : data?.off}</div>
            <ToggleSwitch className='toggle-switch' checked={resultState} onClick={resultChange} />
          </div>
        </Box>
        <Box className='status-change-box'>
          <div className='notification-box-content'>
            <div className="status-change">{data?.notification?.StatusChange}</div>
          </div>
        </Box>
        <Box className='inApp-note-box'>
          <div className='status-box-note'>{data?.notification?.note}
            <div className="switch-note">{statusState === true ? data?.on : data?.off}</div>
            <ToggleSwitch className='toggle-switch' checked={statusState} onClick={statusChange} />
          </div>
        </Box>
      </div>
      <div className="button-div">
        <Button variant="contained" onClick={setStatusAPI} className='save'>{data?.save_button}</Button>
      </div>
    </div>
  )
}

export default ManageAccount