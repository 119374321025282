import  React,{useEffect, useState} from "react";
import { Modal } from "@mui/material";
import Button from "@mui/material/Button";
import Notes from "./notes.js";
import "./notes.scss";
import { getPanelNotesCount } from "../../Redux/Actions/panelnotes.js";
import Constants from "../../languages/language";
import PanelNotes from "./PanelNotes.js";
import { useNavigate } from "react-router-dom";
import { getStatus } from "../../utils/global.js";
const notes = Constants.AntigenHeader;
const antigramTableConst = Constants.antigramTable;

export const FlyoutBox = (props) => {
  const navigate = useNavigate();
  const [anchorel, setanchorel] = React.useState(null);
  const [panelnotesCount, setpanelnotesCount] = React.useState(0);
  const panelnotes = Constants?.antigramTable?.panelDetails?.panelNotes;
  const open = Boolean(anchorel);
  const [panelId,setPanelId] = useState(props?.panelId)
  useEffect(()=>{
  getWorkupsCall();
  },[])
  const handleWorkupNotesClose = () => {
    setanchorel(null);
  };
  const handleClick = (event) => {
    setanchorel(event.currentTarget);
  }
  const handleWorkupNotes = () =>{
    setanchorel(null);
    props.handleWorkupNotesClose()
  }
  const handlePopupClose = () => {
    getWorkupsCall();
    setanchorel(null);
  };
  const getWorkupsCall = async () => {
    if (panelId) {
      const res = await getPanelNotesCount(panelId);
      setpanelnotesCount(res);
      if (res?.status !== 200) {
        var err = res;
        getStatus(err,  navigate);
      }
    }
  };
  return (
    <div style={{ opacity: "1", right: "30rem" }}>
      {props.label === antigramTableConst?.panelDetails?.panelNotes ? (
        <div
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {props.label}
          <span className="notes-count">{panelnotesCount}</span>
        </div>
      ) : (
        <Button
          className="wk-notes-btn"
          variant="outlined"
          color="error"
          onClick={handleClick}
        >
          <div
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
          >
            {notes.notes}
          </div>
          <span className="notes-count"> {props.notescount} </span>
        </Button>
      )}
      <Modal
        className="notes-flyout-box"
        id="long-menu"
        anchorel={anchorel}
        open={open}
        onClose={() => handleWorkupNotesClose()}
        style={{ right: "0", left: "auto", zIndex: "888" }}
      >
        <div className="workup-notes-pop">
          {props.label === panelnotes ? (
            <PanelNotes
              panelId={props.panelId}
              statusFilter={props.statusFilter}
              handlePopupClose={() => handlePopupClose()}
            />
          ) : (
            <Notes
              workupId={props.workupId}
              panelId={props.panelId}
              allNotes={props.allNotes}
              status={props.status}
              handlePopupClose={() => handleWorkupNotes()}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
