import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from './languages/en.json';
import translationFr from './languages/fr.json';

const DETECTION_OPTIONS = {
    order: ['navigator']
  };
const resource = {
    "en-US": {
      translation: translationEn,
    },
    "fr-CA": {
      translation: translationFr,
    },
  };
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: true,
    detection: DETECTION_OPTIONS,
    resources: resource
  });
  

export default i18n;