import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './index.scss'

const AlertDialogModal = (props) => {
  return (
    <div>
      <Dialog
        sx={props.sx}
        open={props?.open}
        className={props.className ? `${props.className} dialog-box` :"dialog-box"}
        onClose={props?.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="alert-dialog-title">{props?.dialogTitle}
        {props.showCloseicn !== false && <span><CloseOutlinedIcon className="close-icon" onClick={props?.handleClose}/></span>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="alert-dialog-description">
            {props?.dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="button"
            className="alert-cancel"
            onClick={props?.handleClose}
            autoFocus
            variant="outlined"
            disableRipple
          >
            {props?.btnNoText}
          </Button>
          
          {props?.selectedClassific!=="disabled" ? <Button
            id="button"
            className="alert-submit"
            onClick={props?.handleProceed}
            disableRipple
            variant="contained">
            {props?.btnYesText}
          </Button> : <Button
            id="button"
            className="alert-submit disabled"
            onClick={props?.handleProceed}
            disableRipple
            variant="contained" disabled>
            {props?.btnYesText}
          </Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AlertDialogModal;
