import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";

export const Mobliebrand = async (obj) => {

    try {
        const response = await Api.post(
          `${REACT_APP_API_ENDPOINT}log/device`,
         obj
        );
      return response;
  } catch (err) {
    return err;
  }
}