import { React, useState, useEffect, Fragment } from "react";
import Constants from "../../../../languages/language";
import FormattedLabel from "../../../formattedLabel/FormattedLabel";
import RuledTab from "./RuledTab";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import "./index.scss";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#34383C",
    filter: "drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.15))",
  },
}));

const ClassificationRow = ({
  antTypeVal,
  ruledClassificObj,
  changedClassifications,
  ruleType,
  slectedView,
  ruledOutTab
}) => {
  const [ruledClassObj, setRuledClassObj] = useState();
  useEffect(() => {
    setRuledClassObj({ ...ruledClassificObj });
  }, [ruledClassificObj]);

  const classificationRowData = () => {
    const colOrder = Constants.antigramTable.classificationRow;
    const classificationCells = [];

    let ruleClass;
    //let counts = 0;
    let couterLabel;
    for (let j = 0; j < colOrder.length; j++) {
      let discrepentClass = false;
      var hasChangedClass =
        changedClassifications?.indexOf(colOrder[j]) > -1 ? " changed" : "";
      if (
        ruledClassObj?.ruled_out &&
        ruledClassObj?.ruled_out.length > 0 &&
        ruledClassObj?.ruled_out.indexOf(colOrder[j]) > -1
      ) {
        ruleClass = "classific-ruled-out-bg";
      } else if (
      //   ruledClassObj?.unknown &&
      //   ruledClassObj?.unknown.length > 0 &&
      //   ruledClassObj?.unknown.indexOf(colOrder[j]) > -1
      // ) {
      //   ruleClass = "classific-ruled-out-bg";
      // } else if (
        ruledClassObj?.ruled_in &&
        ruledClassObj?.ruled_in.length > 0 &&
        ruledClassObj?.ruled_in.indexOf(colOrder[j]) > -1
      ) {
        const typingVal = antTypeVal && antTypeVal[colOrder[j]]?.grade;
        if(slectedView === "recommended" && (typingVal === "1+" || typingVal === "2+" || typingVal === "3+" || typingVal === "4+")){
          ruleClass = "classific-ruled-in-bg discrepent-cell";
          discrepentClass = true;
        }else{
          ruleClass = "classific-ruled-in-bg";
          discrepentClass = false;
        }
      } else {
        ruleClass = "";
        couterLabel = "";
      }
      classificationCells.push(
        <div key={j} className={`classfic-wrapper ${hasChangedClass}`}>
          { discrepentClass === true ? 
            <LightTooltip title={Constants.antigramTable.workupHeader.classifications.discrepantTooltipMsg} className="discrepant-hover-popup" placement="top-start">
              <span className={`classfic-cell cl-${j + 1} ${colOrder[j]} ${ruleClass}`}>
                <span>
                  <FormattedLabel j={colOrder[j]}></FormattedLabel>
                </span>
              </span>
            </LightTooltip> : 
            <span className={`classfic-cell cl-${j + 1} ${colOrder[j]} ${ruleClass}`}>
              <span>
                <FormattedLabel j={colOrder[j]}></FormattedLabel>
              </span>
            </span>
          }
          {couterLabel}
        </div>
      );
    }
    return classificationCells;
  };

  //   return classificationRowData()
  return (
    <div id="classification-row" className="classification-tab">
      <div className="label-row">
        {!ruledOutTab && <RuledTab />}
        {/* {// Keeping rules commented code for future requirements
          slectedView === "recommended" && (
          <div className="selected-rule">
            Rule:<span className="rule">{ruleType}</span>
          </div>
        )} */}
      </div>
      <div className="values-row">{classificationRowData()}</div>
    </div>
  );
};
export default ClassificationRow;
