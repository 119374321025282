import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";
import { useNavigate } from "react-router-dom";

export const getReadNotifications = async (limit) => {
  const params = limit !== null ? `status=READ&limit=${limit}` : `status=READ`;
  return await getNotifications(params);
};

export const getUnreadNotifications = async (limit) => {
  const params =
    limit != null ? `status=UNREAD&limit=${limit}` : `status=UNREAD`;
  return await getNotifications(params);
};

const getNotifications = async (queryParams) => {
  try {
    const response = await Api.get(
      `${REACT_APP_API_ENDPOINT}me/notifications?${queryParams}`,
      {}
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
    return err;
  }
};

export const markRead = async (ids) => {
  const apiObj = {
    read: ids,
  };

  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}me/notifications`,
      apiObj
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
    return err;
  }
};

export const saveFilters = (filters) => {
  try {
    const response = Api.post(
      `${REACT_APP_API_ENDPOINT}me/kvs/notifications_filter`,
      { value: JSON.stringify(filters) }
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};
