import React, { useState, useContext } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { WorkupIinterpretationData } from "../../../context/workup-interpretation.js";
import ClassificationRow from "../antigramHeader/ClassificationRow/ClassificationRow";
import language from "../../../languages/language";

const submitModel=language.AntigenHeader;
// import "../antigramHeader/index.scss";

const submitModalSelector = (props) => {
  const [value, setValue] = useState("");
  
  const contextIntrptData  = useContext(WorkupIinterpretationData);
  // Keeping rules commented code for future requirements
  // const classificationOrigParsedData = props.ruleTyp === "3 x 3"? 
  // contextIntrptData[0]?.alternate_classifications["3x3"]:
  // contextIntrptData[0]?.alternate_classifications["2x2"];
  const classificationOrigParsedData = contextIntrptData[0]?.classifications?.algorithm_solution;
  const classificationModifParsedData = contextIntrptData[0]?.classifications?.user_solution;

  const handleChange = (event, id) => {
    setValue(event.target.value);
    if (event.target.value == "Recommended") {
      props.handleRecColor("#E9F0F3")
      localStorage.setItem(
        "selectedAnalysisKey",
       event.target.value
      );
      localStorage.setItem(
        "selectedAnalysis",
        JSON.stringify(classificationOrigParsedData)
      );
    } else if (event.target.value == "Manual") {
      props.handleManualColor("#E9F0F3")
      localStorage.setItem(
        "selectedAnalysisKey",
        event.target.value
      );
      localStorage.setItem(
        "selectedAnalysis",
        JSON.stringify(classificationModifParsedData)
      );
    }
    //props.testColor("#E9F0F3")
    // setValue(id);
  };

  return (
      <FormControl>
        {/* <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel> */}
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <label>
            <div className="recommended-container">
              <FormControlLabel
                value="Recommended"
                control={
                  <Radio
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#CF2F44",
                      },
                    }}
                  />
                }
                label={submitModel?.recommended}
              />
              <ClassificationRow ruledOutTab={props?.ruledOutTab} ruledClassificObj={classificationOrigParsedData} />
            </div>
          </label>
          <label>
            <div className="manual-container">
              <FormControlLabel
                value="Manual"
                control={
                  <Radio
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#CF2F44",
                      },
                    }}
                  />
                }
                label={submitModel.manual}
              />
              <ClassificationRow
              ruledOutTab={props?.ruledOutTab} 
                ruledClassificObj={classificationModifParsedData}
              />
            </div>
          </label>
        </RadioGroup>
      </FormControl>
  );
};
export default submitModalSelector;
