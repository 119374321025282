import React, { useState, useEffect, useContext } from "react";
import { aboutPageOverview } from "../../../Redux/Actions/aboutPageOverview";
import Constants from "../../../languages/language";
import { SpinnerContext } from "../../../context/spinner-context";
import "./about-page.scss";
import i18n from "../../../i18n";
import {getStatus} from "../../../utils/global";
import { useNavigate } from "react-router-dom";
const AboutPageOverview = () => {
  const aboutPageConsts = Constants.aboutPageOverview;
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const [aboutPageData, setaboutPageData] = useState({});
  const navigate=useNavigate();
  const arrData = [];
  const aboutPageOverviewFetch = async () => {
    changeSpinnerStatus(true);
    const res = await aboutPageOverview();
    if (res?.status !== 200) {
      var err = res;
      getStatus(err, navigate);
    }
    const browserLanguage =
      i18n.language === "fr-CA" ? res?.data["fr-CA"] : res?.data["en-US"];
    Object.entries(browserLanguage).forEach(function (key, index) {
      arrData.push(key[1]);
      setaboutPageData(arrData);
    });
    changeSpinnerStatus(false);
  };
  useEffect(() => {
    aboutPageOverviewFetch();
  }, []);
  return (
    <div className="about-page-container">
      <div className="about-page-wrapper">
        <span className="about-page-heading">
          {aboutPageConsts.aboutPageTitle}
        </span>
      </div>
      {aboutPageData &&
        aboutPageData.length > 0 &&
        aboutPageData?.map((ite, idx) => {
          return (
            <div className="about-page-rule" key={idx}>
              <span className="rule-label">{`${ite?.title} `}</span>
              <p className="rule-paragraph">{ite?.body}</p>
            </div>
          );
        })}
    </div>
  );
};

export default AboutPageOverview;
