import React, { useEffect, useRef, useState } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import SessionTimeoutDialog from './SessionTimeoutDialog.js'
import { Auth } from "aws-amplify/lib-esm";
import {AUTH_PROVIDER} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import {getUserInfo} from "../../Redux/Actions/user.js"
import { getStatus } from "../../utils/global";

function SessionTimeout() {
    const idleTimerRef = React.createRef(null)
    const location = useLocation();
    const status = location?.state?.status;
    const navigate = useNavigate();

    const [checkLogOut, setCheckLogOut] = useState(false)

    const extendSessionCheck = () =>{
        setCheckLogOut(true)
    }

    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [apiResponse, setapiResponse] = useState(false);
    const logOutCheck = async () => {
        const res = await getUserInfo();
        const responseData = res;
        setapiResponse(responseData);
        if (res?.status !== 200) {
          var err = res;
          getStatus(err, navigate);
        }
        setCheckLogOut(false)
      };

    const handleContinue = () => {
        if(checkLogOut && status !== "511"){
        logOutCheck()
        }
        setTimeoutModalOpen(false);
        clearTimeout(idleTimerRef.current)
        localStorage.setItem("sessionTimeout","false")   
    };

    const logOut = async () => {
        clearTimeout(idleTimerRef.current)
        await Auth.signOut({ provider: AUTH_PROVIDER });
        window?.location?.reload()
    }

    const onIdle = () => {
        setTimeoutModalOpen(true)
        localStorage.setItem("sessionTimeout","true")
    }

    const onActive = () =>{
        localStorage.getItem("sessionTimeout") === "false" ?
        handleContinue() : null
    }


    return (
        <div>
            <div ref={idleTimerRef}>
            <IdleTimerProvider timeout={600 * 1000} crossTab={true} onActive={onActive} syncTimers={200} onIdle={onIdle}></IdleTimerProvider>
            </div>
            {(timeoutModalOpen && status !== "511") && <SessionTimeoutDialog
                onContinue={handleContinue}
                open={timeoutModalOpen}
                onLogout={logOut}
                extendSessionCheck={extendSessionCheck}
            />}
        </div>
    )
}

export default SessionTimeout