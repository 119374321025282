import React from "react";
import { useLocation } from "react-router-dom";
import { ErrorCode } from "../../utils/icons";
import Constants from "../../languages/en.json";
import "./index.scss";
import Header from "../header/Header";
import { AppBar, Toolbar } from "@mui/material";
import { userData } from "../../views/homePageLayout/apiData";
import Sidebar from "../sidebar/Sidebar";
const NotFound = () => {
  const location = useLocation();
  const err_handle = Constants.errorhandler;
  {
    window.location.pathname === "/error" && <Header />;
  }
  const  status = location?.state?.status || " ";
  const statusText = location?.state?.statusText || " ";
  return (
    <div className="homepage-layout-root">
    <div className="homapage-header">
      <Header userData={userData} />
    </div>
  <div className="homepage-main-wrapper">
      <div className="homepage-sidebar">
        <Sidebar />
      </div>
      <div className="homepage-content-wrapper">
    <Toolbar>
      <div className="error-handler">
        <ErrorCode />
        <div className="status-msg">
          {err_handle.notFound }
        </div>
        <p>
          {         
            err_handle.tryAgain
          }
        </p>
      </div>
    </Toolbar>
    </div>
   </div>   
  </div>
  );
};

export default NotFound;
