import  Constants  from "../languages/en.json"

const errMsg=Constants.errorMessages;
export const getStatus=(err,navigate)=>{
     if(err?.status === 400){
      navigate(`/error`,{state:{status:"400",statusText:err.statusText}})
    }
    if(err?.status === 401){
      navigate(`/error`,{state:{status:"401",statusText:err.statusText}})
    }
    if(err?.status === 402){
      navigate(`/error`,{state:{status:"402",statusText:err.statusText}})
    }
    if(err?.status === 403){
      navigate(`/error`,{state:{status:"403",statusText:err.statusText}})
    }
    if(err?.status === 404){
      navigate(`/error`,{state:{status:"404",statusText:err.statusText}})
    }
    if(err?.status === 405){
      navigate(`/error`,{state:{status:"405",statusText:err.statusText}})
    }
    if(err?.status === 0){
      navigate(`/error`,{state:{status:"0",statusText:err.statusText}})
    }
    if(err?.status === 500){
      navigate(`/server-error`,{state:{status:"500",statusText:errMsg.err_500}})
    }
    if(err?.status === 501){
      navigate(`/server-error`,{state:{status:"501",statusText:err.statusText}})
    }
    if(err?.status === 502){
      navigate(`/server-error`,{state:{status:"502",statusText:errMsg.err_502}})
    }
    if(err?.status === 503){
      navigate(`/server-error`,{state:{status:"503",statusText:errMsg.err_503}})
    }
    if(err?.status === 504){
      navigate(`/server-error`,{state:{status:"504",statusText:errMsg.err_504}})
    }
    if(err?.status === 511){
      navigate(`/server-error`,{state:{status:"511",statusText:err.statusText}})
    }
}