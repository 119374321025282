import React from "react";
import AlertDialogModal from "../../components/alertDialogModal/AlertDialogModal";
import Constants from "../../languages/language";
const workupConsts = Constants.workupDetails.workupform;

import "./index.scss";
const RemoveTestModal = (props) => {
  const editWorkupremoveDeleteMessage =
  Constants.workupDetails.createData.remove
  const Styles = {
    "& .MuiDialog-container": {
      padding: "60px",
      "& .MuiPaper-root": {
        width: "600px",
        padding: "8px",
        "& .MuiTypography-root": {
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#34383C",
          "& .section-line": {
            position: "absolute",
            height: "10px",
            left: "0px",
            right: "0px",
            top: "0px",
            background: "#E7E7ED",
          },
          "& .remove-modal-sub-content": {
            fontWeight: "400",
            fontSize: "14px",
            color: "#34383C",
            lineHeight: "45px",
            borderTop:"1px solid #E7E7ED",
          },
        },
        "& .MuiButtonBase-root.alert-cancel": {
          color: "#34383C",
          fontWeight: "500",
          fontSize: "12px",
          lineHeight: "18px",
          border: "1px solid #34383C",
          padding: "8px 22px",
          webkitTapHighlightColor: "none",
        },
        "& .MuiButtonBase-root.alert-submit": {
          padding: "9px 22px",
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "21px",
          webkitTapHighlightColor: "none",
        },
      },
    },
  };
  const contentHere = () => {
    return (
      <>
        <div className="remove-modal-sub-content">
          {props.text ||  workupConsts.removeTestModal.content}
          <br/>
          {editWorkupremoveDeleteMessage}
        </div>
      </>
    );
  };
  return (
    <AlertDialogModal
      sx={Styles}
      dialogButtonName="test"
      className="remove-test-modal"
      dialogTitle={workupConsts.removeTestModal.title}
      dialogContent={contentHere()}
      btnYesText={workupConsts.removeTestModal.remove}
      btnNoText={workupConsts.removeTestModal.cancel}
      open={props?.open}
      handleClose={props?.handleClose}
      handleProceed={props?.handleProceed}
    />
  );
};
export default RemoveTestModal;
