import React, { useEffect, useState, useContext, useRef } from "react";
import AntigramTable from "./antigramTable/AntigramTable.js";
import { getSingleResult } from "../../../Redux/Actions/results.js";
import { useParams, useNavigate } from "react-router-dom";
import "./index.scss";
import Button from "@mui/material/Button";
import Constants from "../../../languages/language";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { SpinnerContext } from "../../../context/spinner-context.js";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import RubikMedium from "../../rubikFonts/RubikMedium.js";
import RubikRegular from "../../rubikFonts/RubikRegular.js";
import { styled } from "@mui/material/styles";
import "./index.scss";
import { getDownloadResults } from "../../../Redux/Actions/results.js";
import { DateFormat } from "../../../utils/dateFormat.js";
import {
  addToWorkupStorage,
  resetAddToWorkupFlow,
} from "../../../services/communicationService";
import "./index.scss";
import dayjs from "dayjs";
import { getStatus } from "../../../utils/global.js";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const HeaderComp = Constants.antigramTable.headerComponents;
const DownloadInfo = Constants.downloadResults;

const Light = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#34383C",
    fontWeight: 400,
    padding: 12,
    borderRadius: 6,
    fontSize: 10,
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))",
  },
}));
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#000000",
    lineHeigth: 17,
    fontWeight: 400,
    padding: 10,
    borderRadius: 6,
    fontSize: 14,
    filter: "drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08))",
  },
}));
const TestResultsPanel = () => {
  const [resultApi, setResultApi] = useState();
  const { panelId, date } = useParams();
  useEffect(() => {
    changeSpinnerStatus(true);
    getWorkupsCall();
    DownloadResult();
  }, []);

  const DownloadResult = async () => {
      const res = await getDownloadResults(panelId);
      if(res?.status !== 200)
      {
        var err = res
        getStatus(err, navigate);
      }
      if (res) {
        const responseData = res?.data;
        setResultApi(responseData);
      }
  };

  const generatePDF = () => {
    changeSpinnerStatus(true);
    const current = new Date();
    const downloadDate = dayjs(current).format(DateFormat());
    var doc = document.getElementById("panel");
    doc.page = 1;
    var totalPages = 2;

    html2canvas(doc, {
      ignoreElements: function (element) {
        if ("see-details" == element.id) {
          return true;
        }
      },
      scale: 5,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      var scaleBy = 5;
      var pdf = new jsPDF("l", "px", "letter");
      pdf.addFileToVFS("Rubik-Medium-normal.ttf", RubikMedium);
      pdf.addFont("Rubik-Medium-normal.ttf", "Rubik-Medium", "normal");
      pdf.addFileToVFS("Rubik-Regular-normal.ttf", RubikRegular);
      pdf.addFont("Rubik-Regular-normal.ttf", "Rubik-Regular", "normal");

      pdf.setFontSize(19);
      pdf.setFont("Rubik-Regular");
      pdf.text(20, 28, DownloadInfo.logo);

      pdf.setFontSize(19);
      pdf.addFileToVFS("Rubik-SemiBold.ttf", RubikMedium);
      pdf.addFont("Rubik-SemiBold.ttf", "Rubik-SemiBold", "normal");
      pdf.setFont("Rubik-SemiBold");
      pdf.text(20, 60, "Panel " + apiResponse?.panel?.panel_type);
      pdf.text(
        20,
        82,
        "Sample ID: " + apiResponse?.panel?.sample?.unhashed.slice(0, 20)
      );

      pdf.setFont("Rubik-Medium");
      pdf.setFontSize(12);
      pdf.text(460, 60, DownloadInfo.reagent_rbc);
      pdf.text(460, 72, apiResponse?.panel?.product);
      pdf.text(460, 84, DownloadInfo.antigen_profile);

      pdf.setFontSize(8);
      pdf.setFont("Rubik-Regular");
      if(apiResponse?.panel?.panel_type !== "Screen"){
      pdf.text(20, 395, resultApi.static[0]);
      pdf.text(305, 395, resultApi.static[1]);
      }
      else{
      pdf.text(20, 280, resultApi.static[0]);
      pdf.text(305, 280, resultApi.static[1]);
      }
      pdf.text(478, 450, DownloadInfo.footer_ortho);

      pdf.setFontSize(10);
      pdf.text(415, 26, DownloadInfo.download_date + downloadDate);
      pdf.text(
        532,
        26,
        DownloadInfo.page + doc.page + DownloadInfo.of + totalPages
      );
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width + 10;
      var context = canvas.getContext("2d");
      context.scale(scaleBy, scaleBy);
      pdf.addImage(imgData, "PNG", 0, 110, pdfWidth, pdfHeight);
      pdf.addPage();
      doc.page++;
      pdf.setFontSize(10);
      pdf.text(415, 26, DownloadInfo.download_date + downloadDate);
      pdf.text(
        532,
        26,
        DownloadInfo.page + doc.page + DownloadInfo.of + totalPages
      );
      pdf.setFontSize(8);
      pdf.text(40, 60, resultApi["en-US"]);
      pdf.line(40, 130, 550, 130);
      pdf.text(40, 140, resultApi["fr-CA"]);
      pdf.line(40, 210, 550, 210);
      pdf.save(DownloadInfo.pdf_name);
      changeSpinnerStatus(false);
    });
  };
  const { changeSpinnerStatus } = useContext(SpinnerContext);
  const [apiResponse, setapiResponse] = useState([]);
  const testResultRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const value = localStorage.getItem("view-menu-testresult"); //,JSON.stringify( testResult);
    testResultRef.current = value !== null ? JSON.parse(value) : null;
  }, []);

  const getWorkupsCall = async () => {
      const res = await getSingleResult(panelId);
      if(res?.status !== 200)
      {
        var err = res
        getStatus(err, navigate);
      }
      if (res) {
        const responseData = res?.data;
        setapiResponse(responseData);
      }
      changeSpinnerStatus(false)
  };
  const [openPanel, setOpenPanel] = useState(false);

  const ResultViewDetails = async () => {
    setLoad(true);
      const res = await getDownloadResults(panelId);
      if(res?.status !== 200)
      {
        var err = res
        getStatus(err, navigate);
      }
      if (res) {
        const responseData = res?.data;
        setPanelApiResponse(responseData);
      }
    setLoad(false);
  };
  const [panelApiResponse, setPanelApiResponse] = useState();
  const [load, setLoad] = useState(false);
  const handleOpen = () => {
    setOpenPanel(true);
  };
  useEffect(() => {
    if (openPanel) {
      ResultViewDetails();
    }
  }, [openPanel]);

  const panelClose = () => {
    setOpenPanel(false);
  };

  const handleAddToWorkup = () => {
    resetAddToWorkupFlow();
    addToWorkupStorage.selectedTestResult = testResultRef.current;
    navigate(`/workup-home`, {
      state: {
        isAddToWorkupFlow: true,
      },
    });
  };

  function NewAntigramHeader() {
    const antigramHederData = apiResponse?.panel;
    const { sample, expiration_date } = antigramHederData;

    return (
      <div className="test-table-antigram-panel-header-wrapper-view">
        <div className="antigram-panel-header">
          <div className="antigram-title-row">
            <span className="workuptitle">
              {sample?.unhashed?.length > 20 ? (
                <LightTooltip
                  className="view-only-hover-popUp"
                  title={sample?.unhashed}
                  arrow
                  placement="top"
                >
                  <div className="workuptitleMain">
                    {sample?.unhashed &&
                      sample?.unhashed?.substring(0, 20) + "..."}
                  </div>
                </LightTooltip>
              ) : (
                <div className="workuptitleMain">{sample?.unhashed}</div>
              )}
            </span>
            <span className="last-modified">
              {date === undefined
                ? "-"
                : dayjs(date).format(DateFormat() + " HH:mm")}
            </span>
            <span className="hyphen"> - </span>
            <span className="view-only"> {HeaderComp.ViewOnly}</span>
            <FileDownloadOutlinedIcon
              onClick={generatePDF}
              className="download-button"
            />
            <Button
              className="wk-submit-btn"
              variant="contained"
              onClick={handleAddToWorkup}
            >
              {HeaderComp.AddToWorkup}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    apiResponse &&
    Object.keys(apiResponse).length !== 0 && (
      <div className="antigram-panel-root">
        <div>
          <NewAntigramHeader />
        </div>
        <div id="panel">
          <AntigramTable
            handleOpen={handleOpen}
            panelApiResponse={panelApiResponse}
            openPanel={openPanel}
            panelClose={panelClose}
            apiAllData={apiResponse}
            load={load}
          />
        </div>
      </div>
    )
  );
};

export default TestResultsPanel;
