
export const DateFormat = () => {
  const formats = {
    "af-ZA": "YYYY/MM/DD",
    "am-ET": "D/M/YYYY",
    "ar-AE": "DD/MM/YYYY",
    "ar-BH": "DD/MM/YYYY",
    "ar-DZ": "DD/MM/YYYY",
    "ar-EG": "DD/MM/YYYY",
    "ar-IQ": "DD/MM/YYYY",
    "ar-JO": "DD/MM/YYYY",
    "ar-KW": "DD/MM/YYYY",
    "ar-LB": "DD/MM/YYYY",
    "ar-LY": "DD/MM/YYYY",
    "ar-MA": "DD/MM/YYYY",
    "ar-OM": "DD/MM/YYYY",
    "ar-QA": "DD/MM/YYYY",
    "ar-SA": "DD/MM/YYYY",
    "ar-SY": "DD/MM/YYYY",
    "ar-TN": "DD/MM/YYYY",
    "ar-YE": "DD/MM/YYYY",
    "arn-CL": "DD/MM/YYYY",
    "as-IN": "DD/MM/YYYY",
    "az-Cyrl-AZ": "DD/MM/YYYY",
    "az-Latn-AZ": "DD/MM/YYYY",
    "ba-RU": "DD/MM/YYYY",
    "be-BY": "DD/MM/YYYY",
    "bg-BG": "DD/M/YYYY",
    "bn-BD": "DD/MM/YYYY",
    "bn-IN": "DD/MM/YYYY",
    "bo-CN": "YYYY/M/D",
    "br-FR": "DD/MM/YYYY",
    "bs-Cyrl-BA": "D/M/YYYY",
    "bs-Latn-BA": "D/M/YYYY",
    "ca-ES": "DD/MM/YYYY",
    "co-FR": "DD/MM/YYYY",
    "cs-CZ": "D/M/YYYY",
    "cy-GB": "DD/MM/YYYY",
    "Da-DK": "DD/MM/YYYY",
    "De-AT": "DD/MM/YYYY",
    "De-CH": "DD/MM/YYYY",
    "De-DE": "DD/MM/YYYY",
    "De-LI": "DD/MM/YYYY",
    "De-LU": "DD/MM/YYYY",
    "Dsb-DE": "D/ M/ YYYY",
    "Dv-MV": "DD/MM/YYYY",
    "el-GR": "D/M/YYYY",
    "en-029": "MM/DD/YYYY",
    "en-AU": "D/MM/YYYY",
    "en-BZ": "DD/MM/YYYY",
    "en-CA": "DD/MM/YYYY",
    "en-GB": "DD/MM/YYYY",
    "en-IE": "DD/MM/YYYY",
    "en-IN": "DD/MM/YYYY",
    "en-JM": "DD/MM/YYYY",
    "en-MY": "D/M/YYYY",
    "en-NZ": "D/MM/YYYY",
    "en-PH": "M/D/YYYY",
    "en-SG": "D/M/YYYY",
    "en-TT": "DD/MM/YYYY",
    "en-US": "M/D/YYYY",
    "en-ZA": "YYYY/MM/DD",
    "en-ZW": "M/D/YYYY",
    "es-AR": "DD/MM/YYYY",
    "es-BO": "DD/MM/YYYY",
    "es-CL": "DD/MM/YYYY",
    "es-CO": "DD/MM/YYYY",
    "es-CR": "DD/MM/YYYY",
    "es-DO": "DD/MM/YYYY",
    "es-EC": "DD/MM/YYYY",
    "es-ES": "DD/MM/YYYY",
    "es-GT": "DD/MM/YYYY",
    "es-HN": "DD/MM/YYYY",
    "es-MX": "DD/MM/YYYY",
    "es-NI": "DD/MM/YYYY",
    "es-PA": "MM/DD/YYYY",
    "es-PE": "DD/MM/YYYY",
    "es-PR": "DD/MM/YYYY",
    "es-PY": "DD/MM/YYYY",
    "es-SV": "DD/MM/YYYY",
    "es-US": "M/D/YYYY",
    "es-UY": "DD/MM/YYYY",
    "es-VE": "DD/MM/YYYY",
    "et-EE": "D/MM/YYYY",
    "eu-ES": "YYYY/MM/DD",
    "fa-IR": "MM/DD/YYYY",
    "fi-FI": "D/M/YYYY",
    "fil-PH": "M/D/YYYY",
    "fo-FO": "DD/MM/YYYY",
    "fr-BE": "D/MM/YYYY",
    "fr-CA": "DD/MM/YYYY",
    "fr-CH": "DD/MM/YYYY",
    "fr-FR": "DD/MM/YYYY",
    "fr-LU": "DD/MM/YYYY",
    "fr-MC": "DD/MM/YYYY",
    "fY/NL": "D/M/YYYY",
    "ga-IE": "DD/MM/YYYY",
    "gd-GB": "DD/MM/YYYY",
    "gl-ES": "DD/MM/YYYY",
    "gsw-FR": "DD/MM/YYYY",
    "gu-IN": "DD/MM/YYYY",
    "ha-Latn-NG": "D/M/YYYY",
    "he-IL": "DD/MM/YYYY",
    "hi-IN": "DD/MM/YYYY",
    "hr-BA": "D/M/YYYY",
    "hr-HR": "D/M/YYYY",
    "hsb-DE": "D/ M/ YYYY",
    "hu-HU": "YYYY/ MM/ DD",
    "hy-AM": "DD/MM/YYYY",
    "iD-ID": "DD/MM/YYYY",
    "ig-NG": "D/M/YYYY",
    "ii-CN": "YYYY/M/D",
    "is-IS": "D/M/YYYY",
    "it-CH": "DD/MM/YYYY",
    "it-IT": "DD/MM/YYYY",
    "iu-Cans-CA": "D/M/YYYY",
    "iu-Latn-CA": "D/MM/YYYY",
    "ja-JP": "YYYY/MM/DD",
    "ka-GE": "DD/MM/YYYY",
    "kk-KZ": "DD/MM/YYYY",
    "kl-GL": "DD/MM/YYYY",
    "km-KH": "YYYY/MM/DD",
    "kn-IN": "DD/MM/YYYY",
    "ko-KR": "YYYY/ MM/ DD",
    "kok-IN": "DD/MM/YYYY",
    "ky-KG": "DD/MM/YYYY",
    "lb-LU": "DD/MM/YYYY",
    "lo-LA": "DD/MM/YYYY",
    "lt-LT": "YYYY/MM/DD",
    "lv-LV": "YYYY/MM/DD",
    "mi-NZ": "DD/MM/YYYY",
    "mk-MK": "DD/MM/YYYY",
    "ml-IN": "DD/MM/YYYY",
    "mn-MN": "YYYY/MM/DD",
    "mn-Mong-CN": "YYYY/M/D",
    "moh-CA": "M/D/YYYY",
    "mr-IN": "DD/MM/YYYY",
    "ms-BN": "DD/MM/YYYY",
    "ms-MY": "DD/MM/YYYY",
    "mt-MT": "DD/MM/YYYY",
    "nb-NO": "DD/MM/YYYY",
    "ne-NP": "M/D/YYYY",
    "nl-BE": "D/MM/YYYY",
    "nl-NL": "D/M/YYYY",
    "nn-NO": "DD/MM/YYYY",
    "nso-ZA": "YYYY/MM/DD",
    "oc-FR": "DD/MM/YYYY",
    "or-IN": "DD/MM/YYYY",
    "pa-IN": "DD/MM/YYYY",
    "pl-PL": "DD/MM/YYYY",
    "prs-AF": "DD/MM/YYYY",
    "ps-AF": "DD/MM/YYYY",
    "pt-BR": "D/M/YYYY",
    "pt-PT": "DD/MM/YYYY",
    "qut-GT": "DD/MM/YYYY",
    "quz-BO": "DD/MM/YYYY",
    "quz-EC": "DD/MM/YYYY",
    "quz-PE": "DD/MM/YYYY",
    "rm-CH": "DD/MM/YYYY",
    "ro-RO": "DD/MM/YYYY",
    "ru-RU": "DD/MM/YYYY",
    "rw-RW": "M/D/YYYY",
    "sa-IN": "DD/MM/YYYY",
    "sah-RU": "MM/DD/YYYY",
    "se-FI": "D/M/YYYY",
    "se-NO": "DD/MM/YYYY",
    "se-SE": "YYYY/MM/DD",
    "si-LK": "YYYY/MM/DD",
    "sk-SK": "D/ M/ YYYY",
    "sl-SI": "D/M/YYYY",
    "sma-NO": "DD/MM/YYYY",
    "sma-SE": "YYYY/MM/DD",
    "smj-NO": "DD/MM/YYYY",
    "smj-SE": "YYYY/MM/DD",
    "smn-FI": "D/M/YYYY",
    "sms-FI": "D/M/YYYY",
    "sq-AL": "YYYY/MM/DD",
    "sr-Cyrl-BA": "D/M/YYYY",
    "sr-Cyrl-CS": "D/M/YYYY",
    "sr-Cyrl-ME": "D/M/YYYY",
    "sr-Cyrl-RS": "D/M/YYYY",
    "sr-Latn-BA": "D/M/YYYY",
    "sr-Latn-CS": "D/M/YYYY",
    "sr-Latn-ME": "D/M/YYYY",
    "sr-Latn-RS": "D/M/YYYY",
    "sv-FI": "D/M/YYYY",
    "sv-SE": "YYYY/MM/DD",
    "sw-KE": "M/D/YYYY",
    "syr-SY": "DD/MM/YYYY",
    "ta-IN": "DD/MM/YYYY",
    "te-IN": "DD/MM/YYYY",
    "tg-Cyrl-TJ": "DD/MM/YYYY",
    "th-TH": "D/M/YYYY",
    "tk-TM": "DD/MM/YYYY",
    "tn-ZA": "YYYY/MM/DD",
    "tr-TR": "DD/MM/YYYY",
    "tt-RU": "DD/MM/YYYY",
    "tzm-Latn-DZ": "DD/MM/YYYY",
    "ug-CN": "YYYY/M/D",
    "uk-UA": "DD/MM/YYYY",
    "ur-PK": "DD/MM/YYYY",
    "uz-Cyrl-UZ": "DD/MM/YYYY",
    "uz-Latn-UZ": "DD/MM YYYY",
    "vi-VN": "DD/MM/YYYY",
    "wo-SN": "DD/MM/YYYY",
    "xh-ZA": "YYYY/MM/DD",
    "yo-NG": "D/M/YYYY",
    "zh-CN": "YYYY/M/D",
    "zh-HK": "D/M/YYYY",
    "zh-MO": "D/M/YYYY",
    "zh-SG": "D/M/YYYY",
    "zh-TW": "YYYY/M/D",
    "zu-ZA": "YYYY/MM/DD",
  };

  return formats[navigator.language] || "DD/MM/YYYY";
}
