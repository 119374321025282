import React,{useEffect, useState} from "react";
import "./PaginationView.scss";
import { Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import Constants from "../../languages/language"
import UnlinkSampleId from "./UnlinkSampleId";
const PaginationView = ({
  onChange,
  postsPerPage,
  totalPosts,
  startIndex,
  lastIndex,
  unlinkedSampleList,
  showSampleIdDetails
  
}) => {

var text="";

if(totalPosts>0){
text = `Showing ${startIndex + 1} to ${
  lastIndex > totalPosts ? totalPosts : lastIndex
} results of ${totalPosts} records`;
}
const [openUnlinkedSampleIds, setOpenUnlinkedSampleIds] = useState(false);

  return (
    <Box className="box-wrapper">
      <span>{text}</span>
      <UnlinkSampleId
      showSampleIdDetails={showSampleIdDetails}
      unlinkedSampleList={unlinkedSampleList}
      lang={Constants?.Enter_Sample?.See_Unlinked}
      />
      <Pagination
        count={Math.ceil(totalPosts / postsPerPage)}
        onChange={onChange}
        siblingCount={0}
        boundaryCount={1}
      />
    </Box>
  );
};

export default PaginationView;
