import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Constants from "../../../../languages/language";
import Tooltip from "@mui/material/Tooltip";
import "./index.scss";

const AutoControlDropDown = ({
  className,
  keyFwd,
  children,
  resultValUser,
  panelNo,
  colName,
  updateAutoControllData,
  rowLabel
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [transformPosition,setTransformPosition] = React.useState("top");
  const [anchorPosition,setAnchorPosition] = React.useState("bottom");
  const MENU_HEIGHT=130;
  const open = Boolean(anchorEl);
  const warning = Constants?.antigramTable?.workupHeader?.antigenTyping?.disabledTooltip;
  const handleClick = (event) => {
    if(event.clientY+MENU_HEIGHT >= window.innerHeight){ 
      setTransformPosition("bottom");
      setAnchorPosition("top");
    }
    else{
      setTransformPosition("top");
      setAnchorPosition("bottom");
    }
    if (!resultValUser) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const autoControlDropdown = ["0", "1+", "2+", "3+", "4+", "NT"];

  const autoControlHandle = () => {
    return (
      <>
        {resultValUser ? (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "common.white",
                  color: "black",
                  padding: "10px",
                  width: "15rem",
                  alignItems: "center",
                  display: "flex",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "17px",
                  "& .MuiTooltip-arrow": {
                    color: "common.black",
                  },
                },
              },
            }}
            title = { warning }
            placement="top-start"
          >
            <td className="disabled-cell">{children}</td>
          </Tooltip>
        ) : (
          <td
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            style={{}}
            className={open ? `${className} dropdwon-open` : className}
            onClick={handleClick}
            key={keyFwd}
          >
            {children}
          </td>
        )}
        <Menu
          id="fade-menu"
          className="autocontrol-type-drodown-modal-main"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          elevation={0}
          anchorOrigin={{
            vertical: anchorPosition,
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: transformPosition,
            horizontal: "center",
          }}
        >
          {autoControlDropdown &&
            autoControlDropdown.length > 0 &&
            autoControlDropdown?.map((ite, idx) => {
              return (
                <MenuItem
                  key={idx}
                  onClick={() => {updateAutoControllData(panelNo, colName, ite, idx), handleClose()}}
                  value={ite}
                  className = {rowLabel === ite ? "ite-active":null}
                >
                  {ite}
                </MenuItem>
              );
            })}
        </Menu>
      </>
    );
  };

  return autoControlHandle();
};
export default AutoControlDropDown;
