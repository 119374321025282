export const addToWorkupStorage = {
  selectedTestResult: null,
  isAddToWorkupFlowSuccess: false,
  isNewWorkup: false,
};

export const createOrEditWorkupStorage = {
  originalWorkupName: null,
  workupName: null,
  workupId: null,
  selectedTestResults: [],
  isTheFlowSuccessful: false,
  isWorkupDataLoaded: false,
  workupOriginalData: null,
  testDataToRemove: [],
  isEdit: null,
  origin:null,
};

export const notificationStorage = {
  isNotificationFlow: false,
  notificationData: {},
};

export function resetAddToWorkupFlow() {
  addToWorkupStorage.isAddToWorkupFlowSuccess = false;
  addToWorkupStorage.selectedTestResult = null;
  addToWorkupStorage.isNewWorkup = false;
  addToWorkupStorage.isEditWorkup = false;
}

export function resetCreateOrEditWorkupFlow() {
  createOrEditWorkupStorage.originalWorkupName = null;
  createOrEditWorkupStorage.workupName = null;
  createOrEditWorkupStorage.workupId = null;
  createOrEditWorkupStorage.selectedTestResults = [];
  createOrEditWorkupStorage.isTheFlowSuccessful = false;
  createOrEditWorkupStorage.isWorkupDataLoaded = false;
  createOrEditWorkupStorage.workupOriginalData = null;
  createOrEditWorkupStorage.testDataToRemove = [];
  createOrEditWorkupStorage.isEdit = null;
  createOrEditWorkupStorage.origin=null;
}

export function resetNotificationFlow() {
  notificationStorage.isNotificationFlow = false;
  notificationStorage.notificationData = {};
}
