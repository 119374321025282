import axios from "axios";
import qs from "qs";
import { Amplify, Auth } from "aws-amplify";

axios.defaults.timeout = 100000;
let accessToken;
axios.interceptors.request.use(
  async function (config) {
    let url = process.env.REACT_APP_API_ENDPOINT;
    config.headers["Content-type"] = "application/json";
    config.baseURL = url;
    let authorizationToken = await Auth.currentSession();
    accessToken = authorizationToken?.idToken?.jwtToken;
    if (accessToken !== "") {
      config.headers["authorizationToken"] = accessToken;
    }
    return config;
  },
  function (err) {
    // 
    return Promise.reject(err);
  }
);
class Api {
  static get headers() {
    return {
      Accept: "application/json",
      "Content-type": "application/json",
      authorizationToken: accessToken,
    };
  }

  static async get(route, params) {
    try {
      let res = await axios.get(route, params);
      return res;
    } catch (err) {
      // 
      if (!err.response) {
        throw { status: 511 };
      } else {
        throw err.response;
      }
    }
  }
  static async post(route, body, params) {
    try {
      let res = await axios.post(route, body, params);
      return res;
    } catch (err) {
      // 
      if (!err.response) {
        throw { status: 511 };
      } else {
        throw err.response;
      }
    }
  }
  static async put(route, body, params) {
    try {
      let res = await axios.put(route, body, params);
      return res;
    } catch (err) {
      // 
      if (!err.response) {
        throw { status: 511 };
      } else {
        throw err.response;
      }
    }
  }
  static async delete(route, body, params) {
    try {
      let res = await axios.delete(route, body, params);
      return res;
    } catch (err) {
      //
      if (!err.response) {
        throw { status: 511 };
      } else {
        throw err.response;
      }
    }
  }
}

export default Api;
