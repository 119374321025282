import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Link } from "react-router-dom";
import { ListItemText } from "@mui/material";
import "./MenuDropDown.scss";
import { Auth } from "aws-amplify/lib-esm";
import Constants from "../../languages/language";
import { DOWNLOAD_ANTIGRAM_URL } from "../../utils/constants";
import { useState } from "react";
import { LogoutModal } from "./logout/LogoutModal";
import {AUTH_PROVIDER} from "../../utils/constants";

const data = Constants.profileMenu;
const logoutContent = Constants?.logOut;

const MenuDropDown = ({ open, anchorEl, handleClose }) => {
  const [logoutPopup,setLogoutPopup] = useState(false);
  const onSignOutClick = () => {
    handleClose()
    setLogoutPopup(true)
  };
  const logoutConfirm = async () =>{
    localStorage?.setItem("sessionTimeout","false")
    await Auth.signOut({ provider: AUTH_PROVIDER });
  }
  const logoutCancel = (value)=>{
    setLogoutPopup(false)
  }
  return (
    <div>
    {logoutPopup && 
      <LogoutModal
      title={logoutContent?.heading}
      bodyTitle={logoutContent?.content}
      logOutBtn={logoutContent?.logout}
      logoutCancel={(value) => {
        logoutCancel(value)
      }}
      logoutConfirm={logoutConfirm}
      />
    }
    <Menu
      id="basic-menu"
      className="profile-dropdown"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Link to={`/lab-sop`}>
        <MenuItem className="profile-menu" onClick={handleClose}>
          <ListItemIcon>
            <AssignmentOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{data.view_lab}</ListItemText>
        </MenuItem>
      </Link>
      <Link to={`/manage-account`}>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountCircleOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{data.manage_account}</ListItemText>
        </MenuItem>
      </Link>
      <a href={DOWNLOAD_ANTIGRAM_URL} target="_blank" rel="noopener noreferrer">
        {" "}
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <FileDownloadOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{data.download}</ListItemText>
        </MenuItem>
      </a>
      <Link to={`/about-page`}>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DescriptionOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{data.about}</ListItemText>
        </MenuItem>
      </Link>
      <MenuItem onClick={onSignOutClick}>
        <ListItemIcon>
          <LogoutOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText onClick={onSignOutClick}>{data.logout}</ListItemText>
      </MenuItem>
    </Menu>
    </div>
  );
};

export default MenuDropDown;
