import React from "react";
import PositionedSnackbar from "../testTable/PositionedSnackbar";
import {ReactComponent as Error} from "../../assets/images/Error.svg"
import Constants from "../../languages/language";

const message = Constants.Error_Message;
export const ServerError=()=>{
    return (
        <div className="no-result-found">
          <div id="snackbar"></div>
          <div className="circle"><Error></Error></div>

          <h3>{message?.something_wrong}</h3>
          <p>
            {message?.try_again}
          </p>
        </div>
    );
}