import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";

export const getWorkups = async (term) => {
  try {
    let url = `${REACT_APP_API_ENDPOINT}org/workups`;
    if (term) {
      url = `${url}?term=${encodeURIComponent(term)}`;
    }
    const response = await Api.get(url, true);
    if (response) {
      return response?.data;
    }
  } catch (err) {
    return err;
  }
};

export const getWorkupsDetails = async (workupId) => {
  try {
    const response = await Api.get(
      `${REACT_APP_API_ENDPOINT}%7BorgScope%7D/workups/${workupId}/details`,
      true
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
    return err;
  }
};

export const geSingletWorkups = async (id) => {
  try {
    const response = await Api.get(
      `${REACT_APP_API_ENDPOINT}{orgScope}/workups/` + id,
      true
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
    return err;
  }
};

export const getFiltersFromApi = async () => {
  try {
    const response = await Api.get(`${REACT_APP_API_ENDPOINT}me`, {});

    if (response) {
      return response?.data?.workups_filter;
    }
  } catch (err) {
    return err;
  }
};

export const getStatusFromApi = async () => {
  try {
    const response = await Api.get(`${REACT_APP_API_ENDPOINT}me`, {});
    if (response) {
      return response?.data?.status_notifications;
    }
  } catch (err) {
    return err;
  }
};

export const getResultFromApi = async () => {
  try {
    const response = await Api.get(`${REACT_APP_API_ENDPOINT}me`, {});
    if (response) {
      return response?.data?.result_notifications;
    }
  } catch (err) {
    return err;
  }
};

export const saveFiltersToApi = async (filters) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}me/kvs/workups_filter`,

      { value: JSON.stringify(filters) }
    );
    return response?.data;
  } catch (err) {
    return err;
  }
};

export const changeNotificationsApi = async(type, flag) => {
  try {
    const response = await Api.post(`${REACT_APP_API_ENDPOINT}me/kvs/${type}`, {
      value: flag,
    });
    if (response) {
      return response?.data;
    }
  } catch (err) {
    return err;
  }
};

export const updtSingletWorkups = async (id, data) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}{orgScope}/workups/` + id,
      data
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};

export const saveDraftAnalysis = async (id, data) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}{orgScope}/workups/` + id,
      data
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};
export const submitWorkup = async (id, data) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}{orgScope}/workups/` + id,
      data
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};
export const saveSubmitWorkup = async (id, data) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}{orgScope}/workups/${id}/analysis`,
      data
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};
export const autoControl = async (id, data) => {
  try {
    let response;
    response = await Api.post(
      `${REACT_APP_API_ENDPOINT}org/workups/${id}/tests`,
      data
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};

export const unlinkTestData = async (workupId, testDataId) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}org/workups/${workupId}/unlink/${testDataId}`
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};

export const col5Name = async (workupId, panelId, data) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}{orgScope}/workups/${workupId}/panels/${panelId}/tests`,
      data
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;
  }
};
