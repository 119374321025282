import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import DetectBrowserComponent from "./components/detectBrowser/DetectBrowserComponent"
import {isMobile,isTablet,mobileModel,browserName, browserVersion} from 'react-device-detect';
import  store from "./ReactRedux/index"
import { Provider } from 'react-redux';
import {Mobliebrand} from '../src/Redux/Actions/mobileBrand'
const root = ReactDOM.createRoot(document.getElementById("root"));

const sendMobilebrand = async () => {
    const obj = {
        "type":mobileModel,
        "browser": browserName+" "+browserVersion
    }
    const res = await Mobliebrand(obj);
    return res
}
if( mobileModel !=='none' ){
    sendMobilebrand();
}
root.render(
    <BrowserRouter>
        {isMobile || isTablet ? <DetectBrowserComponent />:<Provider store={store}><App /></Provider>}    
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
