import React,{useState,useEffect} from 'react'
import ModalComponent from "../../../components/modal/Modal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { Box, FormControlLabel, Checkbox ,Button} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import "./index.scss";
import Constants from '../../../languages/language'

const _types = Constants.notificationResults.types;
const _filterHeaders = Constants.filters.filterHeaders;

const ResultNotificationFilter = ({ updateFilters,filters}) => {
  const [filterCount, setFilterCount] = useState(null);
  const [types, setTypes] = useState(_types);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
    error: null,
  });

  const [applyStatus, setApplyStatus] = useState(false);
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);

  useEffect(() => {
    applyFilters(filters);
  }, [filters]);

  const applyFilters = (filters) => {
    setDates({
      ...dates,
      startDate: filters.startDate || null,
      endDate: filters.endDate || null,
    });

    if (filters.types) {
      setTypes(
        _types.map((p) => ({
          ...p,
          isSelected: filters.types.includes(p.value),
        }))
      );
    }
  };

  const handleDateChange = (date, type) => {
    const newDates = { ...dates, [type]: date };
    const { startDate, endDate } = newDates;
    setDates(newDates);
  };

  const handlePanelChange = (value) => {
    const index = types.findIndex((p) => p.value === value);
    var item = types[index];

    var newPanelTypes = [...types];
    newPanelTypes[index] = { ...item, isSelected: !item.isSelected };
    setTypes(newPanelTypes);
  };

  const handleClear = () => {
    setTypes(_types);
    setDates({ startDate: null, endDate: null });
    updateFilters({});
  };

  const getFiltersToApply = () => {
    const filters = {
      startDate: dates.startDate,
      endDate: dates.endDate,
      types: types.filter((p) => p.isSelected).map((p) => p.value),
      filterCount,
    };

    return filters;
  };

  const handleApply = () => {
    const filtersToApply = getFiltersToApply();
    updateFilters(filtersToApply);
    handleClose();
    setApplyStatus(true);
  };

  useEffect(() => {
    let filterCount = 0;
    if (dates.startDate !== null) filterCount++;

    filterCount += types.filter((p) => p.isSelected).length;

    if (filterCount === 0) {
      filterCount = null;
    }

    setFilterCount(filterCount);

    const currentFilters = getFiltersToApply();

    if(JSON.stringify(currentFilters)===JSON.stringify(filters)){
      setIsApplyDisabled(true);
    }
    else{
      setIsApplyDisabled(false)
    }

  }, [ types, dates]);

 
  const [anchorEl, setAnchorEl] = useState(null);
  const isFilterOpen = anchorEl !== null;
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if(showFilterCount === null){
      setApplyStatus(false)
      }
  };
  const handleClose = () => {
    document.getElementById("demo-customized-button").style.backgroundColor =
      null;
    setAnchorEl(null);
    applyFilters(filters);
  };

  const showFilterCount = filters.filterCount && filters.filterCount > 0;

  const disableButton =
    filterCount === null && applyStatus === false ? true : false;
  const cleardisable = filterCount === null ? true : false;

  const displayCount = (
    <span className="notification-count-filter">
      {showFilterCount ? filters.filterCount : ""}
    </span>
  );
  return (
    <div className="filter-component">
      <Button
           id="demo-customized-button"
           aria-controls={open ? "demo-customized-menu" : undefined}
           aria-haspopup="true"
           aria-expanded={open ? "true" : undefined}
           variant="outlined"
           disableElevation
           onClick={handleClick}
            startIcon={showFilterCount ? displayCount : <TuneIcon />}
            style={{ backgroundColor: isFilterOpen ? "#FDEBF0" : undefined }}
            className="notification-filter"           
          >
            {_filterHeaders.filter_button}
          </Button>
    <ModalComponent
           sx={{marginTop:"30px"}}
            id="demo-customized-menu"
            anchorEl={anchorEl}
            open={open1}
            onClose={handleClose}
            className="filter-custom-modal"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="filter-wrapper">
              <span className="filter-label">
                {_filterHeaders.filter_count}
                {filterCount && <span> ({filterCount})</span>}
              </span>
              <button
                style={{ fontFamily: "Rubik" }}
                className={
                  filterCount === null ? "disable-clear-label" : "clear-label"
                }
                onClick={handleClear}
                disabled={cleardisable}
              >
                {_filterHeaders.clear_button}
              </button>
              <button
                style={{ fontFamily: "Rubik" }}
                className={isApplyDisabled?"disable-filter-lable": "apply-filter-lable"}
                onClick={handleApply}
                disabled={isApplyDisabled}
              >
                {_filterHeaders.apply_button}
              </button>
            </div>
            {/* Dates  */}

            <div className="notification-filter-date-wrapper">
              <div className="date-label">{_filterHeaders.date}</div>
              <div className="date-picker-label">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={_filterHeaders.start_date}
                    value={dates.startDate}
                    onChange={(newValue) => {
                      handleDateChange(newValue, "startDate");
                    }}
                    className="date-container"
                    renderInput={(params) => <TextField {...params} />}
                  />

                  <DatePicker
                    label={_filterHeaders.end_date}
                    className="date-container"
                    value={dates.endDate}
                    onChange={(newValue) => {
                      handleDateChange(newValue, "endDate");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={dates.startDate}
                  />
                </LocalizationProvider>
              </div>
              {dates.error && (
                <div className="date-error-label">{dates.error}</div>
              )}
            </div>

            {/* Type */}

            <Box className="notification-filter-panel-wrapper">
              <div className="panel-label">{_filterHeaders.type}</div>

              <div className="panel-container">
                {types.map((p) => (
                  <FormControlLabel
                    label={p.display}
                    control={
                      <Checkbox
                        checked={p.isSelected}
                        onChange={() => handlePanelChange(p.value)}
                      />
                    }
                    className="panel-types-items"
                    key={p.value}
                  />
                ))}
              </div>
            </Box>
          </ModalComponent>
          </div>
  )
}

export default ResultNotificationFilter