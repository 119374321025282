import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";

export const acceptTermsOfService = async () => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}me/kvs/terms_of_service`,
      { value: true }
    );
    if (response) {
      return response?.data;
    }
  } catch (err) {
    return err;
  }
};

export const getTerms = async () => {
  try {
    const response = await Api.get(`${REACT_APP_API_ENDPOINT}{orgscope}/terms`);
    return response?.data;
  } catch (err) {
    return err;
  }
};
