import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";

export const labSopOverview = async() => {
  try {
    const response = await Api.get(
      `${REACT_APP_API_ENDPOINT}{orgScope}/config`
    );
    if (response) {
      return response;
    }
  } catch (err) {
    return err;  }
};
