import React from "react";
import "./index.scss";
import Constants from "../../../languages/en.json"
const AutoControl = ({ apiData }) => {
  const colsData=Constants.antigramTable.workupHeader.autocontrol.summary;
  const autoData=Constants.antigramTable.workupHeader.autocontrol;

  const colsArray = ["is", "37c", "iat", "fic", "fifth_column"];
  let obj={is:"", "37c":"", iat:"", fic:"", fifth_column:""};
  const [autoctrFlag, setAutoctrFlag] = React.useState(null);
  React.useEffect(()=> {
    loopColsData();
  },[JSON.stringify(apiData)]);

  const loopColsData = () => {
    for(let i=0; i<colsArray.length; i++){
      getFlag(colsArray[i]);
    }
  }
  let currentVal;
  const getFlag = (colName) =>{
    for(let i=0; i<apiData?.panels?.length; i++){
      let autoControlIndex = null
      for(let j = 0; j < apiData?.panels[i]?.results?.length; j++){
        if(apiData.panels[i]?.results[j].cell_number === "AC"){
          autoControlIndex = j;
          break;
        }
      }
       currentVal = apiData.panels[i].results[autoControlIndex]?.columns[colName]?.grade;
      
      if( obj[colName] !== "Positive"){
        if( currentVal === "1+" || currentVal === "2+" || currentVal === "3+" || currentVal === "4+" ){
          obj[colName]="Positive";
        }else if( obj[colName] !== "Negetive"){
          if(currentVal === "0" ){
            obj[colName]="Negetive";
          }else{
            obj[colName]="NT";
          }
        }
      }
    }
    setAutoctrFlag(autoctrFlag => ({
      ...autoctrFlag,
      ...obj
    }));
  }
  
  const displayTableVals = () => {
    let valArray = [];
    autoctrFlag && Object.keys(autoctrFlag).forEach(function(key, index) {
      let resltType;
      if(autoctrFlag[key]=="Positive"){
        resltType = "+";
      }else if(autoctrFlag[key]=="Negetive"){
        resltType = "0";
      }else{
        resltType = "";
      }
      valArray.push(<td key={index}>{resltType}</td>);
    })
    return(valArray);
  }

  return (
    <div className="classification-tab-autocontrol">
      <div id="autoControl" className="values-row">
        <table>
          <thead className="thead"><tr><td colSpan={5}>{colsData["title"]}</td></tr></thead>
          <tbody className="tbody">
            <tr className="col-titles">
              <td>{colsData["is"]}</td>
              <td>{colsData["37c"]}</td>
              <td>{colsData["iat"]}</td>
              <td>{colsData["ficin"]}</td>
              <td>{colsData["other"]}</td>
            </tr>
            <tr className="col-data">
                {displayTableVals()}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="label-row">
        <p>{autoData.autotabP1}</p>
        <p>{autoData.autoTabP2}</p>
      </div>
    </div>
  );
};
export default AutoControl;
