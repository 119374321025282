import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import Switch from "@mui/material/Switch";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import FormattedLabel from "../../../formattedLabel/FormattedLabel";
import Constants from "../../../../languages/language";
import "./index.scss";

const colOrder = Constants.antigramTable.classificationRow;
const antTypConsts = Constants.antigramTable.workupHeader.antigenTyping;

{
  /* Antigen Typing Alert message Code Commented for Future Use */
}
// const warningMessage = () => {
//   return(<React.Fragment><WarningIcon className='warning-icon' style={{color: "#F2A900"}} /><span className='alert-note'><strong>{antTypConsts.changeAlertTitle}:</strong> {antTypConsts.changeAlertMessage}</span></React.Fragment>);
// };

const AntigenTyping = ({
  getAntTypeVal,
  antTypeValOrgObj,
  updateAntigramTyping,
  updateMfTagsParent,
  apiData,
  completeAlert,
  disableSave,
}) => {
  const [showAlertMsg, setShowAlertMsg] = React.useState(false);

  const openAlertMsg = sessionStorage.getItem(
    apiData?.workup?.id + "-showAntigenModifidAlert"
  );

  const antigenTypingRowData = () => {
    const antigenTypingCells = [];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentCell, setCurrentCell] = React.useState();
    const [activeCellBorder, setActiveCellBorder] = React.useState();
    let antTypingCard = getAntTypeVal;
    const open = Boolean(anchorEl);
    let cellDisabled;
    let cellDisabledClass;

    /* Handle popper */
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const handleClickPopper = (event) => {
      setAnchorEl2(anchorEl2 ? null : event.currentTarget);
    };
    const popupState = Boolean(anchorEl2);
    const handleClickAway = () => {
      setAnchorEl2(false);
    };

    /* Handle current cell and target */
    const updateTypingRules = (val, event) => {
      setActiveCellBorder(event.currentTarget);
      event.currentTarget.style.borderColor = "#8A96A1";
      setAnchorEl(event.currentTarget);
      setCurrentCell(val);
    };

    const handleClose = () => {
      activeCellBorder.style.borderColor = "";
      setAnchorEl(null);
    };

    /* Dropdown select handler */
    const updateTypingCards = (typingVal) => {
      openAlertMsg === null ? setShowAlertMsg(true) : "";
      sessionStorage.setItem(
        apiData?.workup?.id + "-showAntigenModifidAlert",
        false
      );
      if (
        antTypingCard != null &&
        currentCell in antTypingCard &&
        antTypingCard[currentCell].grade != undefined &&
        antTypingCard[currentCell].grade != "" &&
        antTypingCard[currentCell].grade == typingVal
      ) {
        /* Sending data to store in parent component due to unmount of tab component from DOM */
        updateAntigramTyping("remove", currentCell, typingVal);
      } else {
        updateAntigramTyping("add", currentCell, typingVal);
      }
      disableSave();
      //showAlertMsg !== false? setShowAlertMsg(false):setShowAlertMsg(false);
      handleClose();
    };

    /* Dropdown switch change handler */
    const updateMfTags = () => {
      if (
        antTypingCard != null &&
        currentCell in antTypingCard &&
        antTypingCard[currentCell].mf != undefined &&
        antTypingCard[currentCell].mf != ""
      ) {
        updateMfTagsParent("remove", currentCell);
      } else {
        updateMfTagsParent("add", currentCell);
      }
      disableSave();
      handleClose();
    };

    antigenTypingCells.push(
      <ClickAwayListener key={Math.random()} onClickAway={handleClickAway}>
        <Popper
          className="antyTypCellPopper"
          open={popupState}
          anchorEl={anchorEl2}
          placement={"top-start"}
        >
          <p>{antTypConsts.disabledTooltip}</p>
        </Popper>
      </ClickAwayListener>
    );

    antigenTypingCells.push(
      <Menu
        id="fade-menu"
        className="antigen-type-drodown-modal-main"
        MenuListProps={{ "aria-labelledby": "fade-button" }}
        key={new Date().getTime()}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem className={antTypingCard?.[currentCell]?.grade==="0" ? "active" : "" } onClick={() => updateTypingCards("0")}>0</MenuItem>
        <MenuItem className={antTypingCard?.[currentCell]?.grade==="1+" ? "active" : "" } onClick={() => updateTypingCards("1+")}>1+</MenuItem>
        <MenuItem className={antTypingCard?.[currentCell]?.grade==="2+" ? "active" : "" } onClick={() => updateTypingCards("2+")}>2+</MenuItem>
        <MenuItem className={antTypingCard?.[currentCell]?.grade==="3+" ? "active" : "" } onClick={() => updateTypingCards("3+")}>3+</MenuItem>
        <MenuItem className={antTypingCard?.[currentCell]?.grade==="4+" ? "active" : "" } onClick={() => updateTypingCards("4+")}>4+</MenuItem>
        <MenuItem className={(antTypingCard?.[currentCell]?.grade==="NT" || antTypingCard?.[currentCell]?.grade===undefined) ? "active" : "" } onClick={() => updateTypingCards("NT")}>
          {antTypConsts.NT}
        </MenuItem>
        <MenuItem className="switch-btn">
          <Switch
            checked={antTypingCard?.[currentCell]?.mf == true ? true : false}
            onChange={() => {
              antTypeValOrgObj?.[currentCell]?.mf?.user_entered === false
                ? ""
                : updateMfTags();
            }}
            inputProps={{ "aria-label": "controlled" }}
            size="small"
          />
          <span>{antTypConsts.MF}</span>
        </MenuItem>
      </Menu>
    );

    /* Loop antigen cells row */

    for (let j = 0; j < colOrder.length; j++) {
      antTypeValOrgObj?.[colOrder[j]]?.grade?.user_entered === false
        ? (cellDisabled = true)
        : (cellDisabled = false);
      let analyzerMf =
        antTypeValOrgObj?.[colOrder[j]]?.mf?.user_entered === false &&
        antTypeValOrgObj?.[colOrder[j]]?.mf?.value === true
          ? "analyzer"
          : "";
      cellDisabled == true
        ? (cellDisabledClass = "disabled")
        : (cellDisabledClass = "");
      let addMfClass =
        antTypingCard?.[colOrder[j]]?.mf == true ? "mf-cell" : "";
      antigenTypingCells.push(
        <div
          key={j}
          className={`antigen-typing-cell-outer ${cellDisabledClass}`}
          onClick={cellDisabled == true ? handleClickPopper : null}
        >
          <span
            className={`antigen-typing-cell ${addMfClass} ${analyzerMf} cl-${
              j + 1
            } ${colOrder[j]}`}
          >
            <FormattedLabel j={colOrder[j]}></FormattedLabel>
            {addMfClass && <span className="mf-tag">{antTypConsts.MF}</span>}
          </span>
          <span
            className="antigen-typing-card"
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            //onClick={(event) => updateTypingRules("colOrder[j]", handleClick(event, colOrder[j]))}
            onClick={
              cellDisabled === true 
                ? apiData.workup.status === "COMPLETED"? completeAlert : null
                : apiData.workup.status === "COMPLETED" ? completeAlert :
                 updateTypingRules.bind(event, colOrder[j])
            }
          >
            {(antTypingCard && antTypingCard?.[colOrder[j]]?.grade !== "NT") && antTypingCard?.[colOrder[j]]?.grade}
          </span>
        </div>
      );
    }
    return antigenTypingCells;
  };

  return (
    <div id="download-antigen" className="antigen-typing-wrapper">    
      {antigenTypingRowData()}
       {/* Antigen Typing Alert message Code Commented for Future Use */}
      {/* {showAlertMsg && <PositionedSnackbar fwdStyles={{ color:'#34383C', backgroundColor:'#FFF2D0', borderColor:'#F2A900' }}>{warningMessage()}</PositionedSnackbar>} */}
    </div>
  );
};

export default AntigenTyping;
