import React from "react";
import { Card, CardContent, CardActions, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import Constants from "../../../languages/language";
import './LogoutModal.scss'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
const data = Constants?.WorkupNotes;
export const LogoutModal = (props) => {
    const handleLogout = () => {
        props.logoutConfirm(true);
    };
    const handleNoClick = () => {
        props.logoutCancel(true);
    };
    return (
        <>
            <div>
                <div className="logout-wrapper">
                    <Card variant="outlined" className="card-content">
                        <CardContent>
                            <div className="logout-header">
                                <ErrorOutlineOutlinedIcon />
                                <p> {props.title}</p>
                                <Close
                                    className="close-icon"
                                    onClick={() => {
                                        handleNoClick();
                                    }}
                                />
                            </div>
                            <div className="logout-content">
                                {props.bodyTitle}
                            </div>
                        </CardContent>
                        <CardActions className="logout-action">
                            <Button
                                className="cancel-btn"
                                onClick={() => {
                                    handleNoClick();
                                }}
                            >
                                {data.cancel}
                            </Button>
                            <Button className="accept-btn" onClick={() => handleLogout()}>
                                {props.logOutBtn}
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </>
    );
};
