import Api from "../../utils/apiConfig";
import { REACT_APP_API_ENDPOINT } from "../../utils/constants.js";
export const saveFiltersToApi = async (filters) => {
  try {
    const response = await Api.post(
      `${REACT_APP_API_ENDPOINT}me/kvs/results_filter`,
      { value: JSON.stringify(filters) }
    );
    return response?.data;
  } catch (err) {
    return err;
  }
};
